import uuid from 'react-uuid';
import { DELETE_GUEST, GUEST, UPDATE_GUEST_INFO } from '../constant';
const initialState = {
  guestDetails: {
    token: uuid(),
    browser_id: uuid(),
    is_newsletter_subscribed: false,
  },
};

const guestReducer = (state = initialState, action) => {
  switch (action.type) {
    case GUEST:
      return { ...state, guestDetails: action.data };
    case DELETE_GUEST:
      return {
        ...state,
        guestDetails: {
          token: uuid(),
          browser_id: uuid(),
          is_newsletter_subscribed: false,
        },
      };
    case UPDATE_GUEST_INFO:
      return {
        ...state,
        guestDetails: (state.guestDetails = action.data),
      };
    default:
      return state;
  }
};

export default guestReducer;
