import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import { useState } from 'react';

const VatDialog = ({ getVatValue, setIsVatOpen, isVatOpen }) => {
  const handleClose = () => {
    setIsVatOpen(false);
  };

  return (
    <div className="AddTo">
      <Dialog
        open={isVatOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="Add-To"
      >
        <DialogContent>
          <p style={{ fontSize: '18px', }}>Are you sure you want to mark this product as VAT exempted?</p>
          <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
            <div style={{ marginRight: '10px' }}>
              <DarkBtn
                title="YES"
                onClick={() => {
                  getVatValue(true);
                  handleClose();
                }}
              />
            </div>
            <div>
              <DarkBtn
                title="NO"
                onClick={() => {
                  getVatValue(false);
                  handleClose();
                }}
              />
            </div>


          </div>

        </DialogContent>
      </Dialog>
    </div>
  );
};

export default VatDialog;
