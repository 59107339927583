//import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
import TablePagination from '@material-ui/core/TablePagination';

export default function BasicPagination({
  page,
  total,
  handleChangePage,
  handleChangeRowsPerPage,
  Row,
  rowsPerPage,
}) {
  return (
    <div className="pagination">
      <div className="row-page">Items per page :{rowsPerPage}</div>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={Row?.length ?Row?.length : total}    
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
