import { useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import BlackBtn from '../../components/universal/Buttons/BlackBtn';
import ExclusiveBlogs from '../../components/universal/ExclusiveBlogs';
import InputType from '../../components/universal/InputType';
import { notify } from '../../core/constants';
import { AxiosAll } from '../../services/axiosAll';
const ForgotPassword = () => {
  const forgetpassword = async (data) => {
    try {
      const res = await AxiosAll('post', `user/forgot-password?`, data);
      notify(res.data.message, 'success');
    } catch (error) {
      console.log(error);
    }
  };

  // formik start
  const initialValues = {
    email: '',
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .required('E-mail address is required.')
      .email('Enter valid email address'),
  });
  const onSubmit = (values) => {
    formik.handleReset();
    forgetpassword({
      email: values?.email,
      url: window.location.origin.toString(),
    });
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <div className="forgotpassword">
      <div className="login-full-container">
        <div className="main-heading">Forgot Password</div>
        <div className="login-para">
          Lost your password? Please enter your email address and you will
          receive a link to create a new password via email.
        </div>

        <form onSubmit={formik.handleSubmit}>
          <div>
            <InputType
              name="email"
              id="email"
              type="email"
              placeholder="Email Address"
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
            />
          </div>
          <div>
            <BlackBtn type="submit" title="Reset Password" />
          </div>
        </form>
      </div>
      <ExclusiveBlogs />
    </div>
  );
};

export default ForgotPassword;
