import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import { notify } from '../../../core/constants';
import { axiosAll } from '../../../services/axiosAll';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import SubHeading from '../../universal/Headings/SubHeading';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';

export default function DispatchOrderDialog({
  order_id,
  id,
  open,
  setOpen,
  courier,
  CourierClass,
}) {
  const [trackingno, setTrackingno] = useState('');
  const [OrdersIds, setOrdersIds] = useState(order_id);
  const [loading, setloading] = useState(false);

  const handleDispatchItem = async (orders, trackingno) => {
    setloading(true);
    // const orderItemIds = [order_id];
    const tracking_no = trackingno;
    const responce = await axiosAll({
      method: 'post',
      url: `v2/order/dispatch-items`,
      data: { orderItemIds: orders, tracking_no },
    });
    notify(responce?.message, 'success');
    setloading(false);
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setTrackingno(([e.target.name] = e.target.value));
  };

  return (
    <div>
      <Button variant="contained" className="darkbtn" onClick={handleClickOpen}>
        {' '}
        Mark as Dispatch{' '}
      </Button>
      <Dialog
        open={open}
        onClose={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="dispatchorderdialogs"
      >
        <DialogContent>
          <SubHeading title="Dispatch order" />
          <div className="heading-para">
            {' '}
            Your customer will get shipping updates via email. Please provide
            the tracking number which we will share with them.{' '}
          </div>
          <div className="heading-id">
            Courier : <span style={{ fontWeight: 'bold' }}>{courier}</span>
          </div>
          <div className="heading-id">
            Courier Class:{' '}
            <span style={{ fontWeight: 'bold' }}>{CourierClass}</span>
          </div>
          <div className="label-text">Tracking number</div>
          <InputTypeOutline
            name={'trackingno'}
            onChange={handleChange}
            type={'text'}
          />
          <div className="btn-section">
            <LightBtn onClick={handleClose} autoFocus title="Cancel" />
            <DarkBtn
              title={loading ? 'Loading...' : 'Add'}
              disabled={loading}
              onClick={() => {
                if (trackingno === '') {
                  notify('Please enter tracking number', 'error');
                } else {
                  handleDispatchItem(OrdersIds, trackingno);
                }
              }}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
