import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const sweatAlertBox = async (
  title,
  text,
  confirmButtonText,
  cancelButtonText,
  handleDelete,
  id
) => {
  const result = await MySwal.fire({
    title: title,
    html:text,
    text: text,
    type: "warning",
    cancelButtonText: cancelButtonText ? cancelButtonText : "Cancel",
    confirmButtonColor: "#ff6600",
    confirmButtonText: confirmButtonText ? confirmButtonText : "Confirm",
    showCancelButton: true,
    allowOutsideClick: false,
  });
  if (result.isConfirmed) {
    handleDelete(id);
  }
};

export default sweatAlertBox;

export const sweatAlert = async (title) => {
  const result = await MySwal.fire(title);
};
