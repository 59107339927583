import { Container, Grid } from '@mui/material';
import moment from 'moment';
import { Fragment, useEffect, useState } from 'react';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';
import SubHeading from '../../../components/universal/Headings/SubHeading';
import { notify } from '../../../core/constants';
import { axiosAll } from '../../../services/axiosAll';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

const OrderInvoice = () => {
  const [invoice, setInvoice] = useState();
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const Order_id = urlParams.get('order_id');

  const handleOrderDetail = async (id) => {
    const responce = await axiosAll({
      method: 'get',
      url: `v2/order/details/${id}`,
    });
    setInvoice(responce?.result);
  };

  const handleInvoiceMAil = async (id) => {
    const response = await axiosAll({
      method: 'post',
      url: `v1/order/send-invoice/${id}`,
    });
    // setInvoice(responce?.result);
    notify(response?.message, 'success');
  };

  // const Invoice_ID = `${Order_id}`;

  // const handleURL = (Invoice_ID) => {
  //   setSearchParma({ ["order_id"]: Invoice_ID });
  // };

  // var data = document.getElementById('salesinvoice');

  // html2canvas(data).then((canvas) => {
  //   var imgWidth = 180;
  //   // var pageHeight = 295;
  //   var imgHeight = (canvas.height * imgWidth) / canvas.width;
  //   // var heightLeft = imgHeight;

  //   const contentDataURL = canvas.toDataURL('image/png');
  //   const pdf = new jsPDF('p', 'mm', 'a4');
  //   var position = 0;

  //   pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
  // });

  const exportAsPdf = () => {
    const data = document.getElementById('salesinvoice');
    html2canvas(data)
      .then((canvas) => {
        var imgWidth = 180;
        // var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        // var heightLeft = imgHeight;

        const contentDataURL = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        var position = 0;

        pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
        pdf.save(`order_${invoice?.order?.generatedOrderId}.pdf`);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    handleOrderDetail(Order_id);
  }, [Order_id]);

  return (
    <div className="orderinvoice">
      <Container id="pdfinvoice" className="all-container-27inch">
        <div className="btn-section">
          {/* <PendingBtn title="Download pdf"/>
          <PendingBtn title="Email invoice"/> */}
          <DarkBtn title="Download pdf" onClick={exportAsPdf} />
          <DarkBtn
            onClick={() => handleInvoiceMAil(Order_id)}
            title="Email invoice"
          />
        </div>
        <div id="salesinvoice" className="salesinvoice">
          <div className="sales-name-section">
            <div className="sales-name">Sales Invoice</div>
            <div className="sales-name">
              Invoice #{invoice?.order?.generatedOrderId}
            </div>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={6} sm={4}>
              <SubHeading title="Shipped To :" />
              {invoice?.order?.addressName},{invoice?.order?.address}
            </Grid>
            <Grid item xs={6} sm={4}>
              <SubHeading title="Billed To :" />
              {invoice?.order?.billingAddressName},
              {invoice?.order?.billingAddress}
            </Grid>
            <Grid item xs={12} sm={4} className="seller-info">
              {invoice?.orderItems?.map((item, index) => {
                if (index === 0) {
                  return (
                    <>
                      <SubHeading title="Seller Info :" />
                      <p className="info">
                        <b>Name: </b> {item?.seller?.name}
                      </p>
                      <p className="info">
                        <b>Store Name : </b> {item?.seller?.userName}
                      </p>
                      <p className="info">
                        <b>Email : </b> {item?.seller?.email}
                      </p>
                      <p className="info">
                        <b>Phone : </b>
                        {item?.seller?.phoneNumber}
                      </p>
                      <p className="info">
                        <b>Reg Vat No: </b>123
                      </p>
                      <p className="info">
                        <b>Order Date : </b>
                        {moment(item?.createdAt).format('DD-MM-YYYY')}
                      </p>
                    </>
                  );
                }
              })}
            </Grid>
          </Grid>

          <div className="table-section">
            <div className="order-section">
              <div className="order-text">Order Summary</div>
            </div>
            <table>
              <tr>
                <th>Item</th>
                <th>Net Cost</th>
                <th>Quantity</th>
                <th>Item Total</th>
              </tr>

              {invoice?.orderItems?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td className="borderline">{item?.name}</td>
                    <td className="borderline">£{item?.netCost?.toFixed(2)}</td>
                    <td className="borderline">{item?.stocks}</td>
                    <td className="borderline">
                      £{item?.itemTotal?.toFixed(2)}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td></td>
                <td>
                  <b>Subtotal</b>
                </td>
                <td>£{invoice?.orderSummary?.subTotal.toFixed(2)}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>
                  <b>Shipping</b>
                </td>
                <td>£{invoice?.orderSummary?.shippingTotal?.toFixed(2)}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>
                  <b>Gift Wrapping</b>
                </td>
                <td>£{invoice?.orderSummary?.giftWrappingTotal?.toFixed(2)}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>
                  <b>Vat @ 20%</b>
                </td>
                <td>£{invoice?.orderSummary?.vatTotal?.toFixed(2)}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td>
                  <b>Total</b>
                </td>
                <td>£{invoice?.orderSummary?.grossTotal?.toFixed(2)}</td>
              </tr>
            </table>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default OrderInvoice;
