import { Container, Grid } from '@mui/material';
import React, { useState, useEffect } from 'react';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import DropDown from '../../../components/universal/DropDown';
import CodeInputBox from '../../../components/universal/InputType/CodeInputBox';
import { axiosAll } from '../../../services/axiosAll';
import { ProductCondition } from '../../../core/constants';
import { useNavigate } from 'react-router';

const BulkUpload = () => {
  const [tag, setTag] = useState();
  const [tagValue, setTagValue] = useState('266');
  const [tagColor, setTagColor] = useState();
  const [tagChildren, setTagChildren] = useState();
  const [tagValueChildren, setTagValueChildren] = useState();

  const [tagChildrenSize, setTagChildrenSize] = useState();
  const [tagChildrenSizeValue, setTagChildrenSizeValue] = useState();

  const [color, setColor] = useState();
  const [colorValue, setColorValue] = useState('0');

  const [badge, setBadges] = useState();
  const [badgeValue, setBadgesValue] = useState('0');

  const [countrie, setCountries] = useState();
  const [countrieValue, setCountriesValue] = useState('0');

  const [couriers, setCouriers] = useState();
  const [couriersValue, setCourierValues] = useState('0');

  const [courierClass, setCourierClasses] = useState();
  const [couriersClassValue, setCourierClassValues] = useState('0');

  const [processingTime, setProcessingTime] = useState();
  const [processingTimeValue, setProcessingTimeValue] = useState('0');
  const hiddenFileInput = React.useRef(null);

  const [productConditionValue, setProductConditionValue] = useState('');
  const navigate = useNavigate();
  const getTag = async (tag, searchProduct) => {
    const response = await axiosAll({
      method: 'get',
      url: `v1/tag`,
    });
    if (response?.statusCode == 200) {
      const data = response?.result?.data?.filter((e) => e.name == 'Ethical');
      setTag(data[0]?.children);
    }
  };

  const getColor = async (tag, searchProduct) => {
    const response = await axiosAll({
      method: 'get',
      url: `v1/product/colors`,
    });
    if (response?.statusCode == 200) {
      setColor(response.result);
    }
  };

  const getBadges = async (tag, searchProduct) => {
    const response = await axiosAll({
      method: 'get',
      url: `v1/product/badges`,
    });
    if (response?.statusCode == 200) {
      setBadges(response.result);
    }
  };

  const getCountries = async (tag, searchProduct) => {
    const response = await axiosAll({
      method: 'get',
      url: `v2/countries-with-couriers`,
    });
    if (response?.statusCode == 200) {
      setCountries(response?.result?.countries);
      setCouriers(response?.result?.couriers);
      setProcessingTime(response?.result?.processingTime);
    }
  };

  const uploadCSV = async (fileUploaded) => {
    if (fileUploaded) {
      const formData = new FormData();
      formData.append('bulk_upload', fileUploaded);
      try {
        const responce = await axiosAll({
          method: 'post',
          url: 'v2/products/bulkUpload',
          data: formData,
        });
        navigate('/onboarding/products');
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleUserMenual = () => {
    window.open(
      'https://prod.goethical.com/doc/dashboard-user-manual.pdf',
      '_blank'
    );
  };

  const handleTagChange = (e) => {
    const { value } = e.target;
    setTagValue(value);
  };

  const handleTagChildrenChange = (e) => {
    const { value } = e.target;
    setTagValueChildren(value);
  };

  const handleColorChange = (e) => {
    const { value } = e.target;
    setColorValue(value);
  };
  const handleBadgeChange = (e) => {
    const { value } = e.target;
    setBadgesValue(value);
  };

  const handleCountrieChange = (e) => {
    const { value } = e.target;
    setCountriesValue(value);
  };

  const handleProcessingTimeChange = (e) => {
    const { value } = e.target;
    setProcessingTimeValue(value);
  };

  const handleCourierValuesChange = (e) => {
    const { value } = e.target;
    setCourierValues(value);
  };

  const handleCourierClassChange = (e) => {
    const { value } = e.target;
    setCourierClassValues(value);
  };

  const handleTagSizeChange = (e) => {
    const { value } = e.target;
    setTagChildrenSizeValue(value);
  };

  const handleProductConditionChange = (e) => {
    const { value } = e.target;
    setProductConditionValue(value);
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event) => {
    if (event.target.files[0]) {
      const fileUploaded = event.target.files[0];
      uploadCSV(fileUploaded);
    }
  };
  useEffect(() => {
    const TagChildrenData = tag?.filter((e) => e.id == tagValue);
    const tagChildrendataResult = TagChildrenData
      ? TagChildrenData[0]?.children
      : [];
    setTagColor(tagChildrendataResult[0]?.isColorAvailable);
    setTagChildren(tagChildrendataResult);
    const tagChildrendataResultValue = tagChildrendataResult[0]?.id;
    setTagValueChildren(tagChildrendataResultValue);
    console.log(tagChildrendataResultValue);
  }, [tagValue, tag]);

  useEffect(() => {
    if (setTagValueChildren && tag && tagValue && tagValueChildren) {
      const tagSizeData = tagChildren?.filter((e) => e.id == tagValueChildren);
      const tegsizeResult = tagSizeData[0]?.tagSizes
        ? tagSizeData[0]?.tagSizes
        : [];
      setTagChildrenSize(tegsizeResult);
      const courierClassResultValue = tegsizeResult[0]?.id;
      setTagChildrenSizeValue(courierClassResultValue);
    }
  }, [tagChildren, tag, tagValue, tagValueChildren]);

  useEffect(() => {
    if (couriersValue != 0 && couriers) {
      const courierClassData = couriers?.filter((e) => e.id == couriersValue);
      const courierClassResult = courierClassData
        ? courierClassData[0]?.courierClasses
        : [];
      setCourierClasses(courierClassResult);
      const courierClassResultValue = courierClassResult[0]?.id;
      setCourierClassValues(courierClassResultValue);
    }
  }, [couriersValue, couriers]);

  useEffect(() => {
    getTag();
    getColor();
    getBadges();
    getCountries();
  }, []);
  console.log('tag', tag);
  return (
    <div className="bulkuploadscreen">
      <Container className="all-container-27inch">
        <div className="bulk-upload-heading">Bulk upload (CSV)</div>
        <div className="bulk-para">
          <p>
            {' '}
            Before continuing please download our{' '}
            <span
              onClick={() =>
                window.open(
                  `https://prod.goethical.com/assets/files/goethical_template.csv`,
                  '_blank'
                )
              }
            >
              CSV template
            </span>{' '}
            and <span onClick={handleUserMenual}>user manual</span>. In
            particular, careful attention needs to be paid to mandatory fields
            and fixed data options, such as category, colour and size.
          </p>
        </div>
        <div className="bulk-para">
          <p>
            Images will need to be separately uploaded and in the{' '}
            <span onClick={() => navigate('/onboarding/product-images')}>
              Product Images
            </span>{' '}
            page and referenced in your CSV.
          </p>
        </div>
        <div className="bulk-para">
          <p>
            For technical support, please contact{' '}
            <span
              onClick={() =>
                window.top.open('mailto:supportgoethical@gmai.com')
              }
            >
              support@goethical.com
            </span>
          </p>
        </div>
        <div className="box-container">
          <div className="btn-section">
            <LightBtn
              title="Download CSV template"
              onClick={() =>
                window.open(
                  `https://prod.goethical.com/assets/files/goethical_template.csv`,
                  '_blank'
                )
              }
            />
            <DarkBtn for="files" title="Upload CSV" onClick={handleClick} />
            <input
              type="file"
              ref={hiddenFileInput}
              accept=".csv"
              onChange={handleChange}
              style={{ display: 'none' }}
            />
          </div>

          <div className="bulkbody-container">
            <div className="product-heading">Finding your product codes</div>
            <div className="bulk-para">
              <p>
                {' '}
                When adding product categories, sizes and colours you need to
                refernece codes defined in our system. Use the searches below to
                find the relevant codes for your products.{' '}
              </p>
            </div>
            <div className="form-heading">Category & Size</div>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="search">Search product category codes</div>
                <div className="dropdown-section">
                  <DropDown
                    option={tag}
                    handlechan={handleTagChange}
                    value={tagValue}
                  />
                  <DropDown
                    option={tagChildren}
                    handlechan={handleTagChildrenChange}
                    value={`${tagValueChildren}`}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox code={tagValueChildren} />
              </Grid>
            </Grid>
            {tagChildrenSize?.length ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <div className="search">Search Sizes</div>

                  <DropDown
                    option={tagChildrenSize}
                    handlechan={handleTagSizeChange}
                    value={`${tagChildrenSizeValue}`}
                  />
                </Grid>
                <Grid item xs={12} sm={4} className="code-side-section">
                  <div className="search">codes</div>
                  <CodeInputBox code={tagChildrenSizeValue} />
                </Grid>
              </Grid>
            ) : null}
            {tagColor && tagChildrenSize?.length ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                  <div className="form-heading">Colour</div>
                  <div className="search">Search colours</div>
                  <div className="dropdown-section-color">
                    <DropDown
                      placholder="Product Colour"
                      option={color}
                      handlechan={handleColorChange}
                      value={colorValue}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} className="code-side-section">
                  <div className="search">codes</div>
                  <CodeInputBox code={colorValue} />
                </Grid>
              </Grid>
            ) : null}

            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="search">Search Product condition</div>
                <DropDown
                  option={ProductCondition}
                  handlechan={handleProductConditionChange}
                  value={productConditionValue}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox productCondition code={productConditionValue} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="search">Search Product Value</div>
                <DropDown
                  placholder="Product Value"
                  option={badge}
                  handlechan={handleBadgeChange}
                  value={`${badgeValue}`}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox code={badgeValue} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="search">Search Dispatching from</div>
                <DropDown
                  placholder="Country you are dispatching from"
                  option={countrie}
                  handlechan={handleCountrieChange}
                  value={countrieValue}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox code={countrieValue} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="search">Search Processing time</div>
                <DropDown
                  placholder="Select Processig Time"
                  option={processingTime}
                  handlechan={handleProcessingTimeChange}
                  value={processingTimeValue}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox code={processingTimeValue} />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="search">Search Delivery courier</div>
                <DropDown
                  placholder="Select Delivery Courier"
                  option={couriers}
                  handlechan={handleCourierValuesChange}
                  value={couriersValue}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox code={couriersValue} />
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={8}>
                <div className="search">Search Delivery class</div>
                <DropDown
                  placholder=" Select Delivery Class"
                  option={courierClass}
                  handlechan={handleCourierClassChange}
                  value={couriersClassValue}
                />
              </Grid>
              <Grid item xs={12} sm={4} className="code-side-section">
                <div className="search">codes</div>
                <CodeInputBox code={couriersClassValue} />
              </Grid>
            </Grid>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default BulkUpload;
