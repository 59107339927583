import React from "react";
import DarkBtn from "../../components/universal/Buttons/DarkBtn";

const ViewDetails = () => {
  return (
    <div className="view-details">
      <div className="category-heading">Category Details</div>
      
      <div className="card-container">
        <h3 className="details-text">Name :</h3>
        <p className="details-para">Ethical</p>
        <h3 className="details-text">Badge Category :</h3>
        <p className="details-para">7</p>
        <h3 className="details-text">Image :</h3>
        <div className="image-container">
          <img src="" alt="img" />
        </div>
        <h3 className="details-text">Sub Category Name :</h3>
        <div className="para">
          <p className="details-para">Women's Fashion</p>
        </div>
        <div className="para">
          <p className="details-para">Men's Fashion</p>
        </div>
        <div className="para">
          <p className="details-para">Things for her</p>
        </div>
        <div className="para">
          <p className="details-para">For the Men</p>
        </div>
        <div className="para">
          <p className="details-para">Women's Accessories</p>
        </div>
        <div className="para">
          <p className="details-para">Men's Accessories</p>
        </div>
        <div className="para">
          <p className="details-para">Health and Fitness</p>
        </div>
        <div className="para">
          <p className="details-para">Jewellery and Watches</p>
        </div>
        <div className="para">
          <p className="details-para">Baby, Child & Toys</p>
        </div>
        <div className="para">
          <p className="details-para">Home & Lifestyle</p>
        </div>
        <div className="para">
          <p className="details-para">Home Décor</p>
        </div>
        <div className="para">
          <p className="details-para">Stationary</p>
        </div>
        <div className="para">
          <p className="details-para">Gifts and Novelty</p>
        </div>
        <div className="para">
          <p className="details-para">Pets</p>
        </div>
        <div className="para">
          <p className="details-para">Food</p>
        </div>
        <div className="para">
          <p className="details-para">Beverages</p>
        </div>
        <div className="para">
          <p className="details-para">Children's Clothing</p>
        </div>
        <div>
          <DarkBtn title="Back" />
        </div>
      </div>
    </div>
  );
};

export default ViewDetails;
