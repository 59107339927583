import { useEffect, useState } from 'react';
import Multiselect from 'multiselect-react-dropdown';

const EditMultiSelect = ({
  badges,
  selectedBadges,
  setSelectedBadges,
  setMetadata,
  metadata,
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    const options = badges.map((badge) => ({
      category: 1,
      content: `${badge.id}`,
      name: badge.name,
      type: 5,
    }));
    setOptions(options);
  }, [badges]);

  useEffect(() => {
    if (metadata?.length === 0 && selectedBadges) {
      setMetadata(selectedBadges);
    }
  }, [selectedBadges, metadata]);

  return (
    <Multiselect
      options={options}
      selectionLimit={4}
      selectedValues={selectedBadges?.filter(
        (badge, index) =>
          selectedBadges.findIndex((b) => b.content === badge.content) === index
      )}
      onSelect={(selectedList, selectedItem) => {
        setSelectedBadges(selectedList);
        setMetadata(selectedList);
      }}
      onRemove={(selectedList, selectedItem) => {
        const newMetadata = selectedBadges.map((badge) => {
          if (badge.content === selectedItem.content) {
            return {
              action: 'delete',
              ...badge,
            };
          }
          return badge;
        });
        setMetadata(newMetadata);
        setSelectedBadges(selectedList);
      }}
      displayValue="name"
    />
  );
};

export default EditMultiSelect;
