import InfoIcon from '@mui/icons-material/Info';
import { Container, Grid, Tooltip } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import DispatchOrderDialog from '../../../components/dialogs/DispatchOrder';
import RefundDialog from '../../../components/dialogs/Refund';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import MainHeading from '../../../components/universal/Headings/MainHeading';
import SubHeading from '../../../components/universal/Headings/SubHeading';
import { capitalizeFirstLetter } from '../../../core/constants';
import { axiosAll } from '../../../services/axiosAll';

const SellerOrderDetails = () => {
  const [orderDetail, setOrderDetail] = useState();
  const [refundedItems, setRefundedItems] = useState([]);
  const [notRefundedItems, setNotRefundedItems] = useState([]);
  const [isProductRefundable, setIsProductRefundable] = useState(false);
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const Order_id = location?.state?.id;
  const Order = location?.state?.order;

  const path = `/onboarding/invoice?order_id=${Order_id}`;

  const handleOrderDetail = async (Order_id) => {
    const responce = await axiosAll({
      method: 'get',
      url: `v2/order/details/${Order_id}`,
    });
    setOrderDetail(responce?.result);
  };

  const handleNavigate = () => {
    window.open(window.location.origin + path, '_blank');
    // Nav(path, { state: { orderId: Order_id } });
  };

  const Headings = `Order detail #${orderDetail?.order?.generatedOrderId}`;
  useEffect(() => {
    handleOrderDetail(Order_id);
  }, [open]);

  useEffect(() => {
    const refund = orderDetail?.orderItems?.filter(
      (item) => item?.refundId !== null
    );
    const notRefund = orderDetail?.orderItems?.filter(
      (item) => item?.refundId === null
    );
    setNotRefundedItems(notRefund);
    setRefundedItems(refund);
  }, [orderDetail]);

  useEffect(() => {
    const refundable = notRefundedItems
      ?.map((item) => {
        if (item?.isRefundable === 1) {
          return item;
        }
      })
      .filter((item) => item !== undefined);

    if (refundable?.length > 0) {
      setIsProductRefundable(true);
    } else {
      setIsProductRefundable(false);
    }
  }, [notRefundedItems]);

  const isShowDispatch = () => {
    // if any item from orderDetail?.orderItems is item?.statusMessage === 'PENDING' or item?.trackingNo then return true else return false
    const isPending = orderDetail?.orderItems?.filter(
      (item) => item?.statusMessage === 'PENDING'
    );
    if (isPending?.length > 0) {
      return true;
    }
    return false;
  };

  return (
    <div className="sellerorderdetails">
      <Container className="all-container-27inch">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} className="sellerorderdetails-container">
            <MainHeading title={Headings} />
            <div className="order-para">
              Once you have marked item(s) as dispatched we will release payment
              to your nominated bank account.
            </div>
            <div className="data-container">
              <SubHeading title="Details" />
              <Grid container spacing={0} className="borderadd">
                <Grid item xs={12} sm={3.4}>
                  <div className="ordernumber"> Order number </div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <div className="orderid">
                    <span>(#{orderDetail?.order?.generatedOrderId})</span>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="borderadd">
                <Grid item xs={12} sm={3.4}>
                  <div className="ordernumber"> Order Date </div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <div className="orderid">
                    {moment(orderDetail?.order?.createdAt).format(
                      'MMMM D, YYYY [@]h:mm A'
                    )}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="borderadd">
                <Grid item xs={12} sm={3.4}>
                  <div className="ordernumber"> Order Status </div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <div
                    className={`orderid ${
                      orderDetail?.orderItems[0]?.status === 1
                        ? 'pendingbtn'
                        : orderDetail?.orderItems[0]?.status === 2
                        ? 'deliveredbtn'
                        : orderDetail?.orderItems[0]?.status === 3
                        ? 'cancelbtn'
                        : orderDetail?.orderItems[0]?.status === 4
                        ? 'cancelbtn'
                        : orderDetail?.orderItems[0]?.status === 5
                        ? 'partialbtn'
                        : 'cancelbtn'
                    }`}
                  >
                    {orderDetail?.orderItems[0]?.status === 1
                      ? 'Pending'
                      : orderDetail?.orderItems[0]?.status === 2
                      ? 'Dispatched'
                      : orderDetail?.orderItems[0]?.status === 3
                      ? 'Cancelled'
                      : orderDetail?.orderItems[0]?.status === 4
                      ? 'Disputed'
                      : orderDetail?.orderItems[0]?.status === 5
                      ? 'Partial Refund'
                      : orderDetail?.orderItems[0]?.status === 6
                      ? 'Panding Payment'
                      : orderDetail?.orderItems[0]?.status === 7
                      ? 'Failed Payment'
                      : orderDetail?.orderItems[0]?.status === 8
                      ? 'Completed Order'
                      : orderDetail?.orderItems[0]?.status === 9
                      ? 'Payment Not Auth'
                      : ''}
                    {/* <CancelBtn /> */}
                  </div>
                </Grid>
              </Grid>

              {!(
                orderDetail?.orderItems[0]?.status === 1 ||
                orderDetail?.orderItems[0]?.status === 3
              ) &&
              (orderDetail?.orderItems[0]?.orderShippingCharges
                ?.shippingCourier ||
                orderDetail?.orderItems[0]?.orderShippingCharges
                  ?.shippingClass) ? (
                <>
                  {orderDetail?.orderItems[0]?.orderShippingCharges
                    ?.shippingCourier ? (
                    <Grid container spacing={0} className="borderadd">
                      <Grid item xs={12} sm={3.4}>
                        <div className="ordernumber">Courier</div>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <div className="orderid">
                          {
                            orderDetail?.orderItems[0]?.orderShippingCharges
                              ?.shippingCourier
                          }
                        </div>
                      </Grid>
                    </Grid>
                  ) : null}
                  {orderDetail?.orderItems[0]?.orderShippingCharges
                    ?.shippingClass ? (
                    <Grid container spacing={0} className="borderadd">
                      <Grid item xs={12} sm={3.4}>
                        <div className="ordernumber">Courire Class</div>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <div className="orderid">
                          {
                            orderDetail?.orderItems[0]?.orderShippingCharges
                              ?.shippingClass
                          }
                        </div>
                      </Grid>
                    </Grid>
                  ) : null}
                </>
              ) : null}

              <Grid container spacing={0} className="borderadd">
                <Grid item xs={12} sm={3.4}>
                  <div className="ordernumber">Buyer Name</div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <div className="orderid">
                    {capitalizeFirstLetter(orderDetail?.order?.buyer?.name)}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="borderadd">
                <Grid item xs={12} sm={3.4}>
                  <div className="ordernumber">Buyer Email</div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <div className="orderid">
                    {orderDetail?.order?.buyer?.email}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="borderadd">
                <Grid item xs={12} sm={3.4}>
                  <div className="ordernumber">Shipping Address</div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <div className="orderid">
                    {orderDetail?.order?.addressName},{'  '}
                    {orderDetail?.order?.address}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={0} className="borderadd">
                <Grid item xs={12} sm={3.4}>
                  <div className="ordernumber">Billing Address</div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <div className="orderid">
                    {orderDetail?.order?.billingAddressName},
                    {orderDetail?.order?.billingAddress}
                    {'  '}
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={3.4}>
                  <div className="ordernumber">Tracking number</div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <div className="orderid">
                    {orderDetail?.orderItems[0]?.trackingNo}
                  </div>
                </Grid>
              </Grid>
            </div>
            <SubHeading title="Order Items" />
            <div className="table-container">
              <table>
                <tr className="table-heading">
                  <th style={{ width: '67%' }}>Item</th>
                  <th style={{ width: '67%' }}>Status</th>
                  <th style={{ textAlign: 'center' }}>Quantity</th>
                  <th style={{ textAlign: 'end', paddingRight: '40px' }}>
                    Total(£)
                  </th>
                </tr>

                {orderDetail?.orderItems?.map((item, index) => {
                  return (
                    <tr index={index} key={index} className="table-data">
                      <td>
                        {item?.name}
                        {Order === 'order' ? (
                          <div>
                            {item?.personalization ? (
                              <p>Personalisation: {item?.personalization}</p>
                            ) : null}
                            {item?.productInventory?.sizes?.size ? (
                              <p>
                                {' '}
                                Size : {item?.productInventory?.sizes?.size}
                              </p>
                            ) : null}
                            {item?.productInventoryColor?.colorMaster?.color ? (
                              <p>
                                {' '}
                                Color :{' '}
                                {
                                  item?.productInventoryColor?.colorMaster
                                    ?.color
                                }
                              </p>
                            ) : null}
                            {item?.giftMessage ? (
                              <p> Gift : {item?.giftMessage}</p>
                            ) : null}
                            {item?.giftWrappingPrice ? (
                              <p>
                                {' '}
                                Gift Wrapping : £
                                {item?.giftWrappingPrice?.toFixed(2)}
                              </p>
                            ) : null}
                          </div>
                        ) : (
                          ''
                        )}
                      </td>{' '}
                      <td style={{ textAlign: 'center' }}>
                        <div
                          className={`orderid ${
                            item?.status === 1
                              ? 'pendingbtn'
                              : item?.status === 2
                              ? 'deliveredbtn'
                              : item?.status === 3
                              ? 'cancelbtn'
                              : item?.status === 4
                              ? 'cancelbtn'
                              : item?.status === 5
                              ? 'partialbtn'
                              : 'cancelbtn'
                          }`}
                        >
                          {item?.status === 1
                            ? 'Pending'
                            : item?.status === 2
                            ? 'Dispatched'
                            : item?.status === 3
                            ? 'Cancelled'
                            : item?.status === 4
                            ? 'Disputed'
                            : item?.status === 5
                            ? 'Partial Refund'
                            : item?.status === 6
                            ? 'Panding Payment'
                            : item?.status === 7
                            ? 'Failed Payment'
                            : item?.status === 8
                            ? 'Completed Order'
                            : item?.status === 9
                            ? 'Payment Not Auth'
                            : ''}
                        </div>
                      </td>
                      <td style={{ textAlign: 'center' }}>{item?.stocks}</td>
                      <td style={{ textAlign: 'end', paddingRight: '40px' }}>
                        £{item?.priceNoVat?.toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
              </table>
              <div className="second-table">
                <div className="second-table-bg">
                  <Grid container spacing={2}>
                    <Grid item xs={5} sm={8} md={8}>
                      <div></div>
                    </Grid>
                    <Grid item xs={3.5} sm={2} md={2}>
                      <div className="table-data2 "> Subtotal </div>
                    </Grid>
                    <Grid item xs={3.5} sm={2} md={2}>
                      <div className="table-price">
                        £{orderDetail?.orderSummary?.subTotal?.toFixed(2)}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={5} sm={8}>
                      <div></div>
                    </Grid>
                    <Grid item xs={3.5} sm={2}>
                      <div className="table-data2 "> Shipping </div>
                    </Grid>
                    <Grid item xs={3.5} sm={2}>
                      <div className="table-price">
                        £
                        {orderDetail?.orderSummary?.shippingTotalNoVat?.toFixed(
                          2
                        )}
                      </div>
                    </Grid>
                  </Grid>

                  {orderDetail?.orderSummary?.giftWrappingTotal ? (
                    <Grid container spacing={2}>
                      <Grid item xs={5} sm={8}>
                        <div></div>
                      </Grid>
                      <Grid item xs={3.5} sm={2}>
                        <div className="table-data2 "> Gift Wrapping </div>
                      </Grid>
                      <Grid item xs={3.5} sm={2}>
                        <div className="table-price">
                          £
                          {orderDetail?.orderSummary?.giftWrappingTotal?.toFixed(
                            2
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  ) : null}
                  <Grid container spacing={2}>
                    <Grid item xs={5} sm={8}>
                      <div></div>
                    </Grid>
                    <Grid item xs={3.5} sm={2}>
                      <div className="table-data2 "> Vat @ 20% </div>
                    </Grid>
                    <Grid item xs={3.5} sm={2}>
                      <div className="table-price">
                        {' '}
                        £{orderDetail?.orderSummary?.vatTotal?.toFixed(2)}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xs={5} sm={8}>
                      <div></div>
                    </Grid>
                    <Grid item xs={3.5} sm={2}>
                      <div className="table-data2 "> Gross Total</div>
                    </Grid>
                    <Grid item xs={3.5} sm={2}>
                      <div className="table-price">
                        {' '}
                        £{orderDetail?.orderSummary?.grossTotal?.toFixed(2)}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>{' '}
            {orderDetail?.orderItems[0]?.statusMessage == 'DEFAULT' ? (
              ''
            ) : (
              <div className="data-container">
                <SubHeading title="Fee breakdown" />
                <Grid container spacing={0} className="borderadd">
                  <Grid item xs={12} sm={3.4}>
                    <div className="ordernumber"> Gross amount </div>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <div className="orderid">
                      £{orderDetail?.orderSummary?.grossTotal?.toFixed(2)}
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={0} className="borderadd">
                  <Grid item xs={12} sm={3.4}>
                    <div className="ordernumber"> Card processing fee </div>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <div className="orderid">
                      £{orderDetail?.orderSummary?.feeBreakdownCpf?.toFixed(2)}
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={0} className="borderadd">
                  <Grid item xs={12} sm={3.4}>
                    <div className="ordernumber"> Go Ethical Commission </div>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <div className="orderid">
                      £{orderDetail?.orderSummary?.feeBreakdownGec?.toFixed(2)}
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={0} className="borderadd">
                  <Grid item xs={12} sm={3.4}>
                    <div className="ordernumber">
                      Net Payout
                      <Tooltip
                        title="This value will be calculated once the product is dispatched and Stripe has charged the buyer."
                        placement="right"
                      >
                        <InfoIcon />
                      </Tooltip>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <div className="orderid">
                      £{orderDetail?.orderSummary?.feeBreakdownNpo?.toFixed(2)}
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={3.4}>
                    <div className="ordernumber">Payout Date</div>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <div className="orderid">
                      {moment(
                        orderDetail?.orderSummary?.feeBreakdownPayoutDate
                      ).format('MMMM D, YYYY [@]h:mm A')}
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
            {refundedItems?.length > 0
              ? refundedItems?.map((item, index) => (
                  <div key={index} className="data-container">
                    <SubHeading title="Refund" />
                    <Grid container spacing={0} className="borderadd">
                      <Grid item xs={12} sm={3.4}>
                        <div className="ordernumber"> Refund Amount </div>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <div className="orderid">£{item?.refundAmount}</div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={0} className="borderadd">
                      <Grid item xs={12} sm={3.4}>
                        <div className="ordernumber">
                          Refund Status{' '}
                          <Tooltip
                            title="After you initiate a refund, Stripe submits refund requests to your customer's bank or card issuer. Your customer sees the refund as a credit approximately 5-10 business days later, depending upon the bank."
                            placement="right"
                          >
                            <InfoIcon />
                          </Tooltip>{' '}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <div className="orderid">Refunded</div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={0} className="borderadd">
                      <Grid item xs={12} sm={3.4}>
                        <div className="ordernumber">Refund Date </div>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <div className="orderid">
                          {' '}
                          {moment(item?.refundDate).format(
                            'DD/MM/YYYY [@]h:mm A'
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                ))
              : null}
            {/* {orderDetail?.orderItems[0]?.statusMessage == 'DEFAULT' ? (
              ''
            ) : ( */}
            <>
              <div className="last-btn-section">
                {orderDetail?.orderItems[0]?.statusMessage === 'DISPUTED' ||
                orderDetail?.orderItems[0]?.statusMessage === 'DELIVERED' ? (
                  <>
                    <div onClick={handleNavigate}>
                      <LightBtn title="View invoice" />
                    </div>
                  </>
                ) : (
                  ''
                )}

                {Order ? (
                  ''
                ) : isProductRefundable ? (
                  <RefundDialog
                    OrdersList={notRefundedItems?.map((item) => {
                      return { value: item?.id, title: item?.name };
                    })}
                    open={open}
                    setOpenUp={setOpen}
                  />
                ) : (
                  ' '
                )}
              </div>
              <div className="last-btn-section">
                {orderDetail?.orderItems[0]?.statusMessage == 'PENDING' ? (
                  <div onClick={handleNavigate}>
                    <LightBtn
                      title={Order ? 'Generate Invoice' : 'View Invoice'}
                    />
                  </div>
                ) : (
                  ''
                )}
                {Order ? (
                  <>
                    {isShowDispatch() && (
                      <DispatchOrderDialog
                        order_id={orderDetail?.orderItems
                          ?.map((item) => {
                            if (item?.status !== 3) {
                              return item.id;
                            }
                          })
                          .filter((item) => item !== undefined)}
                        id={Order_id}
                        open={open}
                        setOpen={setOpen}
                        courier={
                          orderDetail?.orderItems[0]?.orderShippingCharges
                            ?.shippingCourier
                        }
                        CourierClass={
                          orderDetail?.orderItems[0]?.orderShippingCharges
                            ?.shippingClass
                        }
                      />
                    )}
                  </>
                ) : (
                  ''
                )}
              </div>
            </>
            {/* )} */}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SellerOrderDetails;
