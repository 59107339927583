import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import KeyIcon from "@mui/icons-material/Key";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import Grid from "@mui/material/Grid";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import DrakBtn from "../../../components/universal/Buttons/DarkBtn";
import sweatAlertBox from "../../../components/universal/SweetAlertBox";
import { notify } from "../../../core/constants";
import { axiosAll } from "../../../services/axiosAll";

export const SellerIdDetails = () => {
  const param = useParams();
  const [data, setData] = useState();
  const nav = useNavigate();

  var Brand = [...new Set(data?.ethicalProductsType)];

  const getDetails = async () => {
    const response = await axiosAll({
      method: "get",
      url: `v2/pro_seller_requests/${param?.id}`,
    });
    setData(response?.result?.proSeller);
  };

  const handleDelete = async (id) => {
    const response = await axiosAll({
      method: "delete",
      url: `v2/pro_seller_requests/${id}`,
    });
    if (response?.status == "Success") {
      notify(response?.message, "success");
      nav("/admin/user-registration");
    } else {
      notify(response?.message, "error");
    }
  };

  useEffect(() => {
    getDetails();
  }, [param]);

  return (
    <div className="SellerIdDetails">
      <div className="user-heading">
        <h1 className="view-heading">View Entry</h1>
        <DrakBtn
          title=" Back to all entries "
          onClick={() => nav("/admin/user-registration")}
        />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={7.5}>
          <div className="width70grid">
            <div className="start-icon">
              <div className="icon">
                <StarOutlinedIcon />
              </div>
              <h1 className="user-id">User Registration ID</h1>
            </div>

            <div className="life-cycle">
              <h2>Life Cycle Stage</h2>
            </div>
            <div className="opportunity">
              <p>Opportunity</p>
            </div>

            <div className="life-cycle">
              <h2>Data Source</h2>
            </div>
            <div className="opportunity">
              <p>Website Registration</p>
            </div>

            <div className="life-cycle">
              <h2>Your Name</h2>
            </div>
            <div className="opportunity">
              <p>
                {data?.firstName} {data?.lastName}
              </p>
            </div>

            <div className="life-cycle mrgbottom">
              <h2>Seller Shop / Company Name</h2>
            </div>
            <div className="opportunity">
              <p>{data?.companyName}</p>
            </div>

            <div className="life-cycle mrgbottom">
              <h2>Are you a Registered Limited Company?</h2>
            </div>
            <div className="opportunity">
              <p>{data?.isRegistered}</p>
            </div>

            <div className="life-cycle mrgbottom">
              <h2>Limited company registration number</h2>
            </div>
            <div className="opportunity">
              <p>
                {data?.registrationNumber ? data?.registrationNumber : "Empty"}
              </p>
            </div>

            <div className="life-cycle mrgbottom">
              <h2>Are you VAT registered</h2>
            </div>
            <div className="opportunity">
              <p>{data?.isVat}</p>
            </div>

            <div className="life-cycle mrgbottom">
              <h2>VAT Number</h2>
            </div>
            <div className="opportunity">
              <p>{data?.vatNumber ? data?.vatNumber : "Empty"}</p>
            </div>

            <div className="life-cycle mrgbottom">
              <h2>Company Address</h2>
            </div>
            <div className="opportunity">
              <p>{data?.address1}</p>
              <p>{data?.address2}</p>
              <p>{`${data?.city}, ${data?.state}`}</p>
              <p>{data?.postcode}</p>
              <p>{data?.country}</p>
            </div>

            <div className="life-cycle mrgbottom">
              <h2>Email</h2>
            </div>
            <div className="opportunity">
              <p>{data?.email ? data?.email : "Empty"}</p>
            </div>

            <div className="life-cycle mrgbottom">
              <h2>Phone</h2>
            </div>
            <div className="opportunity">
              <p>{data?.phone ? data?.phone : "Empty"}</p>
            </div>

            <div className="life-cycle mrgbottom">
              <h2>Do you have a website for your business?</h2>
            </div>
            <div className="opportunity">
              <p>{data?.isWebsite}</p>
            </div>

            <div className="life-cycle mrgbottom">
              <h2>Website / URL</h2>
            </div>
            <div className="opportunity">
              <p>{data?.website ? data?.website : "Empty"}</p>
            </div>

            <div className="life-cycle mrgbottom">
              <h2>What type of trader are you?</h2>
            </div>
            <div className="opportunity">
              <p>{data?.traderType ? data?.traderType : "Empty"}</p>
            </div>

            <div className="life-cycle mrgbottom">
              <h2>Brand core value confirmation</h2>
            </div>
            <div className="opportunity">
              <p>{Brand.join(",") ? Brand.join(",") : "Empty"}</p>
            </div>

            <div className="life-cycle mrgbottom">
              <h2>What made you choose to use GoEthical? (optional)</h2>
            </div>
            <div className="opportunity">
              <p>
                {data?.reasonForChoosingBazar
                  ? data?.reasonForChoosingBazar
                  : "Empty"}
              </p>
            </div>

            <div className="life-cycle mrgbottom">
              <h2>Where did you hear about us?</h2>
            </div>
            <div className="opportunity">
              <p>{data?.reachOptions ? data?.reachOptions : "Empty"}</p>
            </div>

            <div className="life-cycle mrgbottom">
              <h2>Do you sell on any existing marketplaces?</h2>
            </div>
            <div className="opportunity">
              <p>{data?.existingMarketPlace}</p>
            </div>

            <div className="life-cycle mrgbottom">
              <h2>Does your store run on an E-commerce system, eg. Shopify?</h2>
            </div>
            <div className="opportunity">
              <p>{data?.doesStoreRunEcommerce}</p>
            </div>

            <div className="life-cycle ">
              <h2>Terms and Conditions</h2>
            </div>
            <div className="opportunity mrgbottom">
              <p>I agree to the terms and conditions below</p>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className="right-container">
            <div className="inner-container">
              <h3 className="entry-text">Entry Details</h3>
              <div className="key-id">
                <div className="keyicon">
                  <KeyIcon />
                </div>
                <h3 className="entry-id">Entry ID : </h3>
                <h3 className="entry-id-data"> {data?.id}</h3>
              </div>
              <div className="key-id">
                <div className="keyicon">
                  <CalendarMonthIcon />
                </div>
                <h3 className="entry-id">Submitted :</h3>
                <h3 className="entry-id-data">
                  {moment(data?.createdAt).format("MMMM D, YYYY [@]h:mm A")}
                </h3>
              </div>
              <div className="key-id">
                <div className="keyicon">
                  <CalendarTodayIcon />
                </div>
                <h3 className="entry-id">Modified :</h3>
                <h3 className="entry-id-data">
                  {moment(data?.updatedAt).format("MMMM D, YYYY [@]h:mm A")}
                </h3>
              </div>
            </div>
            <div className="delete-container">
              <button
                className="delete-entry"
                onClick={() =>
                  sweatAlertBox(
                    "Delete Entry",
                    "Are you sure you want to delete this item?",
                    "Yes",
                    "No",

                    handleDelete,
                    param?.id
                  )
                }
              >
                Delete Entry
              </button>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
export default SellerIdDetails;
