import Grid from '@mui/material/Grid';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { images } from '../../../assets';
import SellerMobileMenu from '../../../pages/Seller/SellerMobileMenu';
import { deleteLogin } from '../../../redux/actions/login';
import { deleteUser } from '../../../redux/actions/user';
import { updateGuestUser } from '../../../redux/actions/guestUser';
import { addLogin } from '../../../redux/actions/login';

const SellerSideBar = () => {
  const dispatch = useDispatch();
  const { userDetails } = useSelector((state) => state.loginReducer);

  const nav = useNavigate();
  const oldData = JSON.parse(localStorage.getItem('oldData'));

  const handleExitImpersonating = () => {
    dispatch(deleteLogin());
    dispatch(deleteUser());
    dispatch(addLogin(oldData));
    dispatch(updateGuestUser(oldData));
    localStorage.removeItem('oldData');
    nav('/admin/seller');
  };

  return (
    <>
      {oldData && (
        <div className="top-picker">
          You are impersonating {userDetails?.user?.name}
          <span
            style={{
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
            onClick={handleExitImpersonating}
          >
            {' '}
            Exit View
          </span>
        </div>
      )}
      <div className="mobileshow tabshow ">
        <div className="mobileviewset">
          <img src={images.mainLogo} className="logogoethical" />
          <SellerMobileMenu />
        </div>
      </div>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={2.5} md={3} xl={2} className="mobilehide tabhide bgcoloradmin">
          <div className="sellersidebar">
            <img src={images.mainLogo} className="logogoethical" />
            <Link to="dashboard" className="item2">
              <img src={images.dashboard} className="new-sidebar" />
              <div className="sidebar-menu-name">Dashboard</div>
            </Link>
            <Link to="product-images" className="item2">
              <img src={images.gallery} className="new-sidebar" />
              <div className="sidebar-menu-name">Product images</div>
            </Link>
            <Link to="products" className="item2">
              <img src={images.book} className="new-sidebar" />
              <div className="sidebar-menu-name">Products</div>
            </Link>
            <Link to="orders" className="item2">
              <img src={images.basket} className="new-sidebar" />
              <div className="sidebar-menu-name">Orders</div>
            </Link>
            <Link to="financial-reports" className="item2">
              <img src={images.financials} className="new-sidebar" />
              <div className="sidebar-menu-name">Financial reports</div>
            </Link>
            <Link to="my-profile" className="item2">
              <img src={images.user} className="new-sidebar" />
              <div className="sidebar-menu-name">My profile</div>
            </Link>
            <div
              onClick={() => {
                dispatch(deleteLogin());
                dispatch(deleteUser());
                nav('/login');

                localStorage.removeItem('oldData');
              }}
              className="item2"
            >
              <img src={images.signOut} className="new-sidebar" />
              <div className="sidebar-menu-name">Logout</div>
            </div>
            <div className="image-me">
              <img src={images.sideMenuImg} />
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={9} xl={10} className="dataside">
          <Outlet />
        </Grid>
      </Grid>
    </>
  );
};

export default SellerSideBar;
