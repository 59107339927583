import { Container } from '@mui/material';
import React, { useEffect, useState } from 'react';
import OrderSkeleton from '../../../components/Skeleton/OrderSkeleton';
import DropDown from '../../../components/universal/DropDown';
import MainHeading from '../../../components/universal/Headings/MainHeading';
import BasicPagination from '../../../components/universal/Pagination/';
import SearchBar from '../../../components/universal/SearchBar';
import { OrderOption } from '../../../core/constants';
import OrderTable from '../../../pages/Seller/Orders/OrderTable';
import { axiosAll } from '../../../services/axiosAll';
const Orders = () => {
  const TableColumn = [
    'Order No',
    'Status',
    'Order Date',
    'Gross sales (£)',
    ' No. of items',
  ];
  const [order, setOrder] = useState();
  const [page, setPage] = useState(0);
  const [paginationPage, setPaginationPage] = React.useState(0);
  const [searchProduct, setSearch] = useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [Loading, setLoading] = useState(false);
  const [status, setStatus] = useState(0);

  const getOrder = async (status) => {
    setLoading(true);
    // setPage(page + 1);
    const params = {
      status: status,
      search: searchProduct ? searchProduct : '',
      page: page + 1,
      limit: 10,
      offset: page * 10,
      ordertype: 2,
    };

    const response = await axiosAll({
      method: 'get',
      url: `v1/order`,
      params,
    });
    if (response?.statusCode == 200) {
      setOrder(response?.result);
    }
    setLoading(false);
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleStatus = (e) => {
    const { value } = e.target;
    setStatus(value);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPaginationPage(0);
    setPage(page + 1);
    getOrder(rowsPerPage);
  };

  useEffect(() => {
    getOrder(status);
  }, [searchProduct, status, page]);

  return (
    <div className="sellerorder">
      <Container className="all-container-27inch">
        <div className="sellerordercontainer">
          <MainHeading title="Orders" />
          <div className="order-row">
            <SearchBar handleChange={handleSearch} value={searchProduct} />
            <DropDown
              option={OrderOption}
              handlechan={handleStatus}
              value={status}
            />
          </div>
          {Loading ? (
            <OrderSkeleton />
          ) : (
            <>
              {' '}
              <OrderTable
                TableColumn={TableColumn}
                Row={order?.data}
                page={paginationPage}
                rowsPerPage={rowsPerPage}
                path={'/onboarding/order-details'}
                name={'Order'}
              />
            </>
          )}

          {Math.floor(order?.total / 10) > 1 ? (
            <BasicPagination
              page={page}
              setPage={setPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              // Row={order?.data}
              total={order?.total}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
            />
          ) : (
            ''
          )}
        </div>
      </Container>
    </div>
  );
};

export default Orders;
