// import LightBtn from '../../components/universal/Buttons/LightBtn';
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Checkbox } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import * as Yup from "yup";
import DarkBtn from "../../components/universal/Buttons/DarkBtn";
import InputTypeOutline from "../../components/universal/InputType/inputTypeOutline";
import ProgressBar from "../../components/universal/ProgressBar";
import { baseUrl, notify } from "../../core/constants";
import { store } from "../../redux/store";
import { axiosAll, AxiosAll } from "../../services/axiosAll";

const CreateAccount = () => {
  const [isPasswordShow, setIsPasswordShow] = React.useState(true);
  const [disableButton, setDisableButton] = useState(true);
  const [loading, setLoading] = useState(false);

  const nav = useNavigate();

  const params = useParams();

  const [ProsellerInfo, setProsellerInfo] = useState({});

  const getProSellerDetails = async () => {
    const response = await axiosAll({
      method: "get",
      url: `v2/pro_seller_requests-by-token/${params?.token}`,
      params: "",
    });
    if (response?.statusCode == 200) {
      setProsellerInfo(response?.result);
      formik?.setFieldValue(
        "name",
        response?.result?.proSeller?.firstName +
          " " +
          response?.result?.proSeller?.lastName
      );
      formik?.setFieldValue("email", response?.result?.proSeller?.email);
      formik?.setFieldValue("password", "");
    }
    // setLoading(false);
  };
  useEffect(() => {
    getProSellerDetails();
  }, [params?.token]);

  const CreatePassword = async (data, token) => {
    setLoading(true);
    try {
      const res = await AxiosAll("post", `user/createPassword?`, data, token);
      if (res.data.status == "Error") {
        setLoading(false);
        notify(res.data.message, "error");
      } else {
        setLoading(false);
        notify(res.data.message, "success");
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const CreateProSeller = async () => {
    const state = store.getState();
    const { guestDetails } = state.guestReducer;
    try {
        const res = await axios.put(
          `${baseUrl}pro_seller_requests/${params?.token}/status?deviceType=3&deviceToken=${guestDetails?.token}`,
          {status: 2}
        );
      if (res.data.status == "Error") {
        setLoading(false);
        notify(res.data.message, "error");
      } else {
        setLoading(false);
        notify(res.data.message, "success");
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const CreteAccountSubmit = async (data) => {
    const state = store.getState();
    const { guestDetails } = state.guestReducer;
    try {
      const res = await axios.post(
        `${baseUrl}user/register?deviceType=3&deviceToken=${guestDetails?.token}`,
        data
      );
      if (res.data.status == "Error") {
        notify(res.data.message, "error");
      } else {
        CreatePassword(
          { password: formik?.values?.password },
          res?.data?.result?.userToken
        );
        if( params?.token){
          CreateProSeller()
        }
        notify(res.data.message, "success");
        nav(
          `/verify-email/${res?.data?.result?.user?.id}/${res?.data?.result?.userToken}`
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  // formik start
  const initialValues = {
    name: "",
    email: "",
    password: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string()
      .required("Full name is required.")
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    password: Yup.string().required("Password is required."),
    email: Yup.string()
      .required("E-mail address is required.")
      .email("Enter valid email address"),
  });

  const onSubmit = (values) => {
    //Condition for full name
    const trimmedName = values?.name.trim();
    if (trimmedName.indexOf(" ") == -1) {
      notify("Please enter a valid full name", "error");
    } else if (
      !(
        values.password.length > 7 &&
        pattern.test(values.password) &&
        (pattern2.test(values.password) || pattterNo.test(values?.password))
      )
    ) {
      notify("Please fill the password correctly", "error");
    } else {
      CreteAccountSubmit(
        params?.token
          ? {
              name: values?.name,
              email: values?.email,
              proSellerToken: params?.token,
            }
          : {
              name: values?.name,
              email: values?.email,
            }
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (
      formik?.values?.email &&
      formik?.values?.password &&
      formik?.values?.name
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [formik?.values]);

  let pattern = new RegExp("^(?=.*[A-Z])");
  let pattern2 = new RegExp("(?=.*[-+_!@#$%^&*., ?])");
  let pattterNo = new RegExp("^(?=.*[0-9])");

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <ProgressBar value={20} />
      <div className="createaccount">
        <div className="text-heading">Create your account</div>
        <div className="text-para">Let's start by creating an account</div>
        <form onSubmit={formik.handleSubmit}>
          <div className="mrgbottom">
            <div className="label-name">Full name</div>
            <InputTypeOutline
              placeholder="Your name"
              name="name"
              id="name"
              type="text"
              onKeyDown={(e) => {
                if (
                  !(
                    (e.keyCode >= 65 && e.keyCode <= 90) ||
                    (e.keyCode >= 97 && e.keyCode <= 122) ||
                    e.keyCode === 32 ||
                    e.keyCode === 8 ||
                    e.keyCode === 9 ||
                    e.keyCode === 13 ||
                    e.keyCode === 37 ||
                    e.keyCode === 38 ||
                    e.keyCode === 39 ||
                    e.keyCode === 40
                  )
                ) {
                  e.preventDefault();
                }
              }}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.errors.name}
            />
          </div>
          <div className="mrgbottom">
            <div className="label-name">Email</div>
            <InputTypeOutline
              name="email"
              id="email"
              type="email"
              placeholder="Email address"
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
            />
          </div>
          <div className="mrgbottom">
            <div className="label-name">Password</div>
            <InputTypeOutline
              password
              name="password"
              id="password"
              type={isPasswordShow ? "password" : "text"}
              placeholder="Password"
              onBlur={formik.handleBlur}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.errors.password}
              isPasswordShow={isPasswordShow}
              setIsPasswordShow={setIsPasswordShow}
            />
          </div>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checkedIcon={<CheckCircleIcon />}
                  icon={<RadioButtonUncheckedIcon />}
                  checked={formik?.values.password.length > 7 ? true : false}
                />
              }
              label="Must be at least 8 character long"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checkedIcon={<CheckCircleIcon />}
                  icon={<RadioButtonUncheckedIcon />}
                  checked={
                    pattern2.test(formik?.values.password) ||
                    pattterNo.test(formik?.values?.password)
                      ? true
                      : false
                  }
                />
              }
              label="Contains at least one number or symbol"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checkedIcon={<CheckCircleIcon />}
                  icon={<RadioButtonUncheckedIcon />}
                  checked={pattern.test(formik?.values.password) ? true : false}
                />
              }
              label="Have one uppercase letter"
            />
          </FormGroup>
          <div className="btn-end">
            <DarkBtn
              title="Continue "
              loading={loading}
              type={"submit"}
              disable={disableButton}
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default CreateAccount;
