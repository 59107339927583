import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import BrandWithCauseDropDown from '../../universal/BrandWithCauseDropDown';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import ClearIcon from '@mui/icons-material/Clear';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { axiosAll } from '../../../services/axiosAll';
import { notify } from '../../../core/constants';

const AdminBrandWithCauseDialog = ({
  sellers,
  setIsBrandWithCauseUpdated,
  isBrandWithCauseUpdated,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formik.handleReset();
    setOpen(false);
  };

  const initialValues = {
    sellerId: '',
    storeName: '',
    cause: '',
    image: null,
  };

  const validationSchema = Yup.object().shape({
    sellerId: Yup.string().required('Seller  is required'),
    storeName: Yup.string().required('Store Name is required'),
    cause: Yup.string().required('Cause is required'),
    image: Yup.mixed().required('Image is required'),
  });

  const addBrand = async (values) => {
    const res = await axiosAll({
      url: 'v2/brand_with_causes',
      method: 'POST',
      data: values,
    });
    if (res.statusCode === 200) {
      notify(`${res.message}`, 'success');
      formik.handleReset();
      handleClose();
      setIsBrandWithCauseUpdated(!isBrandWithCauseUpdated);
    } else {
      notify(`${res.message}`, 'error');
    }
  };

  const onSubmit = (values) => {
    const { sellerId, storeName, cause, image } = values;

    if (sellerId === '') {
      notify('Seller is required', 'error');
    } else if (storeName === '') {
      notify('Store Name is required', 'error');
    } else if (cause === '') {
      notify('Cause is required', 'error');
    } else if (image === null) {
      notify('Image is required', 'error');
    } else {
      const formData = new FormData();
      formData.append('sellerId', sellerId);
      formData.append('storeName', storeName);
      formData.append('cause', cause);
      formData.append('image', image);
      addBrand(formData);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (formik.values.sellerId) {
      const storeName = sellers.find(
        (seller) => seller.id === formik.values.sellerId
      );
      formik.setFieldValue('storeName', storeName.userName);
    }
  }, [formik.values.sellerId, sellers]);

  return (
    <>
      <div className="AdminBrandWithCause">
        <Button className="pendingbtn" onClick={handleClickOpen}>
          Add row
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="admin-brand-withcause"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <form onSubmit={formik.handleSubmit} className="input-heading">
                <div className="top-section">
                  <div className="upload-text">Add New Brand With Cause</div>
                  <ClearIcon onClick={handleClose} style={{cursor:'pointer'}}/>
                </div>
                <label className="label-text">Pro Seller:</label>
                <BrandWithCauseDropDown
                  options={sellers}
                  name="sellerId"
                  value={formik.values.sellerId}
                  onChange={formik.handleChange}
                />
                <div className="checkout">
                  <label className="label-text">Store Name</label>
                  <InputTypeOutline
                    placeholder="Store Name"
                    value={formik.values.storeName}
                    name="storeName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.storeName && formik.errors.storeName}
                  />
                </div>
                <div className="heading">
                  <label className="label-text">Cause</label>
                  <InputTypeOutline
                    placeholder="Cause"
                    value={formik.values.cause}
                    name="cause"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.cause && formik.errors.cause}
                  />
                </div>
                <label className="label-text">Brand Image</label>
                <div className="choose-file">choose file:</div>
                <input
                  type="file"
                  id="myFile"
                  name="image"
                  onChange={(e) => {
                    formik.setFieldValue('image', e.target.files[0]);
                  }}
                />
                <div className="btn">
                  <DarkBtn title="Cancel" onClick={handleClose} />
                  <DarkBtn title="Submit" type="submit" />
                </div>
              </form>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default AdminBrandWithCauseDialog;
