import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import SellerFooter from '../components/universal/PublicFooter';
import PublicHeader from '../components/universal/PublicHeader';
import { addGuestUser } from '../redux/actions/guestUser';
import { AxiosAll } from '../services/axiosAll';
import AppRoutes from './app';
import AppAdminRoutes from './appAdmin';
import PublicRoutes from './public';

const Routes = () => {
  const { guestDetails } = useSelector((state) => state.guestReducer);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();

  const getUserData = async () => {
    try {
      const res = await AxiosAll('post', 'user/guestRegister?', {
        browser_id: guestDetails.browser_id,
        deviceToken: guestDetails.token,
        deviceType: 3,
      });
      dispatch(addGuestUser(res.data.result));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    // if userData is empty, get user data

    if (!userDetails?.token) {
      getUserData();
    }
  }, [userDetails.token]);
  return (
    <BrowserRouter>
      <Toaster />
      {userDetails.token ? null : <PublicHeader />}

      {userDetails.token ? (
        userDetails?.user?.userType == 1 ? (
          <AppAdminRoutes />
        ) : (
          <AppRoutes />
        )
      ) : (
        <PublicRoutes />
      )}
      <SellerFooter />
    </BrowserRouter>
  );
};

export default Routes;
