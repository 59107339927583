import React from "react";


const ThanksForRegistering = () => {
  return (
   <div className="thanksforregistering">
      <div className="thanks-para">
          <p className="para1"> Thanks for registering your interest in GoEthical! </p>
          <p className="para2"> We will be in your touch with you shortly. </p>
      </div>
   </div>
  );
};

export default ThanksForRegistering;
