import { Navigate, Route, Routes } from 'react-router';
import SellerSideBar from '../components/SellerSide/SellerSideBar';
import BrandWithCause from '../pages/Admin/BrandWithCause';
import Categories from '../pages/Admin/Categories';
import FeaturedSection from '../pages/Admin/FeaturedSection';
import PopularCategory from '../pages/Admin/PopularCategory';
import SellerAdmin from '../pages/Admin/SellerAdmin';
import BusinessDetails from '../pages/BusinessDetails';
import CreateAccount from '../pages/CreateAccount';
import Home from '../pages/Home';
import PolicyDetails from '../pages/PolicyDetails';
import ProSellerRegistration from '../pages/ProSellerRegistration';
import ResetPassword from '../pages/ResetPassword';
import Seller from '../pages/Seller';
import AddSingleProduct from '../pages/Seller/AddSingleProduct';
import Dashboard from '../pages/Seller/Dashboard';
import EditSingleProduct from '../pages/Seller/EditSingleProduct';
import FinancialReports from '../pages/Seller/FinancialReports';
import { MyProfile } from '../pages/Seller/MyProfile';
import Orders from '../pages/Seller/Orders';
import ProductImages from '../pages/Seller/ProductImages';
import Products from '../pages/Seller/Products';
import SellerOrderDetails from '../pages/Seller/SellerOrderDetails';

import OrderInvoice from "../pages/Seller/OrderInvoice";
import StoreDetails from "../pages/StoreDetails/indes";
import StripVerification from "../pages/StripVerification";
import VerifyYourEmail from "../pages/VerifyYourEmail";
import ViewDetails from "../pages/ViewDetails";
import Welcome from "../pages/Welcome";
import BulkUpload from '../pages/Seller/BulkUpload';

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route
          index
          element={<Navigate to="/onboarding/dashboard" replace />}
        />
        <Route
          path="login"
          element={<Navigate to="/onboarding/dashboard" replace />}
        />
        <Route path="onboarding" element={<SellerSideBar />}>
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="home" element={<Home />} />
          <Route path="seller" element={<Seller />} />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="create-account" element={<CreateAccount />} />
          <Route path="verify-email" element={<VerifyYourEmail />} />
          <Route path="store-details" element={<StoreDetails />} />
          <Route path="welcome" element={<Welcome />} />
          <Route path="policy-details" element={<PolicyDetails />} />
          <Route path="business-details" element={<BusinessDetails />} />
          <Route
            path="pro-seller-registration"
            element={<ProSellerRegistration />}
          />
          <Route path="categories" element={<Categories />} />
          <Route path="view-details" element={<ViewDetails />} />
          <Route path="popular-category" element={<PopularCategory />} />
          <Route path="brand-with-cause" element={<BrandWithCause />} />
          <Route path="products" element={<Products />} />
          <Route path="product-images" element={<ProductImages />} />
          <Route path="seller-admin" element={<SellerAdmin />} />
          <Route path="featured-section" element={<FeaturedSection />} />
          <Route path="add-single-product" element={<AddSingleProduct />} />
          <Route path="bulk-upload" element={<BulkUpload />} />
          
          <Route
            path="seller/edit-product/:title/:id"
            element={<EditSingleProduct />}
          />

          <Route path="orders" element={<Orders />} />
          <Route
            path="seller/order/details/:id"
            element={<SellerOrderDetails />}
          />
          <Route
            path="seller/view-finance-detail/reports/:id"
            element={<SellerOrderDetails />}
          />

          <Route path="my-profile" element={<MyProfile />} />
          <Route path="financial-reports" element={<FinancialReports />} />
        </Route>
        <Route path="onboarding/invoice" element={<OrderInvoice />} />
        <Route path="stripe-verification" element={<StripVerification />} />
        
        {/* <Route path="*" element={<NotFound />} /> */}
        <Route
          path="*"
          element={<Navigate to="/onboarding/dashboard" replace />}
        />
      </Routes>
    </>
  );
};

export default AppRoutes;
