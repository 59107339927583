import { useFormik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import * as Yup from 'yup';
import BlackBtn from '../../components/universal/Buttons/BlackBtn';
import ExclusiveBlogs from '../../components/universal/ExclusiveBlogs';
import InputType from '../../components/universal/InputType';
import { baseUrl, notify } from '../../core/constants';
const ResetPassword = () => {
    const param = useParams()
    const [loading, setloading] = React.useState(false);
    const [isPasswordShow, setIsPasswordShow] = React.useState(true);
    const [isConfirmShow, setIsConfirmShow] = React.useState(true);

    // formik start
    const initialValues = {
        password: '',
        confirmPassword: '',
    };
    const validationSchema = Yup.object({
        password: Yup.string().required('Password is required.').matches(
            /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
            "Use 8 or more characters with a mix of letters, numbers & symbols"
        ),
        confirmPassword: Yup.string().when('password', {
            is: (val) => (val && val.length > 0 ? true : false),
            then: Yup.string()
                .required('Confirm password is required.')
                .oneOf(
                    [Yup.ref('password')],
                    'Both password are not match.'
                ),
        }),
    });
    const onSubmit = (values) => {
        setloading(true)
        var axios = require('axios');
        var data = JSON.stringify({
            token: param.token,
            password: values.password,
            url: window.location.origin.toString()
        });

        var config = {
            method: 'post',
            url: `${baseUrl}user/reset-password`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data,
        };

        axios(config)
            .then(function (response) {
                setloading(false)
                if (response.data.statusCode == '102') {
                    notify(response.data.message, "error")
                }
                else {
                    notify(response.data.message, "success")
                    window.open(`${window.location.origin.toString()}`, '_Self')
                }
            })
            .catch(function (error) {
                console.log(error);
                setloading(false)
            });
    };


    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit,
    });
    return (
        <><div className='resetpassword'>
            <div className="login-full-container">
                <div className="main-heading">Reset Password</div>
                <div className="login-para">
                    To reset your password, please enter the new password.
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div>
                        <InputType
                            password
                            name="password"
                            id="password"
                            type={isPasswordShow ? "password" : "text"}
                            placeholder="Password"
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={formik.errors.password}
                            isPasswordShow={isPasswordShow}
                            setIsPasswordShow={setIsPasswordShow}
                        />
                    </div>
                    <div>
                        <InputType
                            password
                            name="confirmPassword"
                            id="confirmPassword"
                            type={isConfirmShow ? "password" : "text"}
                            placeholder="Confirm Password"
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmPassword}
                            onChange={formik.handleChange}
                            error={formik.errors.confirmPassword}
                            isPasswordShow={isConfirmShow}
                            setIsPasswordShow={setIsConfirmShow}
                        />
                    </div>
                    <div>
                        <BlackBtn title={loading ? 'Loading...' : "Submit"} type='submit' />
                    </div>
                </form>
            </div>
            <ExclusiveBlogs />
        </div>

        </>
    )
}

export default ResetPassword