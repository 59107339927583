import { Button } from "@mui/material";
import React from "react";

const DisabledBtn = ({ title }) => {
  return (
    <Button variant="contained" className="disabledBtn">
      {" "}
      {title ? title : "Continue"}
    </Button>
  );
};

export default DisabledBtn;
