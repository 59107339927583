import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import * as React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { notify } from "../../../core/constants";
import DarkBtn from "../../universal/Buttons/DarkBtn";
import LightBtn from "../../universal/Buttons/LightBtn";
import SubHeading from "../../universal/Headings/SubHeading";
import InputTypeOutline from "../../universal/InputType/inputTypeOutline";

export default function WelcomeAdminDialog({
  handleChange,
  accountData,
  onclick,
  loading,
  setLoading,
  updateDisabled,
  bankType,
  bankAccountModal,
  setBankAccountModal,
}) {
  const handleClickOpen = () => {
    setBankAccountModal(true);
  };

  const handleClose = () => {
    setBankAccountModal(false);
  };

  // formik start

  const initialValues = {
    fullName: accountData?.fullName,
    accountNumber: accountData?.accountNumber,
    bsb: accountData?.bsb,
  };

  const validationSchema = Yup.object({
    fullName: Yup.string().required("Full Name is required."),
    accountNumber: Yup.string().required(`${bankType?.accountType} is required.`),
    bsb: bankType?.accountSubType !== "" && Yup.string().required(`${bankType?.accountSubType} is required.`),
  });

  const onSubmit = (values) => {
    onSubmitAccount(values);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const onSubmitAccount = (values) => {
    const trimmedName = values?.fullName?.trim();
    if (trimmedName.indexOf(" ") == -1) {
      notify("Please enter a valid full name", "error");
    } else if (
      values?.fullName === accountData?.fullName &&
      values?.accountNumber === accountData?.accountNumber &&
      bankType?.accountSubType !== "" &&
      values?.bsb === accountData?.bsb
    ) {
      notify("Please update your bank details", "error");
    } else if (
      values?.fullName === accountData?.fullName &&
      values?.accountNumber === accountData?.accountNumber &&
      bankType?.accountSubType === ""
    ) {
      notify("Please update your bank details", "error");
    } else {
      onclick(values, bankType);
    }
  };

  React.useEffect(() => {
    if (accountData) {
      formik.setFieldValue("fullName", accountData?.fullName);
      formik.setFieldValue("accountNumber", accountData?.accountNumber);
      formik.setFieldValue("bsb", accountData?.bsb);
    }
  }, [accountData]);

  return (
    <div>
      {/* <LightBtn onClick={handleClickOpen}/> */}
      {!updateDisabled && (
        <Button disabled={updateDisabled} variant="contained" className="lightbtn" onClick={handleClickOpen}>
          Update
        </Button>
      )}
      <Dialog
        open={bankAccountModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="updatebankdetails"
      >
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <SubHeading title="Update Bank Details" />
            <div className="tracking-text "> Account holder name</div>

            <InputTypeOutline
              type={"text"}
              name={"fullName"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fullName}
              error={formik.touched.fullName && formik.errors.fullName}
            />

            <div className="tracking-text ">{bankType?.accountType}</div>
            <InputTypeOutline
              type={"text"}
              name={"accountNumber"}
              onChange={formik.handleChange}
              value={formik.values.accountNumber}
              onBlur={formik.handleBlur}
              error={formik.touched.accountNumber && formik.errors.accountNumber}
            />
            {bankType?.accountSubType && (
              <>
                <div className="tracking-text ">{bankType?.accountSubType}</div>
                <InputTypeOutline
                  type={"text"}
                  name={"bsb"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bsb}
                  error={formik.touched.bsb && formik.errors.bsb}
                />
              </>
            )}
            <div className="btn-section">
              <LightBtn title="Cancel" onClick={handleClose} />
              <DarkBtn title="Submit" type="submit" disabled={loading ? true : false} loading={loading} />
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
