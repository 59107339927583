import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import LockIcon from '@mui/icons-material/Lock';
import { Container, Grid } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { images } from '../../../assets';
import WelcomeAdminDialog from '../../../components/dialogs/WelcomeAdminDashboard';
import DashboardSkeleton from '../../../components/Skeleton/DashboardSkeleton';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';
import DashboardCharts from '../../../components/universal/DashboardChart_v2';
import { Empty } from '../../../components/universal/Empty';
import MainHeading from '../../../components/universal/Headings/MainHeading';
import { baseUrlV1 } from '../../../core/constants';
import { deleteLogin } from '../../../redux/actions/login';
import { addUser, deleteUser } from '../../../redux/actions/user';
import { axiosAll } from '../../../services/axiosAll';

const Dashboard = () => {
  const { userData } = useSelector((state) => state.guestUserReducer);
  const { guestDetails } = useSelector((state) => state.guestReducer);
  const { userDetails } = useSelector((state) => state.loginReducer);
  const dispatch = useDispatch();
  const [userinfo, setuserinfo] = useState({});
  const [deshboardDetail, setDeshboardDetail] = useState();
  const [chart, setChart] = useState();
  const categories = chart?.grossSale?.map((e) => e.key);
  const data1 = chart?.grossSale?.map((e) => e.value);
  const name2 = deshboardDetail?.productSeries?.map((e) => e.name);
  const data2 = deshboardDetail?.productSeries?.map((e) => e.data);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [loadinginfo, setloadinginfo] = useState(true);
  const [loadingChart, setloadingChart] = useState(true);
  const query = window.location.search;
  const urlParam = new URLSearchParams(query);
  const first = urlParam.get('first');
  const nav = useNavigate();
  const location = useLocation();

  //combine name2 and data2 for chart
  const barChartData = name2?.map((e, i) => {
    return {
      name: e,
      data: data2[i],
    };
  });
  

  const email = urlParam.get('email');

  useEffect(() => {
    if (userDetails?.token && email && email !== userData?.user?.email) {
      dispatch(deleteLogin());
      dispatch(deleteUser());
      nav('/login');
    }
    // eslint-disable-next-line
  }, []);

  const userDetailsCall = async () => {
    try {
      const response = await axios(
        `${baseUrlV1}user-profile/${userData?.user?.id}?deviceType=3&deviceToken=${guestDetails?.token}`,
        {
          headers: {
            userToken: userData?.userToken,
          },
        }
      );
      if (response?.data?.result?.user?.isVerified) {
        // notify(response.data.message, 'success');
        // let user = response?.data?.result
        setuserinfo(response?.data?.result);
        if (response?.data?.result?.user?.id) {
          getDeshboard(response?.data?.result?.user?.id);
          getChart(response?.data?.result?.user?.id);
        }
        dispatch(addUser({ user: response?.data?.result }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDeshboard = async (id) => {
    const responce = await axiosAll({
      method: 'get',
      url: `v2/user/dashboard/details/${id}`,
    });

    setDeshboardDetail(responce.result);
    setloadinginfo(false);
  };

  const getChart = async (id) => {
    const responce = await axiosAll({
      method: 'get',
      url: `v2/get/sold-products-by-month/${id}`,
    });
    setChart(responce.result);
    setloadingChart(false);
  };

  useEffect(() => {
    userDetailsCall();
  }, [location.pathname]);

  useEffect(() => {
    if (first == 1) setDialogOpen(true);
  }, [window.location.search]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="seller-dashboard">
      <WelcomeAdminDialog dialogOpen={dialogOpen} />
      <Container className="all-container-27inch">
        {loadinginfo ? (
          <DashboardSkeleton type={1} />
        ) : (
          <>
            <div className="heading-row">
              {userinfo?.user?.userName ? (
                <MainHeading title={`${userinfo?.user?.userName} Dashboard`} />
              ) : null}
              <div className="usericon">
                <img
                  src={
                    userinfo?.sellerStoreFront?.logo
                      ? userinfo?.sellerStoreFront?.logo
                      : images?.defaultUser
                  }
                  className="store-img"
                  alt="store"
                />
              </div>
            </div>
            <div className="complete-section">
              <div className="store-heading">Let's complete your store</div>
              <div className="store-row">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="box-title">Add bank account</div>
                      {deshboardDetail?.user?.isBankAccountVerified ? (
                        <img
                          src={images.completeIcon}
                          className="completed-icon"
                          alt="complete"
                        />
                      ) : (
                        // <Link to={"/onboarding/my-profile"} state={{ tab: 1 }}>
                        //   {" "}
                        <DarkBtn
                          onClick={() =>
                            nav('/onboarding/my-profile', { state: { tab: 1 } })
                          }
                          title="Complete"
                        />
                        // </Link>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="box-title">Add store information</div>
                      {deshboardDetail?.user?.storeInfoStatus ? (
                        <img
                          src={images.completeIcon}
                          className="completed-icon"
                          alt="complete"
                        />
                      ) : (
                        // <Link to={"/onboarding/my-profile"} state={{ tab: 0 }}>
                        //   {" "}
                        <DarkBtn
                          onClick={() =>
                            nav('/onboarding/my-profile', { state: { tab: 0 } })
                          }
                          title="Complete"
                        />
                        // </Link>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="box-title">List your first Item</div>
                      {deshboardDetail?.user?.listFirstItemStatus ? (
                        <img
                          src={images.completeIcon}
                          className="completed-icon"
                          alt="complete"
                        />
                      ) : (
                        // <Link to={"/onboarding/add-single-product"}>
                        //   {" "}
                        <DarkBtn
                          onClick={() => nav('/onboarding/add-single-product')}
                          title="Complete"
                        />
                        // </Link>
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="box-title">Link your e-commerce</div>
                      {deshboardDetail?.user?.linkStatus ? (
                        <DarkBtn title="Complete" />
                      ) : (
                        <DarkBtn title="Coming Soon" />
                      )}
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="complete-section">
              <div className="store-heading">Summary</div>
              <div className="store-row">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="store-heading price">
                        {deshboardDetail?.user?.totalCustomer}
                      </div>
                      <div className="box-title">Total Customers</div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="store-heading price">
                        {' '}
                        {deshboardDetail?.orderProcessing}
                      </div>
                      <div className="box-title">Processing Orders</div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="store-heading price">
                        {deshboardDetail?.noOfOrders}
                      </div>
                      <div className="box-title">Total orders</div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="store-heading price">
                        £ {deshboardDetail?.orderItemPriceSum?.toFixed(2)}
                      </div>
                      <div className="box-title">Total revenue</div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
          </>
        )}

        {loadingChart ? (
          <DashboardSkeleton type={2} />
        ) : (
          <div className="graph-section">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} className="firstgraph-color">
                <div className="grid-box-n graph-n">
                  <div className="text-view">
                    <div className="store-heading">Sales per month</div>
                    <Link to="/onboarding/financial-reports">
                      {' '}
                      <div className="box-title textunderline">View all</div>
                    </Link>
                  </div>
                  {deshboardDetail?.noOfOrders ? null : (
                    <Empty message="Product sales will show here" />
                  )}
                  <DashboardCharts
                    name="Sales per month"
                    data={data1}
                    categories={categories}
                    type={'line'}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} >
                <div className={deshboardDetail?.noOfOrders?'grid-box-n graph-n':'grid-box-n graph-bar'}>
                  <div className="text-view">
                    <div className="store-heading">
                      Order quantity per month
                    </div>
                    <Link to="/onboarding/orders">
                      <div className="box-title textunderline">View all</div>
                    </Link>
                  </div>
                  {deshboardDetail?.noOfOrders ? 
                  <DashboardCharts
                    barChartData={barChartData}
                    categories={categories}
                    type={'bar'}
                  />
                  : (
                    <Empty
                      type={2}
                      message="Quantity of orders will show here"
                    />
                  )}
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className="grid-box-n">
                  <div className="store-heading marginbott">Listings</div>
                  <div className="text-view-repat">
                    <div className="text-view">
                      <LockIcon />
                      <div className="box-title">Active listings</div>
                    </div>
                    <div className="store-heading sidepadding">
                      {deshboardDetail?.inStockProducts}
                    </div>
                  </div>
                  <div className="text-view-repat outofstock-text">
                    <div className="text-view">
                      <HourglassEmptyIcon />
                      <div className="box-title">Out of stock</div>
                    </div>
                    <div className="store-heading sidepadding">
                      {deshboardDetail?.outOfStockProducts}
                    </div>
                  </div>
                </div>
              </Grid>
              {deshboardDetail?.user?.isBankAccountVerified == 0 ? (
                <Grid item xs={12} sm={6}>
                  <div className="grid-box-bank">
                    <Grid container spacing={2}>
                      <Grid item xs={8}>
                        <div className="bank-heading">
                          Add your bank account
                        </div>
                        <div className="bankpara">
                          {' '}
                          Let's add your bank account so you can get paid for
                          your sales.{' '}
                        </div>
                        {/* <Link to={"/onboarding/my-profile"} state={{ tab: 1 }}>
                        {" "} */}
                        <DarkBtn
                          onClick={() =>
                            nav('/onboarding/my-profile', { state: { tab: 1 } })
                          }
                          title="View details"
                        />{' '}
                        {/* </Link> */}
                      </Grid>
                      <Grid item xs={4}>
                        <img
                          src={images.dashboardSide}
                          className="bankdetailsimg"
                          alt="bank details"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </div>
        )}
      </Container>
    </div>
  );
};

export default Dashboard;
