import { Container } from '@mui/material';
import React from 'react';
import Dashboard from './Dashboard';

const Seller = () => {
  return (
    <div className="sellerdashboard">
      
        <Dashboard />
      
    </div>
  );
};

export default Seller;
