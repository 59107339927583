import { Container } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import ProductSkeleton from '../../../components/Skeleton/ProductSkeleton';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import CardPagination from '../../../components/universal/CardPagination';
import DropDown from '../../../components/universal/DropDown';
import { Empty } from '../../../components/universal/Empty';
import MainHeading from '../../../components/universal/Headings/MainHeading';
import ProductsCard from '../../../components/universal/ProductsCard';
import SearchBar from '../../../components/universal/SearchBar';
import sweatAlertBoxEdit from '../../../components/universal/SweetAlertBoxEdit';
import { notify } from '../../../core/constants';
import { store } from '../../../redux/store';
import { axiosAll } from '../../../services/axiosAll';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [filterTag, setFilterTag] = useState([]);
  const [categoriesOption, setCategory] = useState();
  const [tag, setTag] = useState('');
  const [searchProduct, setSearch] = useState(null);
  const navigate = useNavigate();
  const itemsPerPage = 10;
  const { loginReducer } = store.getState();
  const userId = loginReducer?.userDetails?.user?.id;
  const [Loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.userReducer);
  const [page, setPage] = React.useState(1);

  const defaultOption = {
    title: 'All Categories',
    value: '',
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getProducts = async (tag, searchProduct) => {
    setLoading(true);
    const params = {
      startprice: '',
      endprice: '',
      sortbyprice: '',
      location: '',
      tags: tag,
      search: searchProduct ? searchProduct : '',
      page: page,
      per_page_limit: 10,
    };
    const response = await axiosAll({
      method: 'get',
      url: `v1/product/user-products/${userId}`,
      params,
    });
    if (response?.statusCode === 200) {
      setProducts(response?.result);
    }
    setLoading(false);
  };

  const getFiltertag = async () => {
    const response = await axiosAll({
      method: 'get',
      url: 'v1/tag',
    });
    setFilterTag(response?.result.data);
  };

  const handleDelete = async (id) => {
    const response = await axiosAll({
      method: 'delete',
      url: `v2/product/${id}`,
    });
    // notify(response.data.message, "success");
    if (response?.statusCode === 200) {
      if (response?.statusCode === 200) {
        getProducts(tag, searchProduct);
        notify(response.message, 'success');
      } else {
        notify(response.message, 'error');
      }
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setTag(value);
  };

  const handlePagination = (event, value) => {
    setPage(value);
  };

  const handleSearch = (e) => {
    if (page > 1) {
      setPage(1);
    }
    const { value } = e.target;
    setSearch(value);
  };

  useEffect(() => {
    getFiltertag();
  }, []);

  useEffect(() => {
    getProducts(tag, searchProduct);
    // eslint-disable-next-line
  }, [tag, searchProduct, page]);

  var filterdata = [];

  const getCategories = () => {
    var EthicalCategory = filterTag?.filter((x) => x.name);

    for (let i = 0; i <= EthicalCategory?.length - 1; i++) {
      var MainCategory = EthicalCategory[i];
      const category1 = {
        title: MainCategory?.name,
        id: MainCategory?.id,
      };

      filterdata.push(category1);

      for (let j = 0; j <= MainCategory?.children?.length - 1; j++) {
        var subCategory = MainCategory?.children[j];
        const category2 = {
          title: '--' + subCategory?.name,
          id: subCategory?.id,
        };

        filterdata.push(category2);

        for (let k = 0; k <= subCategory?.children?.length - 1; k++) {
          var subChild = subCategory?.children[k];
          const category3 = {
            title: '---' + subChild?.name,
            id: subChild?.id,
          };
          filterdata.push(category3);
        }
      }
    }
    setCategory(filterdata);
    filterdata.unshift(defaultOption);
  };
  useEffect(() => {
    getCategories();
    // eslint-disable-next-line
  }, [filterTag]);

  const handleEdit = (title, id) => {
    const newTitle = title?.replaceAll(/\s/g, '-').toLowerCase();

    navigate(`/onboarding/seller/edit-product/${newTitle}/${id}`);
  };

 

  return (
    <div className="products">
      <div className="product-container">
        <Container className="all-container-27inch">
          <MainHeading title="Products " />
          {/* { Loading ? (
            <ProductSkeleton type={2} />
          ) : ( */}
          <div className="search-row">
            <div className="btn-part">
              <SearchBar handleChange={handleSearch} value={searchProduct} />
              <div className="drop-down">
                <DropDown
                  option={categoriesOption}
                  handlechan={handleChange}
                  label={'All Category'}
                  value={tag}
                />
              </div>
            </div>
            <div className="bulk-btn-section">
              {user?.user?.user?.isProSeller ? (
                <LightBtn
                  title="Bulk upload "
                  onClick={() =>
                    user?.user?.user?.isBankAccountAdded != 1
                      ? notify(
                          'Please add your bank account details from My Profile. for adding a product.',
                          'error'
                        )
                      : navigate(`/onboarding/bulk-upload`)
                  }
                />
              ) : null}{' '}
              <DarkBtn
                title="Add single product"
                onClick={() =>
                  user?.user?.user?.isBankAccountAdded != 1
                    ? notify(
                        'Please add your bank account details from My Profile. for adding a product.',
                        'error'
                      )
                    : navigate('/onboarding/add-single-product')
                }
              />
              {/* <UploadImage /> */}
            </div>
          </div>
          {/* )} */}
          {Loading ? (
            <ProductSkeleton type={1} />
          ) : products?.data?.length > 0 ? (
            products?.data?.map((product, index) => {
              return (
                <Fragment key={index}>
                  <div className="img-container">
                    {product?.isPublished === '0' ? (
                      <div className="unpublished"> Unpublished </div>
                    ) : product?.stockStatus === 0 ? (
                      <div className="outofstock"> out of stock </div>
                    ) : (
                      ''
                    )}
                    <ProductsCard
                      name={product?.name}
                      actual_price={product?.actualPrice}
                      price={product?.price}
                      image={
                        product?.images?.find((imag) => imag?.isCover === 1)
                          ?.image
                      }
                      handleDelete={handleDelete}
                      id={product?.id}
                      isAddedToCart={product?.isAddedToCart}
                      isAddedToWishlist={product?.isAddedToWishlist}
                      isPublished={product?.isPublished}
                      stockStatus={product?.stockStatus}
                      handleEdit={() => {
                        if (
                          product?.deviceType === 3 ||
                          product?.deviceType === null
                        ) {
                          handleEdit(product?.productSlug, product?.id);
                        } else {
                          sweatAlertBoxEdit(
                            'Are You Sure?',
                            `You are about to edit the product titled ${product?.name?.bold()}. Once you confirm, you will no longer be able to make changes to this product using the GoEthical mobile app. All future changes will have to be made using the seller dashboard.`,
                            'Confirm',
                            'Cancel',
              
                            handleEdit,
                            product?.productSlug, product?.id
                          )
                        }
                      }}
                      isProducts
                    />
                  </div>
                </Fragment>
              );
            })
          ) : (
            <Empty message="No Product added " />
          )}

          {products?.total >= 10 ? (
            <CardPagination
              noOfPages={products?.lastPage}
              page={page}
              handleChange={handlePagination}
              rowsPerPage={itemsPerPage}
            />
          ) : (
            ''
          )}
        </Container>{' '}
      </div>
    </div>
  );
};

export default Products;
