import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { images } from '../../assets';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import InputTypeOutline from '../../components/universal/InputType/inputTypeOutline';
import TextArea from '../../components/universal/InputType/TextArea';
import ProgressBar from '../../components/universal/ProgressBar/';
import { baseUrlV1, notify } from '../../core/constants';
import { store } from '../../redux/store';

const StoreDetails = () => {
  const nav = useNavigate();
  const state = store.getState();
  const [sellerData, setSellerData] = useState({});
  const [logoImage, setLogoImage] = useState('');
  const [bannerImage, setBannerImage] = useState('');
  const { guestDetails } = state.guestReducer;
  const [userDetails, setUserDetails] = useState('');
  const [disableButton, setDisableButton] = useState(true);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { token } = useParams();

  const regex = new RegExp(
    '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
  );
  const getUserData = async () => {
    try {
      const res = await axios({
        method: 'get',
        url: `${baseUrlV1}user/${id}?deviceType=3&deviceToken=${guestDetails?.token}`,
        headers: { 'Content-Type': 'application/json', userToken: `${token}` },
      });
      const user = res?.data?.result?.user;
      setSellerData(res?.data?.result);
      setUserDetails(user);
      user?.userName&&user?.userName?.length>0&& formik.setFieldValue('storeName', user?.userName);
      user?.bio&&user?.bio?.length>0&&  formik.setFieldValue('about', user?.bio);
      formik.setFieldValue('logo', res?.data?.result?.sellerStoreFront?.logo);
      formik.setFieldValue(
        'banner',
        res?.data?.result?.sellerStoreFront?.bannerImage
      );
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUserData();
  }, []);

  // formik start
  const initialValues = {
    logo: '',
    banner: '',
    storeName: '',
    about: '',
    videoUrl: '',
  };
  const onSubmit = (values) => {
    if (values?.videoUrl) {
      regex?.test(values?.videoUrl)
        ? submitMatadataUrl(values?.videoUrl, values)
        : notify(`Please enter a valid url`, 'error');
    } else {
      submitStoreDetails(values);
    }
  };
  const formik = useFormik({
    initialValues,
    onSubmit,
  });

  const submitLogo = async (logo) => {
    const formData = new FormData();
    formData.append('image', logo);
    formData.append('type', 1);
    setLoading(true);
    try {
      const res = await axios({
        method: 'post',
        url: `${baseUrlV1}image?deviceType=3&deviceToken=${guestDetails?.token}`,
        data: formData,
        headers: { 'Content-Type': 'application/json', userToken: `${token}` },
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const submitBanner = async (banner) => {
    const formData = new FormData();
    formData.append('file', banner);
    formData.append('value', banner);
    formData.append('fileType', '2');
    formData.append('folderName', 'profile_image/');

    try {
      const res = await axios({
        method: 'post',
        url: `${baseUrlV1}metadata/upload-image-video?deviceType=3&deviceToken=${guestDetails?.token}`,
        data: formData,
        headers: { 'Content-Type': 'application/json', userToken: `${token}` },
      });
      submitMatadata(res?.data?.result?.fileName);
    } catch (error) {
      console.log(error);
    }
  };

  const submitMatadata = async (name) => {
    const data = {
      metadata: [
        {
          type: 6,
          id: userDetails?.metadata[0]?.id ? userDetails?.metadata[0]?.id : 0,
          content: name,
          category: 2,
        },
      ],
      deviceToken: 'qwertyuiopasdfghjklzxcvbnm1234567890',
      deviceType: 3,
      referenceId: id,
    };

    try {
      const res = await axios({
        method: 'post',
        url: `${baseUrlV1}metadata?deviceType=3&deviceToken=${guestDetails?.token}`,
        data: data,
        headers: { 'Content-Type': 'application/json', userToken: `${token}` },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const submitMatadataUrl = async (name, values) => {
    const data = {
      metadata: [
        {
          type: 1,
          content: name,
          category: 2,
        },
      ],
      referenceId: id,
    };

    try {
      const res = await axios({
        method: 'post',
        url: `${baseUrlV1}metadata?deviceType=3&deviceToken=${guestDetails?.token}`,
        data: data,
        headers: { 'Content-Type': 'application/json', userToken: `${token}` },
      });
      submitStoreDetails(values);
    } catch (error) {
      console.log(error);
    }
  };
  const submitStoreDetails = async (values) => {
    const data = {
      address: [
        {
          address: userDetails?.address[0]?.address,
          latitude: userDetails?.address[0]?.latitude,
          longitude: userDetails?.address[0]?.longitude,
        },
      ],
      bio: values?.about,
      name: userDetails?.name,
      phoneNumber: null,
      proSeller: userDetails?.isProSeller,
      userName: values?.storeName,
    };
    const data1 = {
      address: [
        {
          address: userDetails?.address[0]?.address,
          latitude: userDetails?.address[0]?.latitude,
          longitude: userDetails?.address[0]?.longitude,
        },
      ],
      bio: values?.about,
      name: userDetails?.name,
      phoneNumber: null,
      proSeller: userDetails?.isProSeller,
      userName: values?.storeName,
      businessName: userDetails?.sellerName,
      businessAddress: userDetails?.address[0]?.address,
      businessEmail: userDetails?.email,
    };
    try {
      const res = await axios({
        method: 'post',
        url: `${baseUrlV1}user?deviceType=3&deviceToken=${guestDetails?.token}`,
        data: userDetails?.isProSeller ? data1 : data,
        headers: { 'Content-Type': 'application/json', userToken: `${token}` },
      });
      if (res.data.status == 'Error') {
        notify(res.data.message, 'error');
      } else {
        notify(res.data.message, 'success');
        nav(`/policy-details/${id}/${token}`);
      }
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    if (
      formik?.values?.logo &&
      formik?.values?.banner &&
      formik?.values?.storeName &&
      formik?.values?.about
    ) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  }, [ ]);

  // check if image has 2446px x 580px
  const checkImageSize = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => {
        if (img.width === 2446 && img.height === 580) {
          formik.setFieldValue('banner', file);
          submitBanner(file)
          setBannerImage(file);
        } else {
          notify('Image size should be 2446px x 580px', 'error');
        }
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
    getUserData();
  };

  return (
    <>
      <ProgressBar value={60} />

      <div className="storedetails">
        <form onSubmit={formik.handleSubmit}>
          <div className="text-heading">Let’s set up your store</div>
          <div className="text-para">
            This information will display to customers{' '}
          </div>
          <div>
            <p className="logo-text marleft0">Store Logo (135px x 135px)</p>

            <div className="file-section">
              <label for="files" className="drag-label">
                <div className="store-img-row">
                  <div className="upload-text">
                    <input
                      id="files"
                      className="input-type-file-upload"
                      type="file"
                      name="logo"
                      accept="image/png,image/jpeg,image/jpg"
                      onChange={(event) => {
                        if (event.target.files.length > 0) {
                          submitLogo( event.currentTarget.files[0])
                          formik.setFieldValue(
                            'logo',
                            event.currentTarget.files[0]
                          );
                          setLogoImage(event.currentTarget.files[0]);
                        }
                      }}
                      onBlur={formik.handleBlur}
                      onClick={(e) => {
                        e.currentTarget.value = null;
                        formik.setFieldValue('logo', '');
                      }}
                    />

                    {logoImage || sellerData?.sellerStoreFront?.logo ? (
                      <img
                        src={
                          logoImage
                            ? URL.createObjectURL(logoImage)
                            : sellerData?.sellerStoreFront?.logo
                        }
                        className="img"
                      />
                    ) : (
                      <>
                        {' '}
                        <div>
                          <img src={images.upload} className="upload-img" />
                        </div>
                        Upload
                      </>
                    )}
                  </div>
                </div>
              </label>
            </div>
          </div>

          <div>
            <p className="logo-text marleft0">Store banner (2446px x 580px)</p>
            <div className="drop-down">
              <div className="file-label">
                <label for="bannerfiles">
                  <input
                    id="bannerfiles"
                    className="input-type-file-upload"
                    type="file"
                    name="banner"
                    accept="image/png,image/jpeg,image/jpg"
                    onChange={(event) => checkImageSize(event)}
                    onBlur={formik.handleBlur}
                    onClick={(e) => {
                      e.currentTarget.value = null;
                      formik.setFieldValue('banner', '');
                    }}
                  />
                  {bannerImage || sellerData?.sellerStoreFront?.bannerImage ? (
                    <img
                      src={
                        bannerImage
                          ? URL.createObjectURL(bannerImage)
                          : sellerData?.sellerStoreFront?.bannerImage
                      }
                    />
                  ) : (
                    <>
                      <img src={images.upload} className="upload-img" />
                      <p className="select-image-text">
                        Drag and drop banner image or
                        <u> click here</u> to select files{' '}
                      </p>
                    </>
                  )}
                </label>
              </div>
            </div>
          </div>
          <div className="mrgbottom">
            <div className="label-text">Store name</div>
            <InputTypeOutline
              name="storeName"
              id="storeName"
              type="text"
              placeholder="Store Name"
              onBlur={formik.handleBlur}
              value={formik.values.storeName}
              onChange={formik.handleChange}
              error={formik.errors.storeName}
            />
          </div>
          <div className="mrgbottom">
            <div className="label-text">About store</div>
            <TextArea
              name="about"
              id="about"
              type="text"
              onBlur={formik.handleBlur}
              value={formik.values.about}
              onChange={formik.handleChange}
              error={formik.errors.about}
            />
          </div>
          <div className="">
            <div className="label-text">Video url (optional)</div>
            <InputTypeOutline
              name="videoUrl"
              id="videoUrl"
              type="url"
              onBlur={formik.handleBlur}
              value={formik.values.videoUrl}
              onChange={formik.handleChange}
              error={formik.errors.videoUrl}
            />
          </div>
          <div className="btn-section">
            <div>
              <LightBtn
                title="Back"
                onClick={() => nav(`/verify-email/${id}/${token}`)}
              />
            </div>
            <div>
              <DarkBtn
                loading={loading}
                title=" Continue "
                disable={formik?.values?.storeName&& formik?.values?.about&&(logoImage|| formik?.values?.logo)&&(bannerImage|| formik?.values?.banner)?false:true}
                type={'submit'}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default StoreDetails;
