import { Button } from '@mui/material';

const DarkBtn = ({
  title,
  onClick,
  type,
  disable,
  props,
  loading,
  disabled,
}) => {
  return disable ? (
    <>
      <Button variant="contained" className="disabledBtn" disabled={true}>
        {loading ? 'Loading...' : 'Continue'}{' '}
      </Button>
    </>
  ) : (
    <Button
      onClick={onClick}
      type={type}
      variant="contained"
      className="darkbtn"
      {...props}
      disabled={disabled}
    >
      {loading ? 'Loading...' : title}
    </Button>
  );
};

export default DarkBtn;
