import { useSelector } from 'react-redux';
import { useNavigate } from "react-router";
import BlackBtn from "../../components/universal/Buttons/BlackBtn";

const StripVerification = () => {
  const nav= useNavigate()
  const { userDetails } = useSelector((state) => state.loginReducer);
  return (
   <div className="thanksforregistering">
      <div className="thanks-para">
          <p className="para1"> Thank you for completing your Stripe verification. 
</p>
          <p className="para2">Stripe may take some time to verify your documents. Your profile will update automatically when this process has been completed. </p>
      <div>
      <BlackBtn title="Go to My Account" onClick={()=>{nav(userDetails?.token?userDetails?.user?.userType == 1?"/admin/seller":'/onboarding/my-profile':"/login")}} />
      </div>
    </div>
   </div>
  );
};

export default StripVerification;
