import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import SubHeading from '../../universal/Headings/SubHeading';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';
import ProductsCard from '../../universal/ProductsCard';
import { axiosAll } from '../../../services/axiosAll';
import { useEffect, useState } from 'react';
import UploadImage from '../UploadImage';
import { DialogActions, Tooltip } from '@mui/material';
import CardPagination from '../../universal/CardPagination';
import { images } from '../../../assets';

export default function WelcomeAdminDialog({ getSelectedImage }) {
  const [copyText, setCopyText] = useState('Copy image link to clipboard');
  const [open, setOpen] = useState(false);
  const [gallery, setGallery] = useState({});
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState('');
  const [uploadImage, setUploadImage] = useState([]);
  const [imageSize, setImageSize] = useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage('');
  };

  const getGallery = async () => {
    const response = await axiosAll({
      url: 'v2/galleries',
      method: 'get',
      params: {
        page,
        search,
        limit: 20,
      },
    });
    setGallery(response.result);
  };

  const UploadMultiImage = async () => {
    for (let index = 0; index < uploadImage.length; index++) {
      const element = uploadImage[index];
      const formData = new FormData();
      formData.append('image', element);
      formData.append(
        'name',
        element?.editName ? element?.editName : element?.name
      );

      const response = await axiosAll({
        method: 'post',
        url: `v2/galleries`,
        data: formData,
      });
      if (response?.statusCode === 200) {
        setGallery(response?.result);
      }
    }
    getGallery();
    setUploadImage([]);
  };

  useEffect(() => {
    var result = uploadImage.reduce(function (sum, item) {
      return (sum = sum + item.size);
    }, 0);
    setImageSize(result);
  }, [uploadImage]);

  useEffect(() => {
    getGallery();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, page]);

  return (
    // <div>
    <>
      {' '}
      <u onClick={handleClickOpen} className="cursorpointer">
        click here{' '}
      </u>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="medialibrary"
      >
        <DialogContent>
          <SubHeading title="Select from media library" />
          <div className="btn-section">
            <InputTypeOutline
              placeholder="Search image"
              name="imageSearch"
              type="text"
              onChange={(e) => {
                setPage(1);
                setSearch(e.target.value);
              }}
            />
            <UploadImage
              uploadImage={uploadImage}
              setUploadImage={setUploadImage}
              UploadMultiImage={UploadMultiImage}
              imageSize={imageSize}
            />
          </div>
          {gallery?.data?.map((item, index) => {
            const imageFormat = item?.url?.split('.')?.pop();
            return (
              <div className="img-container">
                <div key={index}>
                  <input
                    type="radio"
                    name="check"
                    id={`myCheckbox${index}`}
                    value={item.id}
                    onChange={(e) => setSelectedImage(e.target.value)}
                  />
                  <label className="label-file" htmlFor={`myCheckbox${index}`}>
                    <ProductsCard
                      // name={`${item?.name} (${imageFormat})`}
                      image={item?.url}
                    />
                  </label>
                </div>
                <div className="product-text-section">
                  <div className="product-text">{item?.name}</div>
                  {/* {type === 'add_product' ? null : ( */}
                  <Tooltip
                    title={copyText}
                    placement="right"
                    onClick={() => {
                      // eslint-disable-next-line no-undef
                      navigator.clipboard.writeText(item?.url);
                      setCopyText('Copied to clipboard');
                      setTimeout(() => {
                        setCopyText('Copy image link to clipboard');
                      }, 2000);
                    }}
                  >
                    <img
                      src={images.copy}
                      className="copy-icon_new"
                      alt="copy"
                    />
                  </Tooltip>
                  {/* )}  */}
                </div>
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <CardPagination
            page={page}
            noOfPages={gallery?.lastPage}
            handleChange={(e, value) => setPage(value)}
            rowsPerPage={gallery?.perPage}
          />
          <div className="lastbtn">
            <LightBtn title="Cancel" onClick={handleClose} />
            <DarkBtn
              title="Add"
              onClick={() => {
                if (selectedImage) {
                  const data = gallery?.data?.find((item) => {
                    if (item.id === parseInt(selectedImage)) {
                      return item;
                    }
                  });
                  getSelectedImage(data);
                  handleClose();
                  setSelectedImage('');
                }
              }}
            />
          </div>
        </DialogActions>
      </Dialog>
    </>
    // </div>
  );
}
