import React from 'react';
import DeleteIcon from '@mui/icons-material/Delete';

const DeleteIcons = () => {
  return (
    <div>
         <DeleteIcon  className='deleteicon'/>
    </div>
  )
}

export default DeleteIcons