
const TextArea = ({ placeholder, name, value, onBlur, onChange, error, props }) => {
  return (
    <>
      <textarea
        className="input-textarea"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        {...props}
      />
      {error && <div className="input-error-message">{error}</div>}
    </>
  );
};

export default TextArea;
