import axios from 'axios';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../components/universal/Buttons/LightBtn';
import TextArea from '../../components/universal/InputType/TextArea';
import ProgressBar from '../../components/universal/ProgressBar';
import { baseUrlV1, notify } from "../../core/constants";
import { store } from '../../redux/store';

const PolicyDetails = () => {
    const nav = useNavigate();
    const state = store.getState();
    const { guestDetails } = state.guestReducer;
    const { id } = useParams();
    const { token } = useParams();
    const [returnPolicy, setReturnPolicy] = useState();
    const [refundPolicy, setRefundPolicy] = useState();
    const [shippingPolicy, setShippingPolicy] = useState();
    const getUserData = async () => {
        try {
            const res = await axios({
                method: "get",
                url: `${baseUrlV1}user/${id}?deviceType=3&deviceToken=${guestDetails?.token}`,
                headers: { 'Content-Type': 'application/json', userToken: `${token}` },
            })
            if (res?.data?.result?.user?.metadata.length > 0) {
                var return_policy = res?.data?.result?.user?.metadata.filter((obj) => {
                    return obj.typeName == 'RETURN_POLICY';
                });
                var shipping_policy = res?.data?.result?.user?.metadata.filter((obj) => {
                    return obj.typeName === 'SHIPPING_POLICY';
                });
                var refund_policy = res?.data?.result?.user?.metadata.filter((obj) => {
                    return obj.typeName === 'REFUND_POLICY';
                });
                setReturnPolicy(return_policy[0]);
                setRefundPolicy(refund_policy[0]);
                setShippingPolicy(shipping_policy[0]);
                formik?.setFieldValue("returnPolicy", return_policy[0]?.content);
                formik?.setFieldValue("refundPolicy", refund_policy[0]?.content);
                formik?.setFieldValue("shippingPolicy", shipping_policy[0]?.content);
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        getUserData();
    }, []);

    // formik start
    const initialValues = {
        returnPolicy: "",
        refundPolicy: "",
        shippingPolicy: ""
    };
    const onSubmit = (values) => {
        var metaDataArray = [];
        values?.returnPolicy && metaDataArray.push({
            category: 2,
            content: values?.returnPolicy,
            id: returnPolicy?.id ? returnPolicy?.id : "",
            type: 7,
        });
        values?.refundPolicy && metaDataArray.push({
            category: 2,
            content: values?.refundPolicy,
            id: refundPolicy?.id ? refundPolicy?.id : "",
            type: 8,
        });
        values?.shippingPolicy && metaDataArray.push({
            category: 2,
            content: values?.shippingPolicy,
            id: shippingPolicy?.id ? shippingPolicy?.id : "",
            type: 9,
        })
        submitMatadata(metaDataArray);
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
    });
    const submitMatadata = async (dataMeta) => {
        const data = {
            metadata: dataMeta,
            "deviceToken": "qwertyuiopasdfghjklzxcvbnm1234567890",
            "deviceType": 3,
            "referenceId": id
        }

        try {
            const res = await axios({
                method: "post",
                url: `${baseUrlV1}metadata?deviceType=3&deviceToken=${guestDetails?.token}`,
                data: data,
                headers: { 'Content-Type': 'application/json', userToken: `${token}` },
            })
            if (res.data.status == "Error") {
                notify(res.data.message, 'error');
            }
            else {
                notify(res.data.message, 'success');
                nav(`/business-details/${id}/${token}`)
            }
        } catch (error) {
            console.log(error);
        }
    }


    return (
        <>
            <ProgressBar value={80} />
            <div className='policydetails'>
                <form onSubmit={formik.handleSubmit}>
                    <div className="text-heading">Specify your policies</div>
                    <div className='text-para'>If you don’t have any you can leave this blank</div>
                    <div className=''>

                        <div className='label-text'>Return policy</div>
                        <TextArea
                            name="returnPolicy"
                            id="returnPolicy"
                            type="text"
                            onBlur={formik.handleBlur}
                            value={formik.values.returnPolicy}
                            onChange={formik.handleChange}
                        />
                    </div>

                    <div className=''>
                        <div className='label-text'>Refund policy</div>
                        <TextArea
                            name="refundPolicy"
                            id="refundPolicy"
                            type="text"
                            onBlur={formik.handleBlur}
                            value={formik.values.refundPolicy}
                            onChange={formik.handleChange}
                        />
                    </div>

                    <div className=''>
                        <div className='label-text'>Shipping policy</div>
                        <TextArea
                            name="shippingPolicy"
                            id="shippingPolicy"
                            type="text"
                            onBlur={formik.handleBlur}
                            value={formik.values.shippingPolicy}
                            onChange={formik.handleChange}
                        />
                    </div>
                    <div className='btn-section'>
                        <div>
                            <LightBtn title="Back"
                                onClick={() => nav(`/store-details/${id}/${token}`)}
                            />
                        </div>
                        <div>
                            {/* <DarkBtn /> */}
                            <DarkBtn title=" Continue " type="submit" />
                        </div>
                    </div>
                </form>
            </div>
        </>
    )
}

export default PolicyDetails