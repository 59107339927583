import React from 'react';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import { Link } from 'react-router-dom';
import Pagination from '../../../components/universal/Pagination';
import VisibilityIcon from '@mui/icons-material/Visibility';


const PendingOrders = () => {
    return (
        <div className='PendingOrders'>
            <div className='addproduct'>
            <div className='product'>Pending Dispatch</div>
            </div>
          
       <div className='table-container'>
           <Pagination/>
            <table>
                <tr className='hedding-name'>
                    <th>Generate <br/>Order ID</th>
                    <th>Buyer</th>
                    <th>Seller</th>
                    <th>Total Amount</th>
                    <th>Date</th>
                    <th>Action</th>
                </tr>
                <tr className='product-name'>
                    <td>D6X5T7X7J1F9</td>
                     <td>
                     <Link to="/admin/buyer-id-details">10100
                     </Link></td> 
                     <td> <Link to="/admin/seller-id-details"> 38</Link></td>
                    <td>23.50</td>
                    <td>05/26/2022, 11:34 AM</td>
                    <td>
                      <Link to="/admin/order-view-details">
                          < VisibilityIcon  className='icon-visibility' />
                          </Link>  
                    </td>
                </tr>
                <tr className='product-name'>
                    <td>S1M0M2J6N5U1</td>
                    <td>10100</td>
                    <td>1810</td>
                    <td>230.50</td>
                    <td>05/26/2022, 11:34 AM</td>
                    <td>
                    <Link to="order-view-details">
                          < VisibilityIcon  className='icon-visibility' />
                          </Link>  
                    </td>
                </tr>
                <tr className='product-name'>
                    <td>D6X5T7X7J1F9</td>
                    <td>10200</td>
                    <td>128</td>
                    <td>213.50</td>
                    <td>05/26/2022, 11:34 AM</td>
                    <td>
                    <Link to="order-view-details">
                          < VisibilityIcon  className='icon-visibility' />
                          </Link>  
                    </td>
                </tr>
                <tr className='product-name'>
                    <td>S1M0M2J6N5U1</td>
                    <td>40100</td>
                    <td>3800</td>
                    <td>223.50</td>
                    <td>05/26/2022, 11:34 AM</td>
                    <td>
                    <Link to="order-view-details">
                          < VisibilityIcon  className='icon-visibility' />
                          </Link>  
                    </td>
                </tr>
                <tr className='product-name'>
                    <td>C2K4Z7G8F2M7</td>
                    <td>10122</td>
                    <td>3801</td>
                    <td>233.50</td>
                    <td>05/26/2022, 11:34 AM</td>
                    <td>
                    <Link to="order-view-details">
                          < VisibilityIcon  className='icon-visibility' />
                          </Link>  
                    </td>
                </tr>
                <tr className='product-name'>
                    <td>H8A2Z3K2W5E2</td>
                    <td>20100</td>
                    <td>380</td>
                    <td>203.50</td>
                    <td>05/26/2022, 11:34 AM</td>
                    <td>
                    <Link to="order-view-details">
                          < VisibilityIcon  className='icon-visibility' />
                          </Link>  
                    </td>
                </tr>
            </table>
       </div>

    </div>
    )
}

export default PendingOrders;