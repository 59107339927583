import sweatAlertBox, { sweatAlert } from '../../universal/SweetAlertBox';

const DeleteDialog = ({
  name,
  isAddedToCart,
  isAddedToWishlist,
  handleDelete,
}) => {
  return (
    <>
      {isAddedToCart == 0 || isAddedToWishlist == 0 ? (
        <div
          onClick={() =>
            sweatAlertBox(
              'Are You Sure?',
              `You are about  delete the image titled ${name?.bold()} cannot undo this action  once confirmed`,
              'Confirm',
              'Cancel',

              handleDelete
            )
          }
          className="order-number delete"
        >
          Delete listing
        </div>
      ) : (
        <div
          onClick={() =>
            sweatAlert("Product cant be deleted as its in someone's basket")
          }
          className="order-number delete"
        >
          Delete listing
        </div>
      )}
    </>
  );
};

export default DeleteDialog;
