import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { images } from '../../../assets';
import sweatAlertBox, { sweatAlert } from '../SweetAlertBox';

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black,
  },
}));
const ToolTip = ({
  handleDelete,
  name,
  id,
  isAddedToCart,
  isAddedToWishlist,
  isImages,
  isProducts,
}) => {
  return (
    <BootstrapTooltip title="Delete Product" placement="right">
      {isAddedToCart == 0 || isAddedToWishlist == 0 ? (
        <img
          src={images.delete}
          className="delete-icon"
          alt="delete"
          onClick={() =>
            sweatAlertBox(
              'Are You Sure?',
              `You are about  delete the product titled ${name?.bold()}. You cannot undo this action once confirmed`,
              'Confirm',
              'Cancel',

              handleDelete,
              id
            )
          }
        />
      ) : isAddedToCart == 1 || isAddedToWishlist == 1 ? (
        <img
          alt="delete"
          src={images.delete}
          className="delete-icon"
          onClick={() =>
            sweatAlert("Product cant be deleted as its in someone's basket")
          }
        />
      ) : (
        <img
          alt="delete"
          src={images.delete}
          className="delete-icon"
          onClick={() =>
            sweatAlertBox(
              'Are You Sure?',
              `You are about delete the ${
                isProducts ? 'product' : 'image'
              } titled  ${name?.bold()}, you cannot undo this action  once confirmed`,
              'Confirm',
              'Cancel',

              handleDelete,
              id
            )
          }
        />
      )}
    </BootstrapTooltip>
  );
};

export default ToolTip;
