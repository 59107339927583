import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const BrandWithCauseDropDown = ({ onChange, value, options, name }) => {
  return (
    <div className="dropdown">
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <Select
            name={name}
            labelId="demo-simple-select-label"
            onChange={onChange}
            id="demo-simple-select"
            value={value}
            className="select-field setupselectbox"
          >
            {options &&
              options?.map((option, index) => {
                return (
                  <MenuItem key={index} value={option?.id}>
                    {option?.name}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default BrandWithCauseDropDown;
