import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import PublicHeader from "../../components/universal/PublicHeader";
import { images } from "../../assets";
// import RegistrationStepper from '../../components/RegistrationStepper/';
import RegisterToday from "../../components/RegistrationStepper/RegisterToday";
import AboutYou from "../../components/RegistrationStepper/AboutYou";
import AlmostThere from "../../components/RegistrationStepper/AlmostThere";
import { Container } from "@mui/material";
import ExclusiveBlogs from "../../components/universal/ExclusiveBlogs";

const ProSellerRegistration = () => {
  const [step, setStep] = useState(0);
  const [registration, setRegistration] = useState({});
  return (
    <>
      <PublicHeader />
      <div className="prosellerregistration">
        <Container className="all-container-27inch">
        <Grid container spacing={2} className="gridcenter">
          <Grid item xs={12} sm={8}>
            <div>
              <div className="register-heading">
                {" "}
                Register to become An{" "}
                <br/>
                <span className="ethical-heading">Ethical Seller</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div>
              <img src={images.register} alt="img" className="register-img" />
            </div>
          </Grid>
        </Grid>
        <div className="strepper-row">
          {/* <RegistrationStepper/>  */}

          {step === 0 ? (
            <RegisterToday
              setStep={setStep}
              step={step}
              registration={registration}
              setRegistration={setRegistration}
            />
          ) : step === 1 ? (
            <AboutYou
              setStep={setStep}
              step={step}
              registration={registration}
              setRegistration={setRegistration}
            />
          ) : step === 2 ? (
            <AlmostThere
              registration={registration}
              setRegistration={setRegistration}
            />
          ) : (
            ""
          )}
        </div>
        </Container>
        <ExclusiveBlogs/>
      </div>
    </>
  );
};

export default ProSellerRegistration;
