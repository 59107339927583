import CloseIcon from "@mui/icons-material/Close";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { Alert, AlertTitle, Container, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import axios from "axios";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { images } from "../../../assets";
import { baseUrlV1 } from "../../../core/constants";
import { addUser } from "../../../redux/actions/user";

import UpdateBankDetails from "../../../components/dialogs/UpdateBankDetails";
import UpdateBusinessDetails from "../../../components/dialogs/UpdateBusinessDetails";
import UpdatePassword from "../../../components/dialogs/UpdatePassword";
import DarkBtn from "../../../components/universal/Buttons/DarkBtn";
import LightBtn from "../../../components/universal/Buttons/LightBtn";
import MainHeading from "../../../components/universal/Headings/MainHeading";
import SubHeading from "../../../components/universal/Headings/SubHeading";
import InputTypeOutline from "../../../components/universal/InputType/inputTypeOutline";
import TextArea from "../../../components/universal/InputType/TextArea";
import { notify } from "../../../core/constants";
import { store } from "../../../redux/store";
import { axiosAll } from "../../../services/axiosAll";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export const MyProfile = () => {
  const { user } = useSelector((state) => state.userReducer);
  const [profileData, setProfileData] = useState();
  const [date, setDate] = useState();
  const [profile, setProfile] = useState();
  const [validationUrl, setValidationUrl] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dobLoading, setDOBLoading] = useState(false);
  const [polidyValidation, setPolicyValidation] = useState(false);
  const [passwordModal, setPasswordModal] = useState(false);
  const [bankAccountModal, setBankAccountModal] = useState(false);
  const [DobValidation, setDobValidation] = useState(false);
  const [dragActive, setDragActive] = React.useState(false);
  const [personalData, setPersonalData] = useState();
  const [accountToggle, setAccountToggle] = useState(false);
  const [shopToggle, setShopToggle] = useState(false);
  const [policyToggle, setPolicyToggle] = useState(false);
  const [bannerRemove, setRemove] = useState(false);
  const [openBusinessDetailsDialog, setOpenBusinessDetailsDialog] = useState(false);
  const location = useLocation();
  const tabvalue = location?.state?.tab;
  const [value, setValue] = React.useState(0);
  const [bankError, setBankError] = useState("");
  const state = store.getState();
  const { guestDetails } = state.guestReducer;
  let max_date = moment(new Date()).subtract(13, "years")._d;
  let Max_Date = moment(new Date()).subtract(12, "years")._d;
  const { userData } = useSelector((state) => state.guestUserReducer);
  const dispatch = useDispatch();

  const [bannerImage, setBannerImage] = useState();
  const [userDataProfile, setUserData] = useState({
    address: [
      {
        address: "",
        latitude: 0,
        longitude: 0,
      },
    ],
    bio: "",
    businessAddress: "",
    businessEmail: "",
    businessName: "",
    businessPhoneNumber: "",
    name: "",
    phoneNumber: "",
    proSeller: 0,
    userName: "",
  });

  const [accountData, setAccountData] = useState({
    accountNumber: "",
    bsb: "",
    deviceToken: "",
    deviceType: "",
    firstName: "",
    lastName: "",
  });

  const [policyData, setPolicy] = useState({
    deviceToken: guestDetails?.token,
    deviceType: 3,
    metadata: [
      {
        category: 2,
        content: "",
        type: 7,
        id: "",
        // referenceId: "",
      },
      {
        category: 2,
        content: "",
        type: 8,
        id: "",
        // referenceId: "",
      },
      {
        category: 2,
        content: "",
        type: 9,
        id: "",
        // referenceId: "",
      },
      {
        category: 2,
        content: "",
        type: 1,
        id: "",
        // referenceId: "",
      },
      {
        category: 2,
        content: "",
        type: 6,
        id: "",
        // referenceId: "",
      },
    ],
  });

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
    getAccount();
  };

  const userDetailsCall = async () => {
    try {
      const response = await axios(
        `${baseUrlV1}user-profile/${userData?.user?.id}?deviceType=3&deviceToken=${guestDetails?.token}`,
        {
          headers: {
            userToken: userData?.userToken,
          },
        }
      );
      if (response?.data?.result?.user?.isVerified) {
        // notify(response.data.message, 'success');

        dispatch(addUser({ user: response?.data?.result }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBankType = (bankType) => {
    switch (true) {
      case bankType === "swift_code_account_number":
        return {
          accountType: "Account Number",
          accountSubType: "Swift Code",
        };
      case bankType === "swift_code_iban_number":
        return {
          accountType: "IBAN Number",
          accountSubType: "Swift Code",
        };
      case bankType === "account_number":
        return {
          accountType: "Account Number",
          accountSubType: "",
        };
      case bankType === "routing_account_number":
        return {
          accountType: "Account Number",
          accountSubType: "Routing Number",
        };
      case bankType === "iban_number":
        return {
          accountType: "IBAN Number",
          accountSubType: "",
        };

      default:
        return {
          accountType: "",
          accountSubType: "",
        };
    }
  };

  const getAccount = async () => {
    try {
      const responce = await axiosAll({
        method: "get",
        url: `v2/account`,
      });
      if (responce?.statusCode === 200) {
        setProfileData(responce?.result);
        setAccountData({
          ...accountData,
          accountNumber: responce?.result?.account?.last4 ? responce?.result?.account?.last4 : "",
          bsb: responce?.result?.account?.routingNumber ? responce?.result?.account?.routingNumber?.replaceAll("-", "") : "",
          deviceToken: guestDetails?.token ? guestDetails?.token : "",
          deviceType: 3,
          fullName: responce?.result?.account?.accountHolderName ? `${responce?.result?.account?.accountHolderName}` : "",
        });
      } else {
        setBankError(responce?.message);
      }
    } catch (error) {
      console.log(error);
      setBankError(error?.response.statusCode === 102 && error?.response?.message);
    }
  };

  var DobDate = `${profileData?.account?.dob?.year}-${profileData?.account?.dob?.month}-${profileData?.account?.dob?.day}T07:03:03.000Z`;
  var VideoUrl = personalData?.user?.metadata.find((e) => e.type == 1);

  const getProfile = async () => {
    try {
      const responce = await axiosAll({
        method: "get",
        url: `v1/user-profile/${user?.user?.user?.id}`,
      });
      setPersonalData(responce?.result);
      setUserData({
        ...userDataProfile,
        address: [
          {
            address: responce?.result?.user?.address[0]?.address ? responce?.result?.user?.address[0]?.address : "",
            latitude: 0,
            longitude: 0,
          },
        ],
        registrationNumber: responce?.result?.user?.userVatInformation?.registrationNumber
          ? responce?.result?.user?.userVatInformation?.registrationNumber
          : "",
        bio: responce?.result?.user?.bio ? responce?.result?.user?.bio : "",
        businessAddress: responce?.result?.user?.userVatInformation?.businessAddress
          ? responce?.result?.user?.userVatInformation?.businessAddress
          : "",
        businessEmail: responce?.result.user?.userVatInformation?.businessEmail
          ? responce?.result.user?.userVatInformation?.businessEmail
          : "",
        businessName: responce?.result.user?.userVatInformation?.businessName
          ? responce?.result.user?.userVatInformation?.businessName
          : "",
        businessPhoneNumber: responce?.result.user?.userVatInformation?.phoneNumber
          ? responce?.result.user?.userVatInformation?.phoneNumber
          : "",
        name: responce?.result.user?.name ? responce?.result.user?.name : "",
        phoneNumber: responce?.result.user?.phoneNumber ? responce?.result.user?.phoneNumber : "",
        proSeller: responce?.result?.user?.isProSeller ? responce?.result?.user?.isProSeller : 0,
        userName: responce?.result.user?.userName ? responce?.result.user?.userName : "",
      });

      const BannerImage = responce?.result?.user?.metadata.find((e) => e.type == 6);

      const videoUrl = responce?.result?.user?.metadata.find((e) => e.type == 1);

      const ReturnPolicy = responce?.result?.user?.metadata.find((e) => e.type == 7);
      const RefundPolicy = responce?.result?.user?.metadata.find((e) => e.type == 8);
      const ShippingPolicy = responce?.result?.user?.metadata.find((e) => e.type == 9);
      setPolicy({
        ...policyData,
        metadata: [
          {
            category: 2,
            content: ReturnPolicy?.content ? ReturnPolicy?.content : "",
            type: 7,
            id: ReturnPolicy?.id,
          },
          {
            category: 2,
            content: RefundPolicy?.content ? RefundPolicy?.content : "",
            type: 8,
            id: RefundPolicy?.id,
          },
          {
            category: 2,
            content: ShippingPolicy?.content ? ShippingPolicy?.content : "",
            type: 9,
            id: ShippingPolicy?.id,
          },
          {
            category: 2,
            content: videoUrl?.content,
            type: 1,
            id: videoUrl?.id,
          },
          {
            category: 2,
            content: BannerImage?.content ? BannerImage?.content : "",
            type: 6,
            id: BannerImage?.id,
          },
        ],
        referenceId: responce?.result?.user?.id,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const deleteVideoUrl = async () => {
    if (policyData.metadata[3]?.content == "") {
      try {
        const response = await axiosAll({
          method: "delete",
          url: `v1/metadata/${policyData.metadata[3]?.id}`,
        });
        if (response?.statusCode == 200) {
          notify(response.message, "success");
          getProfile();
          setAccountToggle(false);
          setShopToggle(false);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const updateUserData = async () => {
    setLoading(true);
    try {
      const response = await axiosAll({
        method: "post",
        url: `v1/user`,
        data: userDataProfile,
      });
      if (response?.statusCode === 200) {
        setLoading(false);
        // notify(response.message, 'success');
        getProfile();
        setAccountToggle(false);
        setOpenBusinessDetailsDialog(false);
        setShopToggle(false);
      } else {
        notify(response.message, "error");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const updatePassword = async (data) => {
    setLoading(true);
    try {
      const responce = await axiosAll({
        method: "put",
        url: `v2/user/change-password`,
        data: data,
      });
      if (responce?.statusCode == 200) {
        setPasswordModal(false);
        setLoading(false);
        notify(responce?.message, "success");
      } else {
        setLoading(false);
        notify(responce?.message, "error");
      }
      setAccountToggle(false);
      setShopToggle(false);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  const updateAccountData = async (values, bankType) => {
    var index = values?.fullName?.indexOf(" ");
    var firstName = values?.fullName?.substr(0, index);
    var lastName = values?.fullName?.substr(index + 1);
    const bankAccount = {
      accountNumber: `${values?.accountNumber}`,
      deviceToken: accountData?.deviceToken,
      deviceType: accountData?.deviceType,
      firstName: firstName,
      lastName: lastName,
    };
    if (bankType?.accountSubType !== "" && values?.bsb) {
      bankAccount.bsb = `${values?.bsb}`;
    }
    setLoading(true);
    try {
      const responce = await axiosAll({
        method: "post",
        url: `v2/accounts`,
        data: bankAccount,
      });
      if (responce?.statusCode == 200) {
        if (profileData?.account?.verificationStatus === "unverified" || profileData?.account?.verificationStatus == null) {
          const url = responce?.result?.accountLink?.url;
          window.open(url, "_self");
          notify(responce?.message, "success");
          setLoading(false);
          setAccountToggle(false);
          setBankAccountModal(false);
          userDetailsCall();
        } else {
          notify(responce?.message, "success");
          setLoading(false);
          setAccountToggle(false);
          setBankAccountModal(false);
          getAccount();
          userDetailsCall();
        }
      } else {
        setLoading(false);
        notify(responce?.message, "error");
      }
    } catch (error) {
      notify(error, "error");
    }
  };

  const updatePolicy = async () => {
    if (policyData.metadata[3]?.content || policyData.metadata[3]?.content == undefined) {
      const policydata = {
        deviceToken: guestDetails?.token,
        deviceType: 3,
        metadata: [
          {
            category: 2,
            content: policyData.metadata[0]?.content,
            type: 7,
            id: policyData.metadata[0]?.id,
            // referenceId: "",
          },
          {
            category: 2,
            content: policyData.metadata[1]?.content,
            type: 8,
            id: policyData.metadata[1]?.id,
            // referenceId: "",
          },
          {
            category: 2,
            content: policyData.metadata[2]?.content,
            type: 9,
            id: policyData.metadata[2]?.id,
            // referenceId: "",
          },
        ],
        referenceId: policyData?.referenceId,
      };
      if (policyData.metadata[0]?.content && policyData.metadata[1]?.content && policyData.metadata[2]?.content) {
        setPolicyValidation(false);
        try {
          const responce = await axiosAll({
            method: "post",
            url: `v1/metadata`,
            data: policydata,
          });
          setPolicyToggle(false);
          getProfile();
        } catch (error) {
          console.log(error);
        }
      } else {
        setPolicyValidation(true);
      }
    } else {
      deleteVideoUrl();
    }
  };

  const updateVideoUrl = async () => {
    const Url = {
      deviceToken: guestDetails?.token,
      deviceType: 3,
      metadata: [
        {
          category: 2,
          content: policyData.metadata[3]?.content,
          type: 1,
          id: policyData.metadata[3]?.id,
        },
      ],
      referenceId: policyData?.referenceId,
    };
    try {
      const responce = await axiosAll({
        method: "post",
        url: `v1/metadata`,
        data: Url,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const updateMetaBanner = async () => {
    const Url = {
      deviceToken: guestDetails?.token,
      deviceType: 3,
      metadata: [
        {
          category: 2,
          content: policyData.metadata[4]?.content,
          type: 6,
          id: policyData.metadata[4]?.id,
        },
      ],
      referenceId: policyData?.referenceId,
    };
    try {
      const responce = await axiosAll({
        method: "post",
        url: `v1/metadata`,
        data: Url,
      });
      getProfile();
    } catch (error) {
      console.log(error);
    }
  };

  const updateBannerImage = async () => {
    setLoading(true);
    const formData1 = new FormData();
    formData1.append("file", bannerImage);
    formData1.append("value", bannerImage);
    formData1.append("fileType", 2);
    formData1.append("folderName", "profile_image/");
    try {
      const responce = await axiosAll({
        method: "post",
        url: `v1/metadata/upload-image-video`,
        data: formData1,
      });
      if (responce?.statusCode === 200) {
        if (userDataProfile.businessEmail === "") {
          notify("Business email is required", "error");
          setLoading(false);
          setShopToggle(false);
        } else if (userDataProfile?.businessName === "") {
          notify("Business name is required", "error");
          setLoading(false);
          setShopToggle(false);
        } else if (userDataProfile?.businessAddress === "") {
          notify("Business address is required", "error");
          setLoading(false);
          setShopToggle(false);
        } else {
          const Array1 = policyData?.metadata;
          const objIndex = Array1.findIndex((e) => e.type == 6);
          const updateData = (Array1[objIndex].content = responce?.result?.fileName);
          setPolicy({ ...policyData });
          setLoading(false);
          updateMetaBanner();
          getProfile();
          setShopToggle(false);
          setBannerImage("");

          setRemove(false);
          notify(responce?.message, "success");
        }
      } else {
        notify(responce?.message, "error");
        setLoading(false);
        setBannerImage("");
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (event) {
    event.preventDefault();
    event.stopPropagation();
    if (event?.dataTransfer.files[0]) {
      setBannerImage(event?.dataTransfer.files[0]);
      setRemove(false);
      setDragActive(false);
    }
  };

  const updateLogo = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", profile);
    formData.append("type", 1);
    try {
      const responce = await axiosAll({
        method: "post",
        url: `v1/image`,
        data: formData,
      });
      if (responce?.statusCode == 200) {
        if (userDataProfile.businessEmail === "") {
          notify("Business email is required", "error");
        } else if (userDataProfile?.businessName === "") {
          notify("Business name is required", "error");
        } else if (userDataProfile?.businessAddress == "") {
          notify("Business address is required", "error");
        } else {
          setProfile("");
          updateUserData();
          setShopToggle(false);
          getProfile();
          setLoading(false);
        }
      } else {
        notify(responce?.message, "error");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const updateAll = () => {
    if (userDataProfile?.businessEmail === "") {
      notify("Business email is required to update shop details", "error");
    } else if (userDataProfile?.businessName === "") {
      notify("Business name is required  to update shop details", "error");
    } else if (userDataProfile?.businessAddress === "") {
      notify("Business address is required  to update shop details", "error");
    } else if (userDataProfile?.bio === "") {
      notify("Bio is required  to update shop details", "error");
    } else {
      if (profile && bannerImage) {
        updateLogo();
        updateVideoUrl();
        updateBannerImage();
        getProfile();
      } else if (profile) {
        updateLogo();
        updateVideoUrl();
        getProfile();
      } else if (bannerImage) {
        updateBannerImage();
        updateVideoUrl();
        getProfile();
      } else {
        updateVideoUrl();
        updatePolicy();
        getProfile();
      }
      updateUserData();
    }
  };

  const updateDate = async () => {
    setDOBLoading(true);
    try {
      const responce = await axiosAll({
        method: "post",
        url: `v2/strip_dob_update`,
        data: { dob: date },
      });
      if (responce?.statusCode == 200) {
        getAccount();
        setDOBLoading(false);

        notify(responce.message, "success");
      } else {
        setDOBLoading(false);

        notify(responce.message, "error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const AccountToggle = () => {
    setAccountToggle(!accountToggle);
  };

  const ShopToggle = () => {
    setShopToggle(!shopToggle);
    setProfile("");
    setBannerImage("");
    setRemove(false);
  };

  const PolicyToggle = () => {
    setPolicyToggle(!policyToggle);
  };

  const handleUserChange = (e) => {
    setUserData({ ...userDataProfile, [e.target.name]: e.target.value });
  };

  const handleUploadBanner = (event) => {
    if (event.target.files.length > 0) {
      setBannerImage(event.currentTarget.files[0]);
      setRemove(false);
    }
  };

  const handlePolicyChange = async (item, type) => {
    if (type == 1) {
      const regex = new RegExp("(/|%3D|v=)([0-9A-z-_]{11})([%#?&]|$)");
      const videos = ["mp4", "3gp", "ogg", "mov", "wmv", "avi", "mkv"];

      const extension = item.target.value.split(/[#?]/)[0].split(".").pop().trim();
      if (videos?.includes(extension)) {
        setValidationUrl(false);
      } else if (regex.test(item.target.value)) {
        setValidationUrl(false);
      } else {
        setValidationUrl(true);
      }
    }
    if (validationUrl && type == 1) {
      const Arr = policyData?.metadata;
      const objIndex = Arr.findIndex((e) => e.type == type);
      const updateData = (Arr[objIndex].content = item.target.value);
      await setPolicy({ ...policyData });
    } else {
      const Array = policyData?.metadata;
      const objindex = Array.findIndex((e) => e.type == type);
      const updateData = (Array[objindex].content = item.target.value);
      await setPolicy({ ...policyData });
    }
  };

  const handleDateChange = (newValue) => {
    if (Max_Date > newValue) {
      console.log(true);
      setDobValidation(false);
      setDate(newValue);
    } else {
      console.log(false);

      setDobValidation(true);
    }
  };

  useEffect(() => {
    getProfile();
    getAccount();
  }, []);

  useEffect(() => {
    if (tabvalue) {
      setValue(tabvalue);
    }
  }, [tabvalue]);

  const banner = personalData?.user?.metadata?.find((x) => x.typeName === "BACKGROUND_IMAGE")?.content;

  const handleRemoveImage = () => {
    setRemove(true);
    setBannerImage("");
  };

  const showBannerImage = () => {
    if (loading) {
      return false;
    } else if (bannerRemove) {
      return false;
    } else if (banner) {
      return true;
    } else if (bannerImage) {
      return true;
    } else if (!banner || !bannerImage) {
      return false;
    } else {
      return true;
    }
  };

  const bankType = personalData?.user?.stripeBank?.supportType;

  return (
    <>
      <div className="myprofile">
        <Container className="all-container-27inch">
          <Grid container spacing={2}>
            {/* <Grid item xs={12} sm={3} className="mobilehide">
                    <SellerSideBar /> 
                </Grid> */}
            <Grid item xs={12} sm={12} className="myprofilecontainer">
              <MainHeading title="My Profile" />
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs value={value} onChange={handleChangeTab} aria-label="basic tabs example">
                  <Tab label="Personal" {...a11yProps(0)} />
                  <Tab label="Business & payment" {...a11yProps(1)} />
                  {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
                </Tabs>
              </Box>
              <TabPanel value={value} index={0}>
                <div className="personal-container">
                  <div className="text-section">
                    <SubHeading title="Account Details" />
                    {accountToggle ? (
                      <div className="btn-section">
                        <LightBtn title="Close" onClick={AccountToggle} />
                        <DarkBtn
                          title="Save"
                          onClick={() => {
                            if (userDataProfile?.phoneNumber === "") {
                              notify("Please add mobile number", "error");
                            } else {
                              updateUserData();
                            }
                          }}
                        />
                      </div>
                    ) : (
                      <LightBtn title="Update" onClick={AccountToggle} />
                    )}
                  </div>
                  <Grid container spacing={0} className="gridcenter">
                    <Grid item xs={12} sm={3.4}>
                      <div className="order-number">Login email</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <div className="order-data "> {personalData?.user?.email}</div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={0} className="">
                    <Grid item xs={12} sm={3.4}>
                      <div className="order-number">Mobile number</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      {accountToggle ? (
                        <div className="order-data ">
                          <InputTypeOutline
                            type={"number"}
                            name={"phoneNumber"}
                            onChange={handleUserChange}
                            value={userDataProfile?.phoneNumber}
                          />
                        </div>
                      ) : (
                        <div className="order-data ">{personalData?.user?.phoneNumber}</div>
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div className="personal-container">
                  <div className="text-section">
                    <SubHeading title="Password" />
                    <UpdatePassword
                      updatePassword={updatePassword}
                      setLoading={setLoading}
                      loading={loading}
                      passwordModal={passwordModal}
                      setPasswordModal={setPasswordModal}
                    />
                  </div>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={3.4}>
                      <div className="order-number">Password</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <div className="order-data ">**** </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="personal-container">
                  <div className="text-section">
                    <div>
                      <SubHeading title="Shop Details" />
                      <div className="shop-para"> These details will form your seller profile in the app </div>
                    </div>

                    <div className="btn-section">
                      {shopToggle ? (
                        <div className="btn-section">
                          <LightBtn title="Close" onClick={ShopToggle} />
                          <DarkBtn title="Save" onClick={updateAll} />
                        </div>
                      ) : (
                        <LightBtn title="Update" onClick={ShopToggle} />
                      )}
                    </div>
                  </div>

                  <Grid container spacing={0} className="gridcenter mrgbottom">
                    <Grid item xs={12} sm={3.4}>
                      <div className="logo-text">Logo</div>
                      <div className="pixel-text">(135px x 135px)</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      {shopToggle ? (
                        <div className="file-section-logo">
                          <label for="files" className="drag-label">
                            <div className="store-img-row">
                              <div className="upload-text">
                                <input
                                  id="files"
                                  className="input-type-file-upload"
                                  type="file"
                                  name="logo"
                                  style={{ display: "none" }}
                                  onChange={(event) => {
                                    if (event.target.files.length > 0) {
                                      setProfile(event.currentTarget.files[0]);
                                    }
                                  }}
                                />
                                {/* Upload */}
                                {loading ? (
                                  "Loading..."
                                ) : (
                                  <img
                                    src={profile ? URL.createObjectURL(profile) : personalData?.user?.profilePicture}
                                    className="upload-img-new"
                                    alt="profile"
                                  />
                                )}
                              </div>
                            </div>
                          </label>
                        </div>
                      ) : (
                        <>
                          <img
                            className="store-img-row "
                            src={personalData?.user?.profilePicture ? personalData?.user?.profilePicture : images?.defaultUser}
                            height="125px"
                            width="125px"
                            alt="user"
                          />
                        </>
                      )}

                      {/* (
                       
                      )}

                      {profile && shopToggle ? (
                        <img
                          className="store-img-row "
                          src={URL.createObjectURL(profile)}
                          height="125px"
                          width="125px"
                        />
                      ) : (
                        ""
                      )} */}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} className="gridcenter mrgbottom">
                    <Grid item xs={12} sm={3.4}>
                      <div className="logo-text">Store Banner</div>
                      <div className="pixel-text">(2446px x 580px)</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      {shopToggle ? (
                        <div className="file-section">
                          {showBannerImage() && <CloseIcon onClick={handleRemoveImage} className="upload-close-icon" />}
                          <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                            <label for="files_banner" className={dragActive ? "drag-active drag-label" : "drag-label"}>
                              <div className="cover-img">
                                {/* <div > */}

                                {loading ? (
                                  "Loading..."
                                ) : showBannerImage() ? (
                                  <img
                                    src={
                                      bannerImage
                                        ? URL.createObjectURL(bannerImage)
                                        : personalData?.user?.metadata?.find((x) => x.typeName === "BACKGROUND_IMAGE")?.content
                                    }
                                    className="upload-banner-new"
                                    alt="banner"
                                  />
                                ) : (
                                  ""
                                )}
                                {/* </div> */}

                                {!showBannerImage() && (
                                  <div className="">
                                    <div className="upload-text banner-img1">
                                      {" "}
                                      <div>
                                        <img src={images.upload} className="upload-img" alt="upload" />
                                      </div>
                                      <span className="uploadtext">
                                        <input
                                          id="files_banner"
                                          className="input-type-file-upload"
                                          type="file"
                                          accept="image/png,image/jpeg,image/jpg"
                                          name="banner"
                                          style={{ display: "none" }}
                                          onChange={handleUploadBanner}
                                        />
                                        click here
                                      </span>{" "}
                                      to select files or upload images
                                    </div>
                                  </div>
                                )}
                              </div>
                            </label>
                            {dragActive && (
                              <div
                                id="drag-file-element"
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}
                              ></div>
                            )}
                          </form>
                        </div>
                      ) : (
                        <div className="cover-img">
                          {personalData?.user?.metadata?.find((x) => x.typeName === "BACKGROUND_IMAGE")?.content ||
                          bannerImage ? (
                            <img
                              alt="banner"
                              className="bg-img"
                              src={
                                bannerImage
                                  ? URL.createObjectURL(bannerImage)
                                  : personalData?.user?.metadata?.find((x) => x.typeName === "BACKGROUND_IMAGE")?.content
                              }
                            />
                          ) : (
                            <span className="nobanner_image">No banner image available</span>
                          )}
                        </div>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} className="gridcenter mrgbottom">
                    <Grid item xs={12} sm={3.4}>
                      <div className="logo-text">Store Name</div>
                    </Grid>
                    {shopToggle ? (
                      <Grid item xs={12} sm={7}>
                        <InputTypeOutline name={"userName"} onChange={handleUserChange} value={userDataProfile?.userName} />
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={7} className="logo-text">
                        {personalData?.user?.userName}
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={0} className="gridcenter mrgbottom">
                    <Grid item xs={12} sm={3.4}>
                      <div className="logo-text">Full Name</div>
                    </Grid>
                    {shopToggle ? (
                      <Grid item xs={12} sm={7} className="logo-text">
                        <InputTypeOutline name={"name"} onChange={handleUserChange} value={userDataProfile?.name} />
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={7} className="logo-text">
                        {" "}
                        {personalData?.user?.name}
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={0} className="gridcenter mrgbottom">
                    <Grid item xs={12} sm={3.4}>
                      <div className="logo-text">Bio</div>
                    </Grid>
                    {shopToggle ? (
                      <Grid item xs={12} sm={7}>
                        <TextArea name="bio" value={userDataProfile?.bio} onChange={handleUserChange} />{" "}
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={7} className="emailidtext">
                        {" "}
                        {personalData?.user?.bio}
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={0} className=" mrgbottom">
                    <Grid item xs={12} sm={3.4}>
                      <div className="logo-text">
                        Video Url <br /> (Optional)
                      </div>
                    </Grid>
                    {shopToggle ? (
                      <Grid item xs={12} sm={7}>
                        <InputTypeOutline
                          name="content"
                          value={policyData?.metadata[3]?.content}
                          onChange={(e) => handlePolicyChange(e, policyData?.metadata[3]?.type)}
                        />
                        {validationUrl ? <div className="input-error-message">Please add valid link for video url</div> : ""}
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={7} className="emailidtext">
                        {policyData?.metadata[3]?.content}
                      </Grid>
                    )}
                  </Grid>
                </div>

                <div className="personal-container">
                  <div className="text-section">
                    <SubHeading title="Policies" />

                    <div className="btn-section">
                      {policyToggle ? (
                        <div className="btn-section">
                          <LightBtn title="Close" onClick={PolicyToggle} />
                          <DarkBtn title="Save" onClick={updatePolicy} />
                        </div>
                      ) : (
                        <LightBtn title="Update" onClick={PolicyToggle} />
                      )}
                    </div>
                  </div>
                  <Grid container spacing={0} className="gridcenter mrgbottom">
                    <Grid item xs={12} sm={3.4}>
                      <div className="logo-text">Return Policy </div>
                    </Grid>
                    {policyToggle ? (
                      <Grid item xs={12} sm={7}>
                        <TextArea
                          name="content"
                          value={policyData?.metadata[0]?.content}
                          onChange={(e) => handlePolicyChange(e, policyData?.metadata[0]?.type)}
                        />{" "}
                        {polidyValidation && policyData?.metadata[0]?.content == "" ? (
                          <div className="input-error-message">This feild is required</div>
                        ) : (
                          ""
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={7} className="emailidtext">
                        {policyData?.metadata[0]?.content}
                      </Grid>
                    )}
                  </Grid>
                  <Grid container spacing={0} className="gridcenter mrgbottom">
                    <Grid item xs={12} sm={3.4}>
                      <div className="logo-text"> Refund Policy</div>
                    </Grid>
                    {policyToggle ? (
                      <Grid item xs={12} sm={7}>
                        <TextArea
                          name="content"
                          value={policyData?.metadata[1]?.content}
                          onChange={(e) => handlePolicyChange(e, policyData?.metadata[1]?.type)}
                        />{" "}
                        {polidyValidation && policyData?.metadata[1]?.content == "" ? (
                          <div className="input-error-message">This feild is required</div>
                        ) : (
                          ""
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={7} className="emailidtext">
                        {policyData?.metadata[1]?.content}
                      </Grid>
                    )}
                  </Grid>{" "}
                  <Grid container spacing={0} className="mrgbottom">
                    <Grid item xs={12} sm={3.4}>
                      <div className="logo-text">Shipping Policy </div>
                    </Grid>
                    {policyToggle ? (
                      <Grid item xs={12} sm={7}>
                        <TextArea
                          name="content"
                          value={policyData?.metadata[2]?.content}
                          onChange={(e) => handlePolicyChange(e, policyData?.metadata[2]?.type)}
                        />{" "}
                        {polidyValidation && policyData?.metadata[2]?.content == "" ? (
                          <div className="input-error-message">This feild is required</div>
                        ) : (
                          ""
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={12} sm={7} className="emailidtext">
                        {policyData?.metadata[2]?.content}
                      </Grid>
                    )}
                  </Grid>
                </div>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <div className="personal-container">
                  <div className="text-section">
                    <SubHeading title="Business Details" />
                    <UpdateBusinessDetails
                      userData={userDataProfile}
                      handleChange={handleUserChange}
                      updateUserData={updateUserData}
                      setOpenBusinessDetailsDialog={setOpenBusinessDetailsDialog}
                      openBusinessDetailsDialog={openBusinessDetailsDialog}
                      loading={loading}
                      setLoading={setLoading}
                      getProfile={getProfile}
                    />
                  </div>
                  <div className="shop-para">
                    {" "}
                    Your business information will be publicly viewable to users within the app on your Pro Seller Profile screen.
                    To update or add a VAT number, please contact support@goethical.com{" "}
                  </div>
                  <Grid container spacing={0} className="gridcenter">
                    <Grid item xs={12} sm={3.4}>
                      <div className="order-number">Business Name</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <div className="order-data "> {personalData?.user?.userVatInformation?.businessName} </div>
                    </Grid>
                  </Grid>

                  <Grid container spacing={0} className="gridcenter">
                    <Grid item xs={12} sm={3.4}>
                      <div className="order-number">Company registration number</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <div className="order-data "> {personalData?.user?.userVatInformation?.registrationNumber} </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} className="gridcenter">
                    <Grid item xs={12} sm={3.4}>
                      <div className="order-number">VAT registration number Contact us to update</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <div className="order-data "> {personalData?.user?.userVatInformation?.vatNumber} </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} className="gridcenter">
                    <Grid item xs={12} sm={3.4}>
                      <div className="order-number">Business Email</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <div className="order-data "> {personalData?.user?.userVatInformation?.businessEmail}</div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} className="gridcenter">
                    <Grid item xs={12} sm={3.4}>
                      <div className="order-number">Business Phone</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <div className="order-data "> {personalData?.user?.userVatInformation?.phoneNumber} </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={3.4}>
                      <div className="order-number">Business Address</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <div className="order-data "> {personalData?.user?.userVatInformation?.businessAddress} </div>
                    </Grid>
                  </Grid>
                </div>
                <div className="personal-container">
                  <div className="text-section">
                    <SubHeading title="Bank Details" />
                    <UpdateBankDetails
                      accountData={accountData}
                      onclick={updateAccountData}
                      setLoading={setLoading}
                      loading={loading}
                      bankAccountModal={bankAccountModal}
                      setBankAccountModal={setBankAccountModal}
                      updateDisabled={!!bankError}
                      bankType={getBankType(bankType)}
                    />
                  </div>
                  {bankError && (
                    <Alert severity="error">
                      <AlertTitle>{bankError}</AlertTitle>
                    </Alert>
                  )}
                  <div className="shop-para">
                    {" "}
                    After each purchase Go Ethical will pay the amount owed directly to your bank account. The reference for the
                    transaction will include the invoice number.{" "}
                  </div>
                  {profileData?.account?.verificationStatus === "unverified" ? (
                    <div className="info-icon-row">
                      <span className="icon-info">
                        Restricted <NotInterestedIcon />
                      </span>
                      <span className="info-box">
                        Provide more information in order to enable payments and payouts for this account.
                        <div className="textdark">information needed - Due now</div>
                        <ul>
                          {profileData?.account?.fieldsNeeded
                            ? profileData?.account?.fieldsNeeded?.map((item, index) => {
                                return <li key={index}>{item}</li>;
                              })
                            : null}
                        </ul>
                        <hr className="line" />
                        <div className="lastbtn-section">
                          <button
                            className="verify-btn"
                            onClick={() => window.open(profileData?.account?.verificationLink, "_self")}
                          >
                            Verify your account
                          </button>
                        </div>
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                  <Grid container spacing={0} className="gridcenter">
                    <Grid item xs={12} sm={3.4}>
                      <div className="order-number">Country</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <div className="order-data ">{personalData?.user?.stripeBank?.country} </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} className="gridcenter">
                    <Grid item xs={12} sm={3.4}>
                      <div className="order-number">Account holder name</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <div className="order-data ">{profileData?.account?.accountHolderName} </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} className="gridcenter">
                    <Grid item xs={12} sm={3.4}>
                      <div className="order-number">{getBankType(bankType)?.accountType}</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      {profileData?.account?.last4 ? <div className="order-data "> ***{profileData?.account?.last4} </div> : ""}
                    </Grid>
                  </Grid>
                  {getBankType(bankType)?.accountSubType && (
                    <Grid container spacing={0} className="gridcenter">
                      <Grid item xs={12} sm={3.4}>
                        <div className="order-number">{getBankType(bankType)?.accountSubType}</div>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <div className="order-data ">{profileData?.account?.routingNumber?.replaceAll("-", "")}</div>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container spacing={0} className="gridcenter">
                    <Grid item xs={12} sm={3.4}>
                      <div className="order-number">Documents Status</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <div className="order-data ">
                        {/* capitalize  */}
                        {profileData?.account?.verificationStatus?.replace(/\b\w/g, (l) => l?.toUpperCase())}
                        {/* {profileData?.account?.verificationStatus}{' '} */}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} className="gridcenter">
                    <Grid item xs={12} sm={3.4}>
                      <div className="order-number">Payouts Enabled</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <div className="order-data "> {profileData?.account?.payoutsEnabled ? "True" : "False"}</div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0} className="gridcenter">
                    <Grid item xs={12} sm={3.4}>
                      <div className="order-number">Charges Enabled</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <div className="order-data ">{profileData?.account?.chargesEnabled ? "True" : "False"}</div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={3.4}>
                      <div className="order-number">Card Payments</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <div className="order-data ">
                        {" "}
                        {profileData?.account?.cardPayments?.replace(/\b\w/g, (l) => l?.toUpperCase())}
                      </div>
                    </Grid>
                  </Grid>
                  <div></div>
                </div>

                <div className="personal-container">
                  <Grid container spacing={0} className="gridcenter spacegrid">
                    <Grid item xs={12} sm={3.4}>
                      <div className="order-number">Update date of birth</div>
                    </Grid>
                    <Grid item xs={12} sm={7}>
                      <div className="update-date">
                        <LightBtn disabled={dobLoading} title={dobLoading ? "Loading..." : "Update"} onClick={updateDate} />
                      </div>
                    </Grid>
                  </Grid>
                  <div className="datepicker">
                    {/* <div className="input-field-outline"> */}{" "}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      {" "}
                      <DesktopDatePicker
                        name="dob"
                        inputFormat="dd/MM/yyyy"
                        value={date ? date : DobDate}
                        maxDate={max_date}
                        openTo="year"
                        onChange={handleDateChange}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>
                    {/* </div> */}
                  </div>
                  {DobValidation ? <div className="input-error-message">please choose valid DOB</div> : ""}
                </div>
              </TabPanel>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};
