import Grid from '@mui/material/Grid';
import { Container } from '@mui/system';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import DashboardSkeleton from '../../../components/Skeleton/DashboardSkeleton';
import FinancalReportSkeleton from '../../../components/Skeleton/FinancalReportSkeleton';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import DatePicker from '../../../components/universal/DatePicker';
import BasicPagination from '../../../components/universal/Pagination';
import { store } from '../../../redux/store';
import { axiosAll } from '../../../services/axiosAll';
import BasicTable from '../Orders/OrderTable';

const FinancialReports = () => {
  const CurrentDate = new Date();
  const { user } = useSelector((state) => state.userReducer);
  const PreviousDate = new Date().setFullYear(new Date().getFullYear() - 1);
  const [finalReport, setFinalReport] = useState();
  const [startDate, setStartDate] = React.useState(PreviousDate);
  const [endDate, setEndDate] = React.useState(CurrentDate);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [paginationCount, setCount] = useState();

  const endDateInput = React.useRef();
  const startDateInput = React.useRef();
  const { loginReducer } = store.getState();
  const [Loading, setLoading] = useState(true);

  const TableColumn = [
    'Order number',
    'Gross amount (£)',
    'Total fees charged (£)',
    'Net payout (£)',
    ' Payout date',
    ' Refund',
  ];

  const getFinancialReport = async (startDate, endDate) => {
    const Date1 = moment(startDate).format('DD-MM-YYYY');
    const Date2 = moment(endDate).format('DD-MM-YYYY');
    const response = await axiosAll({
      method: 'get',
      url: `v2/order/order-report-stripe/${user?.user?.user?.id}/${Date1}/${Date2}`,
    });
    if (response?.statusCode == 200) {
      setFinalReport(response?.result);
      setCount(response?.result?.transactions);
      setPage(0);
    }
    setLoading(false);
  };

  const handlefilter = () => {
    getFinancialReport(startDate, endDate);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(() => {
    getFinancialReport(startDate, endDate);
  }, []);
  return (
    <div className="financialreports">
      <Container className="all-container-27inch">
        {Loading ? (
          <DashboardSkeleton type={1} page="finance" />
        ) : (
          <>
            <div className="complete-section">
              <div className="financial-heading">Financial Reports</div>
              <div className="store-row">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="store-heading">
                        £{' '}
                        {finalReport?.total?.totalGrossAmount
                          ? finalReport?.total?.totalGrossAmount?.toFixed(2)
                          : 0}
                      </div>
                      <div className="box-title marginbott">
                        Total gross amount
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="store-heading">
                        £{' '}
                        {finalReport?.total?.totalGoEthicalCommission
                          ? finalReport?.total?.totalGoEthicalCommission?.toFixed(
                              2
                            )
                          : 0}
                      </div>
                      <div className="box-title marginbott">
                        Total GoEthical commission
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="store-heading">
                        £{' '}
                        {finalReport?.total?.totalCardProcessinFees
                          ? finalReport?.total?.totalCardProcessinFees?.toFixed(
                              2
                            )
                          : 0}
                      </div>
                      <div className="box-title marginbott">
                        Total card processing fees
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6} md={3} className="grid-box">
                    <div className="">
                      <div className="store-heading">
                        £{' '}
                        {finalReport?.total?.totalNetPayout
                          ? finalReport?.total?.totalNetPayout?.toFixed(2)
                          : 0}
                      </div>
                      <div className="box-title marginbott">
                        Total net payout
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </div>
            <div className="filters">
              <div className="store-heading">Filters</div>
              <div className="box-title search-title">
                Search by payout date range
              </div>
              <div className="datefilter-btn">
                <div className="datepickerpart">
                  <DatePicker
                    setStartDate={setStartDate}
                    startDate={startDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    endDateInput={endDateInput}
                    startDateInput={startDateInput}
                  />
                  <LightBtn title=" Filter " onClick={() => handlefilter()} />
                </div>

                {paginationCount?.length > 0 && (
                  <div className="exportbtn">
                    {finalReport?.transactions.length ? (
                      <DarkBtn
                        onClick={() =>
                          window.open(finalReport?.csvDownload, '_blank')
                        }
                        title="Export to CSV"
                      />
                    ) : (
                      <DarkBtn disable={true} title="Export To CSV" />
                    )}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        <div className="tabledata">
          {Loading ? (
            <FinancalReportSkeleton />
          ) : (
            <>
              {' '}
              <BasicTable
                TableColumn={TableColumn}
                Row={finalReport?.transactions}
                page={page}
                rowsPerPage={rowsPerPage}
              />
              {finalReport?.transactions?.length > 10 ? (
                <BasicPagination
                  page={page}
                  setPage={setPage}
                  handleChangePage={handleChangePage}
                  handleChangeRowsPerPage={handleChangeRowsPerPage}
                  Row={paginationCount}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                />
              ) : (
                ''
              )}
            </>
          )}
          {/* <div className="empty-table">
            <img src={images.shopImg} className="storeimg" />
            <div className="box-title">Your orders will show in this table</div>
          </div> */}
        </div>
      </Container>
    </div>
  );
};

export default FinancialReports;
