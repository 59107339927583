import Skeleton from '@mui/material/Skeleton';

export default function DashboardSkeleton({ type, page }) {
  return (
    <div className="dashboardskeleton">
      {type == 1 ? <>
        {page == "finance" ? null : <div className='titleicon'>
          <Skeleton variant="text" className='maintitle' />
          <Skeleton variant="circular" className='usericon' />
        </div>}
        <Skeleton variant="text" className='sectiontitle' />
        {/* <Skeleton variant="circular" width={40} height={40} /> */}
        <div className='boxsection'>
          <Skeleton variant="rectangular" className="boxes" />
          <Skeleton variant="rectangular" className="boxes" />
          <Skeleton variant="rectangular" className="boxes" />
          <Skeleton variant="rectangular" className="boxes" />
        </div>

        {page == "finance" ? null : <> <Skeleton variant="text" className='sectiontitle' />
          <div className='boxsection'>
            <Skeleton variant="rectangular" className="boxes" />
            <Skeleton variant="rectangular" className="boxes" />
            <Skeleton variant="rectangular" className="boxes" />
            <Skeleton variant="rectangular" className="boxes" />
          </div></>}
      </>
        :
        <div className='boxsection'>
          <Skeleton variant="rectangular" className="gboxes" />
          <Skeleton variant="rectangular" className="gboxes" />

        </div>}
    </div>
  );
}