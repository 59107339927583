import React from 'react';

const SubHeading = ({ title }) => {
  return (
    <>
      <div className="sub-heading trending-heading"> {title}</div>
    </>
  );
};

export default SubHeading;