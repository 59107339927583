import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useFormik } from "formik";
import * as React from "react";
import * as Yup from "yup";
import DarkBtn from "../../universal/Buttons/DarkBtn";
import LightBtn from "../../universal/Buttons/LightBtn";
import SubHeading from "../../universal/Headings/SubHeading";
import InputTypeOutline from "../../universal/InputType/inputTypeOutline";

export default function WelcomeAdminDialog({
  updatePassword,
  setLoading,
  loading,
  passwordModal,
  setPasswordModal,
}) {
  // const [open, setOpen] = React.useState(false);
  const [isPasswordShow, setIsPasswordShow] = React.useState(true);
  const [isConfirmPasswordShow, setIsConfirmPasswordShow] =
    React.useState(true);

  const handleClickOpen = () => {
    setPasswordModal(true);
  };

  const handleClose = () => {
    setPasswordModal(false);
    setLoading(false);
    formik.handleReset();
  };

  // formik start
  const initialValues = {
    currentPassword: "",
    newPassword: "",
  };
  const validationSchema = Yup.object({
    currentPassword: Yup.string().required("Current password is required."),
    newPassword: Yup.string()
      .required("New password is required.")
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        "Use 8 or more characters with a mix of letters, numbers & symbols"
      ),
  });
  const onSubmit = async (e) => {
    await updatePassword(e);
    setLoading(false);
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  React.useEffect(() => {
    formik.handleReset();
  }, [passwordModal === false]);

  return (
    <div>
      {/* <LightBtn onClick={handleClickOpen}/> */}
      <Button
        variant="contained"
        className="lightbtn"
        onClick={handleClickOpen}
      >
        Update
      </Button>
      <Dialog
        open={passwordModal}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="updatepassword"
      >
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <SubHeading title="Update Password " />
            <div className="uploadpara">
              Make sure you password contains at least one number or symbol and
              is ideally at least 8 characters long.
            </div>
            <div className="tracking-text "> Current password</div>
            <InputTypeOutline
              password
              id="currentPassword"
              type={isPasswordShow ? "password" : "text"}
              name={"currentPassword"}
              onChange={formik.handleChange}
              value={formik.values.currentPassword}
              error={formik.errors.currentPassword}
              isPasswordShow={isPasswordShow}
              setIsPasswordShow={setIsPasswordShow}
            />

            <div className="tracking-text ">New password</div>
            <InputTypeOutline
              password
              id="newPassword"
              type={isConfirmPasswordShow ? "password" : "text"}
              name={"newPassword"}
              onChange={formik.handleChange}
              value={formik.values.newPassword}
              error={formik.errors.newPassword}
              isPasswordShow={isConfirmPasswordShow}
              setIsPasswordShow={setIsConfirmPasswordShow}
            />
            <div className="btn-section">
              <LightBtn title="Cancel" onClick={handleClose} />
              <DarkBtn
                title="Save"
                type="submit"
                disable={loading ? true : false}
                loading={loading}
              />
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
}
