import React from 'react'
import Lightbtn from "../../components/universal/Buttons/LightBtn";
const NoInternet = () => {
  return (
    <div className='nointernet'>
    <h1>Check your connection and try again.</h1>   
    <Lightbtn title="Retry"/> 
    </div>
  )
}

export default NoInternet