import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { Checkbox, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';

import UploadImage from '../../../components/dialogs/UploadImage';
import ProductImageSkeleton from '../../../components/Skeleton/ProductImageSkeleton';
import DarkBtn from '../../../components/universal/Buttons/DarkBtn';
import LightBtn from '../../../components/universal/Buttons/LightBtn';
import CardPagination from '../../../components/universal/CardPagination';
import { Empty } from '../../../components/universal/Empty';
import MainHeading from '../../../components/universal/Headings/MainHeading';
import ProductsCard from '../../../components/universal/ProductsCard';
import SearchBar from '../../../components/universal/SearchBar';
import sweatAlertBox from '../../../components/universal/SweetAlertBox';
import ToolTip from '../../../components/universal/ToolTip';
import { notify } from '../../../core/constants';
import { axiosAll } from '../../../services/axiosAll';
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const ProductImages = () => {
  var converter = require('number-to-words');
  const [productimages, setProductImages] = useState();
  const [selectedProduct, setSelected] = useState(0);
  const [searchProduct, setSearch] = useState();
  const [bulkDeleteToggle, setBulkDeleteToggle] = useState(false);
  const [Loading, setLoading] = useState(true);
  const [page, setPage] = React.useState(1);
  const [imageSize, setImageSize] = useState();
  const [isImageUploading, setIsImageUploading] = useState(false);
  const [bulkDeleteData, setBulkDeleteData] = useState({
    ids: [],
  });
  const [uploadImage, setUploadImage] = useState([]);

  const handlePagination = (event, value) => {
    setPage(value);
  };

  const getProductImage = async (searchProduct) => {
    const response = await axiosAll({
      method: 'get',
      url: `v2/galleries?search=${
        searchProduct ? searchProduct : ''
      }&page=${page}&limit=12`,
    });
    if (response?.statusCode == 200) {
      setProductImages(response?.result);
      if ((page > 1) & (response?.result?.data?.length === 0)) {
        setPage(page - 1);
      }
    }
    setLoading(false);
  };

  const UploadMultiImage = async () => {
    setIsImageUploading(true);
    for (let index = 0; index < uploadImage.length; index++) {
      const element = uploadImage[index];
      const formData = new FormData();
      formData.append('image', element);
      formData.append(
        'name',
        element?.editName ? element?.editName : element?.name
      );

      const response = await axiosAll({
        method: 'post',
        url: `v2/galleries`,
        data: formData,
      });
      if (response?.statusCode == 200) {
        setProductImages(response?.result);
        setPage(1);
      }
    }
    getProductImage();
    setUploadImage([]);
    setLoading(false);
    setIsImageUploading(false);
  };

  const handleDelete = async (id) => {
    const response = await axiosAll({
      method: 'delete',
      url: `v2/galleries/${id}`,
    });
    if (response?.statusCode === 200) {
      notify(response.message, 'success');
    } else {
      notify(response.message, 'error');
    }
    getProductImage();
  };

  const handleBulkDelete = async (id) => {
    const response = await axiosAll({
      method: 'delete',
      url: `v2/bulk-galleries`,
      data: bulkDeleteData,
    });
    if (response?.statusCode === 200) {
      notify(response.message, 'success');
    } else {
      notify(response.message, 'error');
    }
    setSelected(0);
    setBulkDeleteToggle(false);
    getProductImage();
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearch(value);
    setPage(1);
  };

  const handleCheckbox = (id, e) => {
    const { checked } = e.target;

    if (checked) {
      setSelected((prevState) => {
        return prevState + 1;
      });
      setBulkDeleteData({
        ids: [...bulkDeleteData.ids, id],
      });
    } else {
      setSelected((prevState) => {
        return prevState - 1;
      });
      setBulkDeleteData({
        ids: bulkDeleteData.ids.filter((item) => item !== id),
      });
    }

    getProductImage(searchProduct);
  };

  useEffect(() => {
    var result = uploadImage.reduce(function (sum, item) {
      return (sum = sum + item.size);
    }, 0);
    setImageSize(result);
  }, [uploadImage]);

  useEffect(() => {
    getProductImage(searchProduct);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchProduct, page]);

  return (
    <div className="productimages">
      <div className="product-images-container">
        {/* <Container className="all-container-27inch"> */}
        <Container className="all-container-27inch">
          <MainHeading title="Product Images" />
          <div className="seller-para">
            This is where you can view and upload photos of your products. When
            bulk uploading products you need to upload your images here and
            reference the link in your CSV.
          </div>
          {Loading ? (
            <ProductImageSkeleton type={2} />
          ) : (
            <div className={bulkDeleteToggle ? 'flexwidth' : 'search-row'}>
              <div className={bulkDeleteToggle ? 'selected ' : ''}>
                {bulkDeleteToggle ? (
                  `${selectedProduct} selected`
                ) : (
                  <SearchBar
                    handleChange={handleSearch}
                    value={searchProduct}
                  />
                )}
              </div>
              {productimages?.data?.length > 0 ? (
                <div>
                  {bulkDeleteToggle ? (
                    <>
                      <LightBtn
                        title="Cancel"
                        onClick={() => {
                          setBulkDeleteToggle(false);
                          setSelected(0);
                          setBulkDeleteData({
                            ids: [],
                          });
                        }}
                      />{' '}
                      <DarkBtn
                        title="Delete "
                        onClick={() => {
                          if (selectedProduct > 0) {
                            sweatAlertBox(
                              'Are You Sure?',
                              `You are about to delete ${converter
                                .toWords(selectedProduct)
                                ?.bold()} ${
                                selectedProduct === 1 ? 'image' : 'images'
                              }. You cannot undo this action once confirmed.`,
                              'Confirm',
                              'Cancel',

                              handleBulkDelete
                            );
                          } else {
                            notify('Please select atleast one image', 'error');
                          }
                        }}
                      />
                    </>
                  ) : (
                    ''
                  )}
                </div>
              ) : null}
              {bulkDeleteToggle === false ? (
                <div className="btn-part">
                  {productimages?.data?.length > 0 ? (
                    <LightBtn
                      title="Bulk delete"
                      onClick={() => setBulkDeleteToggle(true)}
                    />
                  ) : null}
                  {/* <DarkBtn title=" Add Images "  /> */}
                  <UploadImage
                    setPage={setPage}
                    uploadImage={uploadImage}
                    setUploadImage={setUploadImage}
                    UploadMultiImage={UploadMultiImage}
                    imageSize={imageSize}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          )}
          {Loading || isImageUploading ? (
            <ProductImageSkeleton type={1} />
          ) : (
            <>
              {productimages?.data?.length > 0 ? (
                productimages?.data?.map((productImg, index) => {
                  return (
                    <div className="img-container" key={index}>
                      <ProductsCard
                        image={productImg?.url}
                        name={productImg?.name}
                        copyUrl={productImg?.url}
                        id={productImg?.id}
                        isImages
                      />
                      {bulkDeleteToggle ? (
                        <Checkbox
                          className="checkbox-style"
                          {...label}
                          checkedIcon={<CheckCircleIcon />}
                          checked={
                            bulkDeleteData.ids.includes(productImg?.id)
                              ? true
                              : false
                          }
                          value={productImg?.id}
                          icon={<RadioButtonUncheckedIcon />}
                          onChange={(e) => handleCheckbox(productImg?.id, e)}
                        />
                      ) : (
                        <ToolTip
                          name={productImg?.name}
                          productId={productImg?.id}
                          handleDelete={handleDelete}
                          id={productImg?.id}
                        />
                      )}
                    </div>
                  );
                })
              ) : (
                <Empty message="Uploaded images will be displayed here. Click the Add images button to get started" />
              )}

              {productimages?.total > 12 ? (
                <CardPagination
                  noOfPages={productimages?.lastPage}
                  page={page}
                  handleChange={handlePagination}
                  rowsPerPage={12}
                />
              ) : (
                ''
              )}
            </>
          )}
        </Container>
      </div>
    </div>
  );
};

export default ProductImages;
