import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { images } from '../../../assets';
import { useDispatch } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { deleteLogin } from '../../../redux/actions/login';
import DehazeIcon from '@mui/icons-material/Dehaze';
export default function SellerMobileMenu() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const dispatch = useDispatch();
  const nav = useNavigate();
  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
      <Link to="seller" >
          <ListItem >
            <ListItemButton>
              <ListItemIcon>
              <img src={images.dashboard} className="new-sidebar" />
              </ListItemIcon>
              <ListItemText>
              Dashboard
              </ListItemText>
            </ListItemButton>
          </ListItem>
          </Link>
          <Divider />
          <Link to="product-images" >
          <ListItem >
            <ListItemButton>
            <ListItemIcon>
            <img src={images.gallery} className="new-sidebar" />
              </ListItemIcon>
            
              <ListItemText>
              Product Images
              </ListItemText>
            </ListItemButton>
          </ListItem>
          </Link>
          <Divider />
          <Link to="products" >
          <ListItem >
            <ListItemButton>
            <ListItemIcon>
            <img src={images.book} className="new-sidebar" />
              </ListItemIcon>
            
              <ListItemText>
              Products
              </ListItemText>
            </ListItemButton>
           
          </ListItem>
          </Link>
          <Divider />
          <Link to="orders" >
          <ListItem >
            <ListItemButton>
            <ListItemIcon>
            <img src={images.basket} className="new-sidebar" />
              </ListItemIcon>
           
              <ListItemText>
              Orders
              </ListItemText>
            </ListItemButton>
        
          </ListItem>
          </Link>
          <Divider />
          <Link to="financial-reports" >
          <ListItem >
      <ListItemButton>
            <ListItemIcon>
            <img src={images.basket} className="new-sidebar" />
              </ListItemIcon>
            
              <ListItemText>
              Financial Reports
              </ListItemText>
            </ListItemButton>
          </ListItem>
          </Link>
          <Divider />
          <Link to="my-profile" >
          <ListItem >
            <ListItemButton>
            <ListItemIcon>
            <img src={images.user} className="new-sidebar" />
              </ListItemIcon>
           
              <ListItemText>
              My Profile
              </ListItemText>
            </ListItemButton>
          </ListItem>
          </Link>
          <Divider />
          <ListItem >
          <div
              onClick={() => {
                dispatch(deleteLogin());
                //   dispatch(deleteUser());
                nav('/login');
              }}
              className="item2"
            >
            <ListItemButton>
            <ListItemIcon>
            <img src={images.signOut} className="new-sidebar" />
              </ListItemIcon>
            
              <ListItemText>
              Logout
              </ListItemText>
            </ListItemButton>
            </div>
          </ListItem>
      </List>
      {/* <Divider />
      <List>
        
          <ListItem>
            <ListItemButton>
              <ListItemText>
                <DarkBtn title='Login'/>
              </ListItemText>
            </ListItemButton>
          </ListItem>
       
      </List> */}
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            {/* <img src={images.menuIcon} /> */}
            <DehazeIcon/>
            </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
