import { Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { useFormik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import PlaceholderImage from "../../../assets/images/staticpage/placeholder.jpg";
import OnOffSwitch from '../../../components/universal/OnOffSwitch';
import { notify } from "../../../core/constants";
import { axiosAll } from "../../../services/axiosAll";
import PendingBtn from "../../universal/Buttons/PendingBtn";
import FileUpload from "../../universal/FileUpload";
import InputTypeOutline from "../../universal/InputType/inputTypeOutline";
import TextArea from "../../universal/InputType/TextArea";

export default function AddSubsectionDialog({
  subsectionValue,
  getSubSection,
  
}) {
  const [open, setOpen] = React.useState(false);
  const [squreImage, setSqureImage] = useState();
  const [loading, setLoading] = useState(false);
  const [visibilitySubsection, setVisibility] = useState(false);
  const initialValues = {
    squareTitle: "",
    squareDescription: "",
  };

  const validationSchema = Yup.object({
    squareTitle: Yup.string().required("squre tittle is required."),
    squareDescription: Yup.string().required("squre description is required."),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSqureImage("");
    setVisibility(false)
  };

  const handlesectionlist=()=>{
    return getSubSection(subsectionValue);
  }


  const AddSubSection = async (values) => {
    if (values?.squareTitle && values?.squareDescription && squreImage) {
      const formData = new FormData();
      formData.append(
        "squareTitle",
        values?.squareTitle ? values?.squareTitle : ""
      );
      formData.append(
        "squareDescription",
        values?.squareDescription ? values?.squareDescription : ""
      );
      formData.append("squareImage", squreImage ? squreImage : "");
      formData.append("sectionId", subsectionValue);
     formData.append("status", visibilitySubsection === true ? 1 : 0);

      setLoading(true);
      const response = await axiosAll({
        method: "post",
        url: `v2/subsections`,
        data: formData,
      });
      if (response?.statusCode == 200) {
        notify(response.message, "success");
        formik.setFieldValue("squareTitle", "");
        formik.setFieldValue("squareDescription", "");
        setLoading(false);
        handlesectionlist();
        handleClose();
      } else {
        notify(response.message, "error");
      }
    } else notify("please fill all data", "error");
  };

  const onSubmit = (values) => {
    AddSubSection(values);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="AddSubsection">
      <Button className="pendingbtn" onClick={handleClickOpen}>
        Add SubSection
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="add-subsection"
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <div className="input-heading">
                <h3 className="upload-text">SubSection Details</h3>

                {/* <div className="pro-seller"> */}
                <div className="checkout">
                  <label className="label-text">Square Title</label>
                  <InputTypeOutline
                    name={"squareTitle"}
                    onChange={formik.handleChange}
                    value={formik.values.squareTitle}
                    error={formik.errors.squareTitle}
                  />
                </div>
                <div className="heading">
                  <label className="label-text">Square Description</label>
                  <TextArea
                    onChange={formik.handleChange}
                    value={formik.values.squareDescription}
                    error={formik.errors.squareDescription}
                    name={"squareDescription"}
                  />
                </div>
                <div>
                  <FileUpload
                    title={"choose file"}
                    forId={"SqureImageAdd"}
                    id={"SqureImageAdd"}
                    accept={"image/png,image/jpeg,image/jpg"}
                    onChange={(e) => {
                      setSqureImage(e.target.files[0]);
                    }}
                  />
                  <div className="product-img">
                    {squreImage ? (
                      <img src={URL.createObjectURL(squreImage)} />
                    ) : (
                      <img src={PlaceholderImage} />
                    )}
                  </div>
                </div>
                 <Grid item xs={12} sm={6}  className="switchuifixed">
                <label className="label-text">Visibility</label>
              <OnOffSwitch value={visibilitySubsection} onChange={(e) => setVisibility(e.target.checked) } />
             </Grid>
                <div className="btn">
                  <PendingBtn
                    type="submit"
                    title="Add SubSection"
                    loading={loading}
                  />
                </div>
                {/* </div> */}
              </div>
            </DialogContentText>
          </DialogContent>
        </form>
      </Dialog>
    </div>
  );
}
