import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
const InputTypeOutline = ({
  placeholder,
  onChange,
  name,
  type,
  error,
  value,
  onBlur,
  password,
  isPasswordShow,
  setIsPasswordShow,
  disabled,
  max,  
  ...props
}) => {
  return (
    <div className="input-width">
      <input
        name={name}
        type={type}
        placeholder={placeholder}
        className="input-field-outline"
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        maxLength={max}
        {...props}
      />
      {password && (
        <span
          className="visibility-icon"
          onClick={() => setIsPasswordShow(!isPasswordShow)}
        >
          {isPasswordShow ? <VisibilitySharpIcon /> : <VisibilityOffIcon />}
        </span>
      )}
      <div>{error && <div className="input-error-message">{error}</div>}</div>
    </div>
  );
};

export default InputTypeOutline;
