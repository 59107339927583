import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import * as React from 'react';
import { notify } from '../../../core/constants';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import SubHeading from '../../universal/Headings/SubHeading';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';

export default function WelcomeAdminDialog({
  userData,
  handleChange,
  updateUserData,
  loading,
  setLoading,
  getProfile,
  setOpenBusinessDetailsDialog,
  openBusinessDetailsDialog,
}) {
  const handleClickOpen = () => {
    setOpenBusinessDetailsDialog(true);
  };

  const handleClose = () => {
    setOpenBusinessDetailsDialog(false);
    setLoading(false);
    getProfile();
  };

  return (
    <div>
      {/* <LightBtn onClick={handleClickOpen}/> */}
      <Button
        variant="contained"
        className="lightbtn"
        onClick={handleClickOpen}
      >
        Update
      </Button>
      <Dialog
        open={openBusinessDetailsDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="updatebusinessdetails"
      >
        <DialogContent>
          <SubHeading title="Update Business Details " />
          <div className="tracking-text "> Business name</div>
          <InputTypeOutline
            name={'businessName'}
            onChange={handleChange}
            value={userData?.businessName}
          />

        
          <div className="tracking-text ">Company registration number</div>
          <InputTypeOutline
            name={'registrationNumber'}
            onChange={handleChange}
            value={userData?.registrationNumber}
          />

          <div className="tracking-text ">VAT registration number</div>
          <InputTypeOutline
            disabled={true}
            // name={"bio"}
            // onChange={handleChange}
            // value={userData?.bio}
          />
          <div className="update-text">
            To update or add a VAT number, please contact support@goethical.com
          </div>
          <div className="tracking-text ">Business email</div>
          <InputTypeOutline
            name={'businessEmail'}
            onChange={handleChange}
            value={userData?.businessEmail}
          />
          <div className="tracking-text ">Business Phone</div>
          <InputTypeOutline
            type={'number'}
            name={'businessPhoneNumber'}
            onChange={handleChange}
            value={userData?.businessPhoneNumber}
            max="15"
          />
          <div className="tracking-text ">Business Address</div>
          <InputTypeOutline
            name={'businessAddress'}
            onChange={handleChange}
            value={userData?.businessAddress}
            required={true}
          />
  
          <div className="btn-section">
            <LightBtn title="Cancel" onClick={handleClose} />
            <DarkBtn
              title="Save"
              type="submit"
              onClick={()=>userData?.businessAddress?.length===0||userData?.businessEmail?.length===0?notify(`Business ${userData?.businessEmail?.length===0?'email':'address'} is required`,'error'):updateUserData()}
              disabled={loading ? true : false}
              loading={loading}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
