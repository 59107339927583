import Tooltip from '@mui/material/Tooltip';
import { useState } from 'react';
import { images } from '../../../assets';
import ToolTip from '../ToolTip';

const ProductsCard = ({
  image,
  name,
  price,
  actual_price,
  copyUrl,
  handleDelete,
  id,
  handleEdit,
  isAddedToCart,
  isAddedToWishlist,
  isPublished,
  stockStatus,
  type,
  isImages,
  isProducts,
}) => {
  const [copyText, setCopyText] = useState('Copy image link to clipboard');
  return (
    <div className="productscard">
      {isPublished === 0 || stockStatus === 0 ? (
        <img src={image} className="img-row img-opacity" alt="product" />
      ) : (
        <img src={image} className="img-row " alt="product" />
      )}
      <div className="product-text-section">
        <div>
          <p className="product-text">{name}</p>
          {price === actual_price ? (
            <div className="product-price">
              {' '}
              £{price ? price?.toFixed(2) : null}{' '}
            </div>
          ) : (
            <div className="product-price">
              £{price ? price?.toFixed(2) : null}{' '}
              <span className="discount-price">
                {' '}
                £{actual_price ? actual_price?.toFixed(2) : null}{' '}
              </span>
            </div>
          )}
        </div>
        <div>
          {type === 'add_product' ? null : (
            <Tooltip
              title={copyText}
              placement="right"
              onClick={() => {
                navigator.clipboard.writeText(copyUrl);
                setCopyText('Copied to clipboard');
                setTimeout(() => {
                  setCopyText('Copy image link to clipboard');
                }, 2000);
              }}
            >
              <img src={images.copy} className="copy-icon" alt="copy" />
            </Tooltip>
          )}
        </div>
        <div className="icon-row">
          <div className="iconhover">
            <ToolTip
              handleDelete={handleDelete}
              id={id}
              name={name}
              isAddedToCart={isAddedToCart}
              isAddedToWishlist={isAddedToWishlist}
              isProducts={isProducts}
              isImages={isImages}
            />
            <Tooltip
              title="Edit Product"
              placement="right"
              onClick={handleEdit}
            >
              <img src={images.edit} className="edit-icon" alt="edit" />
            </Tooltip>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ProductsCard;
