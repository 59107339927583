import React from 'react'
import { images } from '../../../assets';
import DrakBtn from "../../../components/universal/Buttons/DarkBtn";

export const BuyerIdDetails = () => {
  return (

    <div className='BuyerIdDetails'>
        
        <div className='user-heading'>User Detail</div>
       
       <div className='profile-detail'>
       <h3 className='email'>Email :</h3>
       <p className='mail-text'>kopal002@yopmail.com</p>
       <h3 className='email'>name</h3>
       <p className='mail-text'>kopal gupta</p>
       <h3 className='email'> Phone Number :</h3>
      
       <div>
       <h3 className='email'> Profile Picture :</h3>
       <div className='img-card'>
       <img src={images.profile} alt="" className='profile-img'/>
       </div>
           
       </div>
       <div>
       <h3 className='email'> Badge :</h3>
       <div className='img-card'>
       <img src={images.profile} alt="" className='profile-img'/>
       </div>
       </div>
       <h3 className='email'> Addresses :</h3>
       <div className='mail-text'>
           <li>GoEthical - GoEthical marketplace Ltd - Kemp House, 152-160 City Road, London, EC1V 2NX</li>
       </div>
       <h3 className='email'> Active Status :</h3>
           <p className='active-text'>Active</p>
           <h3 className='email'> Average Rating :</h3>
           <p className='mail-text'>0</p>
           <h3 className='email'>User Name :</h3>
           <p className='mail-text'>kopalgupta5008</p>
           <h3 className='email'>Bio :</h3>
           <h3 className='email'>Followers Count :</h3>
              <p className='mail-text'>0</p>
              <h3 className='email'>Followers Count :</h3>
              <p className='mail-text'>0</p>
              <h3 className='email'>Upvote Counts On Selling Products :</h3>
              <p className='mail-text'>0</p>
              <h3 className='email'>Profile Completion Status :</h3>
              <p className='mail-text'>Completed</p>
              <h3 className='email'>Card Addition Status :</h3>
              <p className='mail-text'>Added</p>

              <h3 className='email'>Bank Account Addition Status :</h3>
              <p className='mail-text'>Not Added</p>

              <h3 className='email'>Bank Account Verification Status :</h3>
              <p className='mail-text'>Not Verified</p>
              <div>
                  <DrakBtn title="Back"/>
              </div>
       </div>
    </div>

  )
}
export default BuyerIdDetails;