//import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
import Pagination from '@material-ui/lab/Pagination';

export default function CardPagination({
  page,
  noOfPages,
  handleChange,
  rowsPerPage,
}) {
  return (
    <div className="pagination">
      <div>Items per page :{rowsPerPage}</div>
      <Pagination
        count={noOfPages}
        page={page}
        onChange={handleChange}
        defaultPage={1}
        size="small"
      />
    </div>
  );
}
