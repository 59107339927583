import React from 'react';
import EditIcon from '@mui/icons-material/Edit';

const EditIcons = () => {
  return (
    <div>
         <EditIcon  className='editicon'/>
    </div>
  )
}

export default EditIcons