import DvrIcon from '@mui/icons-material/Dvr';
import MenuIcon from '@mui/icons-material/Menu';
import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { images } from '../../../../assets';
import { deleteLogin } from '../../../../redux/actions/login';
import { deleteUser } from '../../../../redux/actions/user';
import AdminSidebarMobile from './AdminSidebarMobile';
// import SellerMobileMenu from '../../../pages/Seller/SellerMobileMenu';
// import { deleteLogin } from '../../../redux/actions/login';

const AdminSidebar = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();
  const [show, setShow] = useState(true);
  return (
    <>
      <div className="adminsidebar">
        {/* <div className='mobileshow'> */}
        <Container className='all-container-27inch container-27-admin'>
          <div className='mobileviewset'>
            {/* <img src={images.mainLogo} className="logogoethical" /> */}
            {/* <TemporaryDrawer /> */}
            {/* </div> */}
          </div>
        </Container>
        <div className="icon">
          <Container className='all-container-27inch icon container-27-admin'>
            <div className="menu-icon mobilehide">
              <MenuIcon onClick={() => setShow(!show)} />
            </div>
            <div className="menu-icon mobileshow">
              <AdminSidebarMobile />
            </div>
            <div className="dvr-icon">
              <DvrIcon />
            </div>
            <div className="component-name">
              <h3>Dashboard</h3>
            </div>
          </Container>
        </div>
      </div>
      <div className='bgcoloradmin'>
        <Container className='all-container-27inch container-27-admin'>
          {
            show ? <Grid container spacing={2}><Grid item xs={12} sm={2.5} md={2} xl={2.5} className="mobilehide">
              <div className="adminsidebar">
                {/* <img src={images.mainLogo} className="logogoethical" /> */}
                <Link to="user-registration" className="item2">
                  <div className="sidebar-menu-name">Pro Seller Requests</div>
                </Link>
                <Link to="trending" className="item2">
                  <div className="sidebar-menu-name">Trending Products</div>
                </Link>
                <Link to="featured-section" className="item2">
                  <div className="sidebar-menu-name">Featured Section</div>
                </Link>
                
                <Link to="brand-with-cause" className="item2">
                  <div className="sidebar-menu-name">Brands with Cause</div>
                </Link>
                <Link to="seller" className="item2">
                  <div className="sidebar-menu-name"> Sellers</div>
                </Link>
                <div
                  onClick={() => {
                    dispatch(deleteLogin());
                    dispatch(deleteUser());
                    nav('/login');
                    localStorage.removeItem('oldData');

                  }}
                  className="item2" >
                  <div className="sidebar-menu-name">Logout</div>
                </div>
              </div>
            </Grid><Grid item xs={12} sm={9.5} md={10} xl={9.5} className="diffbgcolor">
                <Outlet />
              </Grid></Grid> : null
          }
        </Container>
        {
          !show ? <Grid container spacing={2}><Grid item xs={12} sm={12} className="diffbgcolor">
            <Container className='all-container-27inch container-27-admin'>
              <Outlet />
            </Container>
          </Grid> </Grid> : null
        }
      </div>
    </>
  );
};
export default AdminSidebar;