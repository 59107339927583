import axios from "axios";
import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate, useParams } from "react-router";
import { images } from "../../assets";
import DarkBtn from "../../components/universal/Buttons/DarkBtn";
import LightBtn from "../../components/universal/Buttons/LightBtn";
import ProgressBar from "../../components/universal/ProgressBar";
import { baseUrlV1, notify } from "../../core/constants";
import { store } from "../../redux/store";
import { AxiosAll } from "../../services/axiosAll";

const VerifyYourEmail = () => {
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const nav = useNavigate();
  const [isVerified, setIsVerified] = useState("");
  const { id } = useParams();
  const { token } = useParams();
  const [userDetails, setUserDetails] = useState("");
  const [loading, setLoading] = useState(false);

  const resendVerification = async () => {
    setOtp("");
    try {
      const res = await AxiosAll(
        "post",
        `user/resend-link?`,
        { email: `${userDetails?.email}` },
        `${token}`
      );
      notify(res.data.message, "success");
    } catch (error) {
      console.log(error);
    }
  };
  const otpVerify = async (data) => {
    setLoading(true);
    try {
      const res = await AxiosAll(
        "post",
        `user/email-verification?`,
        data,
        `${token}`
      );
      if (res.data.status == "Error") {
        notify(res.data.message, "error");
        setLoading(false);
      } else {
        notify(res.data.message, "success");
        setLoading(false);
        nav(`/store-details/${id}/${token}`);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const getUserData = async () => {
    const state = store.getState();
    const { guestDetails } = state.guestReducer;
    try {
      const res = await axios({
        method: "get",
        url: `${baseUrlV1}user/${id}??deviceType=3&deviceToken=${guestDetails?.token}`,
        headers: { "Content-Type": "application/json", userToken: `${token}` },
      });
      setUserDetails(res?.data?.result?.user);
      setIsVerified(res?.data?.result?.user?.isVerified);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  // func  for varification
  const handleSendForm = () => {
    if (otp.length < 4) {
      setError(true);
    } else if (otp.length == 4) {
      setError(false);
      const data = {
        token: otp,
      };
      otpVerify(data);
      setOtp("");
    }
  };
  useEffect(() => {
    getUserData();
  }, []);
  useEffect(() => {
    if (otp.length == 4) {
      setDisableButton(false);
    }
  }, [otp]);
  return (
    <>
      <ProgressBar value={40} />

      {/* <PublicHeader /> */}
      {isVerified == 1 ? (
        <>
          <div className="verifyyouremail">
            <div>You are already verified!</div>
            <div>Please login...</div>
          </div>
        </>
      ) : (
        <>
          <div className="verifyyouremail">
            <img
              src={images.verify}
              alt="goEthical logo"
              className="logo-img"
            />
            <div className="text-heading">Verify Your Email</div>
            <div className="text-para">
              {" "}
              We’ve emailed you a code. Enter the code to verify your email{" "}
              {userDetails?.email}
            </div>
            <form onSubmit={handleSubmit}>
              <OtpInput
                containerStyle={{
                  justifyContent: "center",
                }}
                inputStyle={{
                  margin: "5px",
                  textAlign: "center",
                  fontSize: "26px",
                  fontFamily: "FuturaPTBold",
                  width: "48px",
                  height: "78px",
                  borderRadius: "8px",
                  borderColor: "#949D8C",
                  borderWidth: "1px",
                  borderStyle: "solid",
                }}
                isInputNum={true}
                value={otp}
                onChange={(otp) => {
                  if (otp.length == 4) {
                    setError(false);
                  } else {
                    setError(true);
                  }
                  setOtp(otp);
                }}
                numInputs={4}
              />
              {error ? (
                <div className="ErrorMessage">Otp is required</div>
              ) : null}
              <div>
                <div className="code-text">
                  Didn’t receive a code?
                  <span
                    className="resend-text"
                    onClick={() => resendVerification()}
                  >
                    {" "}
                    Resend
                  </span>
                </div>
              </div>
              <div className="btn-section">
                <div>
                  <LightBtn
                    title="Back"
                    onClick={() => nav("/create-account/seller-registration")}
                  />
                </div>
                <div>
                  <DarkBtn
                    loading={loading}
                    title=" Continue "
                    disable={disableButton}
                    onClick={() => handleSendForm()}
                  />
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export default VerifyYourEmail;
