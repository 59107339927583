import { Container } from "@mui/material";
import React from "react";

const GiftVoucherTermAndCondition = () => {
    return (
        <div className="TermAndCondition">
            <Container className="all-container-27inch">
            <div className="heading1">
                VOUCHER TERMS:
                <span>
                    <strong> GoEthical vouchers can be purchased for use within the GoEthical app, the GoEthical.com Gift Voucher Terms </strong>
                </span>
            </div>
            <ol>
                <li>
                    <p className="para-heading">GoEthical.com gift vouchers can be used to purchase goods via the goethical.com app ( via Apple or google store)</p>
                </li>
                <li>
                    <p className="para-heading">Gift vouchers expire one year from the issue date</p>
                </li>
                <li>
                    <p className="para-heading">No VAT is charged when buying the gift voucher; however, applicable VAT will be charged for purchases paid for with a gift voucher.</p>
                </li>
                <li>
                    <p className="para-heading">The voucher code can be used multiple times until the voucher value is fully redeemed.</p>
                </li>
                <li>
                    <p className="para-heading">You will be required to provide the voucher number that appears on the voucher.</p>
                </li>
                <li>
                    <p className="para-heading">No other offers or discounts can be applied toward the purchase of an goethical.com gift voucher.</p>
                </li>
                <li>
                    <p className="para-heading">If you have to return goods originally purchased online using gift voucher(s), in most cases you will be given another voucher code for future purchases.</p>
                </li>
                <li>
                    <p className="para-heading">The cash value of a gift voucher is 0.001p.</p>
                </li>
                <li>
                    <p className="para-heading">Gift vouchers cannot be used to purchase other gift vouchers.</p>
                </li>
                <li>
                    <p className="para-heading">GoEthical.com is not responsible for lost or stolen gift vouchers.</p>
                </li>
            </ol>
            </Container>
        </div>
    );
};

export default GiftVoucherTermAndCondition;
