import CloseIcon from '@mui/icons-material/Close';
import { Fragment, useEffect, useState } from 'react';
import SellerSkeleton from '../../../components/Skeleton/SellerSkeleton';
import Table from '../../../components/universal/Admin/Table';
import PendingBtn from '../../../components/universal/Buttons/PendingBtn';
import FileUpload from '../../../components/universal/FileUpload';
import InputTypeOutline from '../../../components/universal/InputType/inputTypeOutline';
import TextArea from '../../../components/universal/InputType/TextArea';
import { notify } from '../../../core/constants';
import { axiosAll } from '../../../services/axiosAll';

const BrandWithCause = () => {
  const [section, setSection] = useState({});
  const [proSellers, setProSellers] = useState([]);
  const [brandWithCause, setBrandWithCause] = useState([]);
  const [sectionValues, setSectionValues] = useState({
    name: '',
    landscape_title: '',
    landscape_description: '',
  });
  const [sectionImage, setSectionImage] = useState('');
  const [isBrandWithCauseUpdated, setIsBrandWithCauseUpdated] = useState(false);
  const [page, setPage] = useState(0);
  const [isProSellerLoading, setIsProSellerLoading] = useState(false);
  const [isBrandWithCauseLoading, setIsBrandWithCauseLoading] = useState(false);

  const getSection = async () => {
    const res = await axiosAll({
      url: `v2/sections?cardId=4&tagId=`,
      method: 'get',
    });
    setSection(res.result.section);
    const data = res.result.section;
    if (data) {
      setSectionValues({
        id: data.id,
        name: data.squareTitle,
        landscape_title: data.landscapeTitle,
        landscape_description: data.landscapeDescription,
      });
      setSectionImage(data.image);
    }
  };

  const getProSellers = async () => {
    setIsProSellerLoading(true);
    const res = await axiosAll({
      url: `v2/proSellers`,
      method: 'get',
    });
    setProSellers(res.result.users);
    setIsProSellerLoading(false);
  };

  const getBrandWithCause = async () => {
    setIsBrandWithCauseLoading(true);
    const res = await axiosAll({
      url: `v2/brand_with_causes?page=${page + 1}`,
      method: 'get',
    });

    setBrandWithCause(res.result);

    setIsBrandWithCauseLoading(false);
  };

  useEffect(() => {
    getSection();
    getProSellers();
  }, []);

  useEffect(() => {
    getBrandWithCause();
  }, [isBrandWithCauseUpdated]);

  const updateSection = async (data) => {
    const res = await axiosAll({
      url: `v2/sections/${sectionValues.id}`,
      method: 'post',
      data,
    });
    // console.log('res', res);
    if (res.statusCode === 200) {
      notify(`${res.message}`, 'success');
    }
  };

  const handleUpdateSection = () => {
    if (sectionValues.name === '') {
      notify('Please enter section title', 'error');
    } else if (sectionValues.landscape_title === '') {
      notify('Please enter landscape title', 'error');
    } else if (sectionValues.landscape_description === '') {
      notify('Please enter landscape description', 'error');
    } else {
      const formData = new FormData();
      formData.append('name', sectionValues.name);
      formData.append('squareTitle', sectionValues.name);
      formData.append('squareDescription', '');
      formData.append('landscapeTitle', sectionValues.landscape_title);
      formData.append(
        'landscapeDescription',
        sectionValues.landscape_description
      );

      updateSection(formData);
    }
  };

  const getImages = async () => {
    const res = await axiosAll({
      url: `v2/sections/${sectionValues.id}/getImages`,
      method: 'get',
    });
    setSectionImage(res.result.image);
    if (res.result.images.length > 0) {
      setSectionImage(res.result.images);
    }
  };

  useEffect(() => {
    if (sectionValues.id) {
      getImages();
    }
  }, [sectionValues]);

  const addImage = async (e) => {
    const formData = new FormData();
    formData.append('image', e);

    const res = await axiosAll({
      url: `v2/sections/${sectionValues.id}/additionalImage`,
      method: 'post',
      data: formData,
    });
    getImages();
  };

  const deleteImage = async (id) => {
    const res = await axiosAll({
      url: `v2/sections/${id}/deleteImages`,
      method: 'delete',
    });
    getImages();

    if (res.status === 'Success') {
      notify(`${res.message}`, 'success');
    }
  };

  return (
    <div className="brand-With-Cause">
      {/* <div className="brand-container"> */}
      <div className="detail-heading">
        <h3> Section Details </h3>
      </div>
      <div className='alignitem'>
      <div className="input-field">
        <InputTypeOutline
          placeholder="Section Title"
          value={sectionValues.name}
          onChange={(e) =>
            setSectionValues({ ...sectionValues, name: e.target.value })
          }
        />
      </div>
      <div className="add-img">
        {sectionImage?.length > 0
          ? sectionImage?.map((image, index) => (
              <Fragment key={index}>
                <CloseIcon onClick={() => deleteImage(image.id)} />
                <img src={image.image} />
              </Fragment>
            ))
          : null}

        <FileUpload
        title={"Add Image"}
         accept={"image/png,image/jpeg,image/jpg"}
          onChange={(e) => {
            setSectionImage(e.target.files[0]);
            addImage(e.target.files[0]);
          }}
        />
      </div>
      </div>
      <div className="input-field">
        <label className="label-text">Landscape Title</label>
        <InputTypeOutline
          placeholder="Landscape Title"
          value={sectionValues.landscape_title}
          onChange={(e) =>
            setSectionValues({
              ...sectionValues,
              landscape_title: e.target.value,
            })
          }
        />
      </div>
      <div className="textarea">
        <label className="label-text">Landscape Description</label>
        <TextArea
          placeholder="Landscape Description"
          value={sectionValues.landscape_description}
          onChange={(e) =>
            setSectionValues({
              ...sectionValues,
              landscape_description: e.target.value,
            })
          }
        />
      </div>
      <div className="updated-section">
        <PendingBtn title="Update Section " onClick={handleUpdateSection} />
      </div>
      {isBrandWithCauseLoading && isProSellerLoading ? (
        <SellerSkeleton />
      ) : (
        <div>
          <Table
            data={brandWithCause}
            sellers={proSellers}
            setIsBrandWithCauseUpdated={setIsBrandWithCauseUpdated}
            isBrandWithCauseUpdated={isBrandWithCauseUpdated}
            page={page}
            setPage={setPage}
          />
        </div>
      )}
    </div>
  );
};

export default BrandWithCause;
