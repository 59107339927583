import React from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const CopyIcon = () => {
  return (
    <div>
         <ContentCopyIcon className='copyicon' />
    </div>
  )
}

export default CopyIcon