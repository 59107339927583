import * as React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

export default function DropDown({ name, handlechan, value, option, touched, blur, placholder, error }) {
  return (
    <div className="dropdown">
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            name={name}
            onChange={handlechan}
            id="demo-simple-select"
            value={value}
            className="select-field setupselectbox"
            onBlur={blur}
            displayEmpty
          >
            {placholder ? (
              <MenuItem value={0} disabled={true}>
                {placholder}
              </MenuItem>
            ) : null}
            {option &&
              option?.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={item?.id ? item?.id : item?.value}
                    disabled={item?.inSubsection ? item?.inSubsection : false}
                  >
                    {item?.title
                      ? item?.title
                      : item?.name
                      ? item?.name
                      : item?.squareTitle
                      ? item?.squareTitle
                      : item?.color
                      ? item?.color
                      : item?.processingTime
                      ? item?.processingTime
                      : item?.courierName
                      ? item?.courierName
                      : item?.courierClassName
                      ? item?.courierClassName
                      : item?.country
                      ? item?.country
                      : item?.size}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        {touched && error && <div className="input-error-message">{error}</div>}
        {value === "Yes" && touched && error && <div className="input-error-message">{error}</div>}
      </Box>
    </div>
  );
}

// <div className='dropdown'></div>
// <Box sx={{ minWidth: 120 }}>
//   <FormControl fullWidth>
//     <InputLabel id="demo-simple-select-label">Age</InputLabel>
//     <Select
//       labelId="demo-simple-select-label"
//       id="demo-simple-select"
//       label="Age"
//       className='select-field' >
//       <MenuItem value={10}>Ten</MenuItem>
//       <MenuItem value={20}>Twenty</MenuItem>
//       <MenuItem value={30}>Thirty</MenuItem>
//     </Select>
//   </FormControl>
// </Box>
