// import Progress from 'react-progressbar';

// const ProgressBar = ({ value }) => {
//   return (
//     <div className='progressbar'>
//       <Progress completed={value} />
//     </div>
//   )
// }

// export default ProgressBar;

import Progress from 'react-progressbar';

const ProgressBar = ({ value }) => {
  return (
    <div className='progressbar'>
      <Progress completed={value} />
    </div>
  )
}

export default ProgressBar;