import { LOGIN, LOGOUT, REMEMBER, REMOVE_LOGIN, UPDATE_INFO } from '../constant';
// action CRUD for Login data 
export const addLogin = (logindata) => {
  return( { type: LOGIN, data: logindata });
  };

export const deleteLogin = () => {
  return ({ type: LOGOUT });
  };

export const updateInfo = (data) => { 
  return dispatch =>{
    return dispatch({ type: UPDATE_INFO, data: data  }); 
  }
};

export const rememberLogin = (loginData) => {
  return (dispatch) => {
    return dispatch({ type: REMEMBER, data: loginData });
  }
}

export const removeLogin = (index) => {
  return (dispatch) => {
    return dispatch({ type: REMOVE_LOGIN, data: index });
  }
}