import { Backdrop, CircularProgress } from "@mui/material";

export const Loader = ({ open, message }) => {
  return (
    <Backdrop
      open={open}
      color="#E8DBCF"
      style={{ zIndex: "999999999999999999" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <CircularProgress size={100} style={{ color: "#949680" }} />
        {message && (
          <h4 style={{ marginTop: "16px", color: "#333" }}>
            {message}
          </h4>
        )}
      </div>
    </Backdrop>
  );
};
