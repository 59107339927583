import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DropDown from '../../universal/DropDown';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';
import LightBtn from '../../universal/Buttons/LightBtn';
import { notify } from '../../../core/constants';

export default function PublishUpdateDialog({
  errors,
  onSubmit,
  setType,
  isSaveLoading,
  isPublishLoading,
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (Object.keys(errors).length > 0) {
      Object.keys(errors).forEach((key, index) => {
        // only show first error message
        if (index === 0) {
          notify(errors[key], 'error');
        }
      });
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        className={`darkbtn ${
          Object.keys(errors).length === 0 ? 'singleproductbtndrk' : ''
        }`}
        type="button"
        onClick={handleClickOpen}
      >
        Save
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="publishupdate"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className="input-heading">
              <h2 className="upload-text">Do you want to publish updates?</h2>
              <div className="label-text">
                Publish will push all updates to your public store. Saved
                changes will not be pushed to your store, the updates will only
                be visible to you.
              </div>
              <div className="label-text">
                You can update this at any time from the individual product edit
                page.
              </div>
              <div className="btn-section-refund">
                <div>
                  <LightBtn title="Cancel" onClick={handleClose} />
                </div>
                <div className="twobtn">
                  <LightBtn
                    title="Save"
                    loading={isSaveLoading}
                    onClick={() => {
                      if (Object.keys(errors).length === 0) {
                        setType('save');
                        onSubmit();
                      }
                    }}
                  />
                  <DarkBtn
                    loading={isPublishLoading}
                    title="Publish"
                    onClick={() => {
                      if (Object.keys(errors).length === 0) {
                        setType('publish');
                        onSubmit();
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
