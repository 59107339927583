import { DELETE_USER, SET_USER, UPDATE_Id } from '../constant';
// Action CRUD for User information 
export const addUser = (userdata) => {
  return ({ type: SET_USER, data: userdata });
  }

export const deleteUser = () => {
  return ({ type: DELETE_USER });
  };
  
export const updateId = (userdata) => { 
    return({ type: UPDATE_Id, data: userdata });    
  };

