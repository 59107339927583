import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilitySharpIcon from "@mui/icons-material/VisibilitySharp";
import React from "react";
const InputType = ({
  type,
  placeholder,
  name,
  id,
  onChange,
  error,
  value,
  onBlur,
  isPassword,
  icon,
  password,
  isPasswordShow,
  setIsPasswordShow,
  min,
  ...props
}) => {
  return (
    <>
      <div className="icon-password">
        <input
          type={type}
          name={`${name}`}
          security={isPassword}
          id={`${id}`}
          placeholder={`${placeholder ? placeholder : ""}`}
          className="input-field-loginside"
          onBlur={onBlur}
          value={value}
          onChange={onChange}
          min={min}
          {...props}
        />
        {password && (
          <span
            className="visibility-icon"
            onClick={() => setIsPasswordShow(!isPasswordShow)}
          >
            {isPasswordShow ? <VisibilitySharpIcon /> : <VisibilityOffIcon />}
          </span>
        )}
        <div>
          {error && <span className="input-error-message">{error}</span>}
        </div>
      </div>
    </>
  );
};

export default InputType;
