import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { images } from '../../../../assets';

const ShippingDropDown = ({
  defaultSelect,
  name,
  onChange,
  value,
  options,
  error,
}) => {
  return (
    <div className="shippingselectbox">
    {/* <Box sx={{ minWidth: 120 }}> */}
      <FormControl fullWidth>
        <Select
         style={{
          backgroundImage: `url(${images.errowDown})`,
        }}
          labelId="demo-simple-select-label"
          name={name}
          onChange={onChange}
          id="demo-simple-select"
          value={value}
          className="select-field"
          defaultValue={defaultSelect?.value}
        >
          <MenuItem value="0" disabled selected>
            {defaultSelect?.name}
          </MenuItem>

          {options?.map((item, index) => {
            return (
              <MenuItem key={index} value={item.id}>
                {item?.processingTime
                  ? item?.processingTime
                  : item?.courierName
                  ? item?.courierName
                  : item?.courierClassName
                  ? item?.courierClassName
                  : item?.name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {error && <div>{error}</div>}
    {/* </Box> */}
    </div>
  );
};

export default ShippingDropDown;
