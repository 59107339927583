import { images } from "../../../assets"

export const Empty=({message,type})=>{
    return(
        <div className="empty-img">
            <img src={type==2?images?.verify : images?.emptyBasket} />
            <div className="chart-text">
             {message?message:'No data available'}
            </div>
        </div>
    )
}