import React from 'react'
import { images } from '../../../assets';
import DrakBtn from "../../../components/universal/Buttons/DarkBtn";
const OrderItemReport = () => {
  return (
    <div className='orderitemreport'>
        <div className='user-heading'>
        Order Item Report
        </div>
        <div className='profile-detail'>
       <h3 className='email'>Generated Order ID :</h3>
       <p className='mail-text'>Q3B6I7E5C9O1m</p>
       <h3 className='email'>Generated Product ID :</h3>
       <p className='mail-text'>O1F4U0J5P2A1O1</p>
       <h3 className='email'> Product Name:</h3>
       <p className='mail-text'>Ecover Surface Cleaner Multi-Action</p>

       <h3 className='email'> Shipping Type :</h3>
       <p className='mail-text'>Delivery</p>
       <h3 className='email'> Status:</h3>
       <p className='mail-text'>PENDING</p>
       <h3 className='email'> Item Price:</h3>
       <p className='mail-text'>2.79</p>
       <h3 className='email'>Total Order Price :</h3>
       <p className='mail-text'>5.29</p>
       <h3 className='email'> Seller:</h3>
       <p className='mail-text'>3639 - Ecover LTD</p>
       <h3 className='email'> Buyer:</h3>
       <p className='mail-text'>10104 - Jadhav Jn</p>
       <div>
       <h3 className='email'> Order Item Image :</h3>
       <div className='img-card'>
       <img src={images.product} alt="" className='profile-img'/>
       </div>
           
       </div>
       <h3 className='email'>Buyer's Order Address :</h3>
       <div className='mail-text'>
       Victoria St, Edinburgh EH1, UK Lofb UK 12345
       </div>
       <h3 className='email'>Latitude :</h3>
           <p className='mail-text'>28.457523</p>
           <h3 className='email'>Longitude :</h3>
           <p className='mail-text'>77.026344</p>
              <div>
                  <DrakBtn title="Back"/>
              </div>
       </div>
    </div>

      
  )
}

export default OrderItemReport

