import React from 'react';

const MainHeading = ({ title }) => {
  return (
    <>
      <div className="main-heading40 trending-heading"> {title}</div>
    </>
  );
};

export default MainHeading;