export const images = {
  mainLogo: require('../assets/images/sellerSide/goethical_logo.png'),
  facebook1: require('../assets/images/sellerSide/blackfacebook.webp'),
  instagram1: require('../assets/images/sellerSide/instagram.webp'),
  linkedin1: require('../assets/images/sellerSide/linkedin.webp'),
  twitter1: require('../assets/images/sellerSide/twitter.webp'),
  blog: require('../assets/images/sellerSide/email-text.png'),
  verify: require('../assets/images/sellerSide/verify.png'),
  upload: require('../assets/images/sellerSide/upload.png'),
  shop: require('../assets/images/sellerSide/shop.png'),
  welcome: require('../assets/images/sellerSide/welcome.png'),
  register: require('../assets/images/sellerSide/register-header-image.png'),
  dashboard: require('../assets/images/sidebar/dashboard.png'),
  book: require('../assets/images/sidebar/book.png'),
  basket: require('../assets/images/sidebar/basket.png'),
  financials: require('../assets/images/sidebar/financials.png'),
  gallery: require('../assets/images/sidebar/gallery.png'),
  help: require('../assets/images/sidebar/help.png'),
  signOut: require('../assets/images/sidebar/signout.png'),
  user: require('../assets/images/sidebar/user.png'),
  product: require('../assets/images/sellerSide/product.jpg'),
  delete: require('../assets/images/sellerSide/delete.png'),
  copy: require('../assets/images/sellerSide/copy.png'),
  edit: require('../assets/images/sellerSide/edit.jpg'),
  profile: require('../assets/images/sellerSide/profile.png'),
  tree: require('../assets/images/sellerSide/tree.png'),
  userTable: require('../assets/images/sellerSide/userTable.png'),
  moti: require('../assets/images/sellerSide/moti1.png'),
  userIconSaller: require('../assets/images/sellerSide/saller-icon.png'),
  completeIcon: require('../assets/images/sellerSide/complete-icon.png'),
  errowDown: require('../assets/images/sellerSide/dropdown.webp'),
  shopImg: require('../assets/images/sellerSide/shop.png'),
  menuIcon: require('../assets/images/menu-icon.webp'),
  sideMenuImg: require('../assets/images/sellerSide/sidebar.png'),
  dashboardSide: require('../assets/images/sellerSide/dashboard.png'),
  contact: require('../assets/images/staticpage/contact.png'),
  emptyBasket: require('../assets/images/staticpage/basket.png'),
  placeholder: require('../assets/images/staticpage/placeholder.jpg'),
  defaultUser: require('../assets/images/user-1.png'),
};
