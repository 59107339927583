import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import * as React from 'react';
import { useNavigate } from 'react-router';
import { images } from '../../../../assets';
import DarkBtn from '../../Buttons/DarkBtn';
import DehazeIcon from '@mui/icons-material/Dehaze';
export default function TemporaryDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const nav =useNavigate ()

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>

        <ListItem >
          <ListItemButton onClick={() => window.open("https://goethical.com", "_Self")}>
            {/* <ListItemIcon>
                icon
              </ListItemIcon> */}
            <ListItemText>
              Home
            </ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem >
          <ListItemButton onClick={() => window.open("https://goethical.com/blog/im-a-seller/", "_Self")}>
            <ListItemText>
              I'm a Seller
            </ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem >
          <ListItemButton onClick={() => window.open("https://goethical.com/blog/im-a-buyer/", "_Self")}>
            <ListItemText>
              I'm a Buyer
            </ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem >
          <ListItemButton onClick={() => window.open("https://goethical.com/blog/about-goethical/", "_Self")}>
            <ListItemText>
              About Us
            </ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem >
          <ListItemButton onClick={() => window.open("https://goethical.com/blog/", "_Self")}>
            <ListItemText>
              Our Blog
            </ListItemText>
          </ListItemButton>
        </ListItem>

        <ListItem >
          <ListItemButton onClick={() => window.open("https://goethical.com/blog/contact-us-goethical/", "_Self")}>
            <ListItemText>
              Contact Us
            </ListItemText>
          </ListItemButton>
        </ListItem>

      </List>
      <Divider />
      <List>

        <ListItem>
          <ListItemButton onClick={() => window.scroll(0, 0)}>
            <ListItemText>
              <DarkBtn title='Login' onClick={()=>nav("/login")} />
            </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <div>
      {['left'].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            {/* <img src={images.menuIcon} /> */}
            <DehazeIcon />
            </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
