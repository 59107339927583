import {
  DELETE_GUEST_USER,
  SET_GUEST_USER,
  UPDATE_GUEST_USER,
} from '../constant';

const initialState = {
  userData: {},
};

const guestUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_GUEST_USER:
      return { ...state, userData: action.data };
    case UPDATE_GUEST_USER:
      return {
        userData: {
          ...action.data,
        },
      };
    case DELETE_GUEST_USER:
      return { userData: {} };
    default:
      return state;
  }
};

export default guestUserReducer;
