import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import InputTypeOutline from '../../universal/InputType/inputTypeOutline';
import LightBtn from '../../universal/Buttons/LightBtn';
import { images } from '../../../assets';
import { Tooltip } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import CustomCropper from './Cropper';
import { notify } from '../../../core/constants';

export default function EditImageDialog({
  itemDetail,
  index,
  uploadImage,
  setUploadImage,
  name,
  // setCroppedImage,
}) {
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(itemDetail);
  const [editName, setName] = useState(name?.split('.')[0]);
  const [cropper, setCropper] = useState();
  const cropperRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleEditName = (e) => {
    const { value } = e.target;
    setName(value);
  };

  const dataURItoFile = (dataURI, fileName) => {
    const byteString = atob(dataURI.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    // remove if user enter image extension in file name
    const fileNameWithoutExtension = fileName.split('.')[0];
    // const imageType = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // // get original image type

    return new File(
      [ab],
      `${fileNameWithoutExtension}${image.type.replace('image/', '.')}`,
      {
        type: image.type,
      }
    );
  };

  const getCropData = () => {
    if (cropper !== 'undefined') {
      const base64 = cropper.getCroppedCanvas().toDataURL();
      const file = dataURItoFile(base64, editName);
      setUploadImage((currentArray) => {
        currentArray[index] = file;
        return [...currentArray];
      });
    }
  };

  const handleSubmit = () => {
    if (editName !== '' && editName.split('.')[0]) {
      getCropData();
      handleClose();
    } else {
      notify('Please enter a name', 'error');
    }
  };

  return (
    <>
      <Tooltip title="Edit Product" placement="right">
        <img
          src={images.edit}
          className="edit-icon"
          onClick={handleClickOpen}
          alt="edit"
        />
      </Tooltip>
      {/* <CloseIcon  className="close-icon"  /> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="editimages"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <h2 className="upload-text">Edit Image:</h2>
            <div className="product">
              <CustomCropper
                image={image}
                setCropper={setCropper}
                cropperRef={cropperRef}
              />
              {/* <img src={URL.createObjectURL(image)} alt="edit" /> */}
            </div>

            {/* <div className="product">
              <img src={croppedImage} alt="cropped" />
            </div> */}

            <div className="label">Image name:</div>
            <InputTypeOutline
              type={'text'}
              name={'editName'}
              value={editName}
              onChange={(e) => handleEditName(e)}
            />
            <div className="btn-section-refund">
              {/* <InputTypeOutline
                type={'text'}
                name={'editName'}
                value={editName}
                onChange={(e) => handleEditName(e)}
              /> */}
              <DarkBtn title="Cancel" onClick={handleClose} />
              <LightBtn title="Save" onClick={handleSubmit} />
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </>
  );
}
