import { DELETE_CART, SET_CART, UPDATE_CART } from '../constant';

let initialState = {
    count: '0'
}

const cartReducer=(state = initialState, action)=> {
    switch (action.type) {
        case SET_CART:
            return { count: action.data };
        case DELETE_CART:
            return {  count: 0 };
     case UPDATE_CART:
                return { count:action.data
                 }; 
        default:
            return state;
    }
}

export default cartReducer;