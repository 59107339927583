import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { notify } from '../../../core/constants';
import { images } from '../../../assets';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import LightBtn from '../../universal/Buttons/LightBtn';
import SubHeading from '../../universal/Headings/SubHeading';
import EditImageDialog from '../EditImage';
import './upload.css';
import { useRef, useState } from 'react';

export default function UploadImage({
  uploadImage,
  setUploadImage,
  UploadMultiImage,
  imageSize,
  setPage,
}) {
  const [open, setOpen] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUploadImage([]);
  };

  const handlesend = () => {
    if (uploadImage.length > 0) {
      UploadMultiImage();
      handleClose();
    } else {
      notify('There is no image to upload', 'error');
    }
  };

  const handleRemoveImage = async (item) => {
    let productIndex = uploadImage?.indexOf(item);
    if (productIndex > -1) {
      uploadImage?.splice(productIndex, 1);
      setUploadImage((currentArray) => [...currentArray]);
    }
  };

  const handleUploadImage = (e) => {
    for (let i = 0; i < e?.currentTarget?.files?.length; i++) {
      if (e?.currentTarget?.files[i].size / (1024 * 1024) <= 2) {
        uploadImage?.push(e?.currentTarget?.files[i]);
      } else {
        notify('You can upload a file upto 2MB only.', 'error');
      }
    }
    setUploadImage((currentArray) => [...currentArray]);
  };

  // handle drag events
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      for (let i = 0; i < e?.dataTransfer.files?.length; i++) {
        if (e?.dataTransfer.files[i].size / (1024 * 1024) <= 2) {
          uploadImage?.push(e?.dataTransfer.files[i]);
        } else {
          notify('You can upload a file upto 2MB only.', 'error');
        }
      }
      setUploadImage((currentArray) => [...currentArray]);
    }
  };

  console.log('uploadImage', uploadImage);

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Button
        style={{ textTransform: 'none' }}
        variant="contained"
        className="darkbtn"
        onClick={handleClickOpen}
      >
        Add images
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="uploadimages"
      >
        <DialogContent>
          <SubHeading title="Image upload  " />
          <div>
            <div className="uploadpara">
              Please make sure that all images are in png or jpg format. Image
              dimensions should be 1000 x 1000.
            </div>

            {uploadImage?.map((item, index) => {
              // console.log('item', item);
              return (
                <div className="img-container-upload">
                  <div className="productscard">
                    <CloseIcon
                      className="close-icon"
                      onClick={() => handleRemoveImage(item)}
                    />
                    <img
                      src={URL.createObjectURL(item)}
                      className="img-row "
                      alt="product"
                    />

                    <div className="product-text-section">
                      <div>
                        {item?.name && item?.editName ? (
                          <p className="product-text">{item?.editName}</p>
                        ) : (
                          <p className="product-text">{item?.name}</p>
                        )}
                      </div>
                      <div>
                        <EditImageDialog
                          itemDetail={item}
                          index={index}
                          uploadImage={uploadImage}
                          setUploadImage={setUploadImage}
                          name={item?.name}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <form
            id="form-file-upload"
            onDragEnter={handleDrag}
            onSubmit={(e) => e.preventDefault()}
          >
            <label
              id="label-file-upload"
              htmlFor="input-file-upload"
              className={dragActive ? 'drag-active' : ''}
            >
              <div>
                <img src={images.upload} className="upload-img" alt="upload" />
                <p className="select-image-text">
                  Drag and drop your photos here or
                  {/* <button className="upload-button" onClick={onButtonClick}>
                
                </button> */}
                  <u>
                    <input
                      ref={inputRef}
                      id={'input-file-upload'}
                      type={'file'}
                      name={'files'}
                      accept={'image/png,image/jpeg,image/jpg'}
                      style={{ display: 'none' }}
                      onChange={handleUploadImage}
                      multiple={true}
                      onClick={(event) => {
                        event.target.value = null;
                      }}
                    />{' '}
                    Click here
                  </u>
                  to select
                </p>
                <p className="select-image-text">
                  Maximum upload file size: 2 MB
                </p>
              </div>
            </label>
            {dragActive && (
              <div
                id="drag-file-element"
                onDragEnter={handleDrag}
                onDragLeave={handleDrag}
                onDragOver={handleDrag}
                onDrop={handleDrop}
              ></div>
            )}
          </form>
          {/* <div className="drop-down">
            <div className="file-label">
              <label for="file_id">
                <img src={images.upload} className="upload-img" />
                <p className="select-image-text">
                  Drag and drop photos or files or
                  <u>
                    {' '}
                    <input
                      id={'file_id'}
                      type={'file'}
                      name={'files'}
                      accept={"image/png,image/jpeg,image/jpg"}
                      style={{ display: 'none' }}
                      onChange={handleUploadImage}
                    />{' '}
                    click here
                  </u>{' '}
                  to select{' '}
                </p>

                <p className="select-image-text">
                  Maximum upload file size: 50 MB
                </p>
              </label>
            </div>
          </div> */}

          {uploadImage?.length ? (
            <>
              <p className="image-text">
                {`Imported Images : ${uploadImage?.length}`}
              </p>
              <p className="image-text">
                {`Current total upload size:
          ${(imageSize / (1024 * 1024)).toFixed(3)} MB`}
              </p>
            </>
          ) : (
            ''
          )}
          <div className="btn-section">
            <LightBtn title="Cancel" onClick={handleClose} />
            <LightBtn
              title="Upload images"
              customClass={`${
                uploadImage?.length > 0 ? 'singleproductbtndrk' : ''
              }`}
              onClick={handlesend}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
