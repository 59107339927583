import React from 'react';
import PreviewOutlinedIcon from '@mui/icons-material/PreviewOutlined';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Pagination from '../../../components/universal/Pagination';
import { Link } from 'react-router-dom';
// import BrandWithCause from "../../Admin/BrandWithCause";
// import TablePagination from '@mui/material/TablePagination';
// import page from '@mui/material/page';
// import handleChangePage from '@mui/handleChangePage/page';
// import rowsPerPage from '@mui/rowsPerPage/page';

export const Categories = () => {
  return (
    <div className="admin">
      <div className="categories-container">
        <h1 className="main-heading">Category</h1>
        <div className='table-section'>
        <Pagination />
        <table>
     
          <tr className="heading">
            <th>ID</th>
            <th>Name</th>
            <th>Badge Type</th>
            <th>Action</th>
          </tr>
          <tr className="row-heading">
            <td>44</td>
            <td>Ethical</td>
            <td>7</td>
            <td className="icon">
            <Link to="/admin/view-details">
              <VisibilityIcon className='iconvisibility' /></Link>
              <Link to="/admin/view-details" > <EditIcon  className='iconedit'/></Link>
            </td>
          </tr>
          <tr className="row-heading">
            <td>44</td>
            <td>Ethical</td>
            <td>7</td>
            <td className="icon">
            <Link to="/admin/view-details">
              <VisibilityIcon className='iconvisibility' /></Link>
              <Link to="/admin/view-details" > <EditIcon  className='iconedit'/></Link>
            </td>
          </tr>
          <tr className="row-heading">
            <td>44</td>
            <td>Ethical</td>
            <td>7</td>
            <td className="icon">
            <Link to="/admin/view-details">
              <VisibilityIcon className='iconvisibility' /></Link>
              <Link to="/admin/view-details" > <EditIcon  className='iconedit'/></Link>
            </td>
          </tr>
        </table>
        </div>
      </div>
    </div>
  );
};

export default Categories;
