import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { images } from '../../assets';
import DarkBtn from '../../components/universal/Buttons/DarkBtn';
import ProgressBar from '../../components/universal/ProgressBar';
import { axiosAll } from '../../services/axiosAll';
const Welcome = () => {
  const searchParams = new URLSearchParams(window?.location?.search);
 const [ProsellerInfo, setProsellerInfo] = useState({});
 const nav =useNavigate()
  const getProSellerDetails  = async () => { 
    const response = await axiosAll({
      method: "get",
      url: `v2/pro_seller_requests-by-token/${searchParams?.get("token")}`,
      params:'',
    });
    if (response?.statusCode == 200) {
      setProsellerInfo(response?.result);
    }
    // setLoading(false);
  };
  useEffect(() => {
    getProSellerDetails()
  }, [searchParams?.get("token") ]);
  return (
    <>
      <ProgressBar />
      <div className="welcomescreen">
        <div className="text-heading">
          Get ready to become a seller on <br />
          Go Ethical!
        </div>
        <div className="text-para">
          What details you’ll need to set up your account:
        </div>
        <div className="img-section">
          <div className="row-tab">
            <img src={images.welcome} alt="facebook" className="icon-img" />
          </div>
          <div className="details-text">
            Store details, including store name, logo and banner image
          </div>
        </div>
        <div className="img-section">
          <div className="row-tab">
            <img src={images.welcome} alt="facebook" className="icon-img" />
          </div>
          <div className="details-text">
            Policy details for refunds, returns and shipping
          </div>
        </div>
        <div className="img-section">
          <div className="row-tab">
            <img src={images.welcome} alt="facebook" className="icon-img" />
          </div>
          <div className="details-text">
            Business details including VAT and company registration numbers
          </div>
        </div>
        <div className='btn-section'>
          <DarkBtn onClick={()=>{
             ProsellerInfo?.proSeller?.isWeb===1?
                ProsellerInfo?.proSeller?.isUserExist?
                   nav(`/login?token=${ProsellerInfo?.proSeller?.token}`):
                   nav(`/create-account/${ProsellerInfo?.proSeller?.token}`)
                   :
                ProsellerInfo?.proSeller?.isApp===1? 
                nav(`/create-account/seller-registration`):
                 nav(`/expiry`) }} title="Let’s get started!"/>
        </div>
      </div>
    </>
  );
};

export default Welcome;
