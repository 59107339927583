import axios from "axios";
import currentURL from "../env";
import { deleteLogin } from "../redux/actions/login";
import { store } from "../redux/store";

const client = axios.create({
  baseURL: currentURL.currentEnv?.replace("v2", ""),
});

export const axiosAll = ({ ...options }) => {
  const state = store.getState();
  const { guestDetails } = state.guestReducer;
  const { loginReducer } = store.getState();
  client.defaults.headers.common.userToken = loginReducer.userDetails.token
    ? loginReducer.userDetails.token
    : ``;
  client.defaults.params = {
    deviceType: 3,
    deviceToken: guestDetails?.token,
  };

  const onSuccess = (response) => {
    if (response.data.statusCode === 401) {
      // store.dispatch(deleteLogin());
      // store.dispatch(deleteUser());
    } else {
      return response.data;
    }
  };
  const onError = (error) => {
    return error;
  };

  return client(options).then(onSuccess).catch(onError);
};

export const AxiosAll = async (method, url, params, userToken) => {
  const state = store.getState();
  const { guestDetails } = state.guestReducer;
  const baseURL = currentURL.currentEnv;
  return await axios({
    method: method,
    url: `${baseURL}${url}deviceType=3&deviceToken=${guestDetails?.token}`,
    data: params,
    headers: { "Content-Type": "application/json", userToken: userToken },
  })
    .then((res) => {
      if (res.data.statusCode === 401) {
        store.dispatch(deleteLogin());
      }
      return res;
    })
    .catch((err) => {
      console.log(err);
    });
};
