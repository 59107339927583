import CloseIcon from "@mui/icons-material/Close";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { Fragment, useEffect, useState } from "react";
import { images } from "../../../assets";
import MediaLibrary from "../../../components/dialogs/MediaLibrary/";
import LightBtn from "../../../components/universal/Buttons/LightBtn";
import MainHeading from "../../../components/universal/Headings/MainHeading";
import SubHeading from "../../../components/universal/Headings/SubHeading";
import InputTypeOutline from "../../../components/universal/InputType/inputTypeOutline";
import TextArea from "../../../components/universal/InputType/TextArea";
import OnOffSwitch from "../../../components/universal/OnOffSwitch";
import ProductsCard from "../../../components/universal/ProductsCard";

import { Grid } from "@mui/material";
import Chip from "@mui/material/Chip";
import { Container } from "@mui/system";
import { useFormik } from "formik";
import "react-quill/dist/quill.snow.css";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import * as Yup from "yup";
import DeleteDialog from "../../../components/dialogs/DeleteDialog";
import VatDialog from "../../../components/dialogs/VatDialog";
import { Loader } from "../../../components/Skeleton/Loader";
import CategoryDropDown from "../../../components/universal/AddProduct/CategoryDropDown";
import ColorDropDown from "../../../components/universal/AddProduct/ColorDropDown";
import ShippingDropDown from "../../../components/universal/AddProduct/ShippingDropDown";
import SizeDropDown from "../../../components/universal/AddProduct/SizeDropDown";
import EditMultiSelect from "../../../components/universal/EditProduct/EditMultiSelect";
import EditTagSelection from "../../../components/universal/EditProduct/EditTagSelection";
import DeleteIcon from "../../../components/universal/Icons/DeleteIcons";
import Editor from "../../../components/universal/InputType/Editor";
import { formats, modules, notify } from "../../../core/constants";
import { axiosAll } from "../../../services/axiosAll";

const EditSingleProduct = () => {
  const { userDetails } = useSelector((state) => state.loginReducer);
  const oldData = JSON.parse(localStorage.getItem("oldData"));
  const [isLoading, setIsLoading] = useState(false);
  const [LoadingMessage, setLoadingMessage] = useState("Fetching Product Details...");
  const [selectedImage, setSelectedImage] = useState([]);
  const [masterCategory, setMasterCategory] = useState([]);
  const [mainCategory, setMainCategory] = useState([]);
  const [subCategory, setSubCategory] = useState([]);
  const [selectedMasterCategory, setSelectedMasterCategory] = useState("0");
  const [masterCategoryArr, setMasterCategoryArr] = useState("");
  const [selectedMainCategory, setSelectedMainCategory] = useState("0");
  const [selectedSubCategory, setSelectedSubCategory] = useState("0");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [badges, setBadges] = useState([]);
  const [filteredBadges, setFilteredBadges] = useState([]);
  const [selectedTags, setSelectedTags] = useState("");
  const [metadata, setMetadata] = useState([]);
  const [condition, setCondition] = useState("3");
  const [tag, setTag] = useState([]);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("0");
  const [processingTime, setProcessingTime] = useState([]);
  const [selectedProcessingTime, setSelectedProcessingTime] = useState("0");
  const [couriers, setCouriers] = useState([]);
  const [selectedCourier, setSelectedCourier] = useState("0");
  const [courierClass, setCourierClass] = useState([]);
  const [selectedCourierClass, setSelectedCourierClass] = useState("0");
  const [colors, setColors] = useState([]);
  const [userProfile, setUserProfile] = useState({});
  const [productDetails, setProductDetails] = useState([]);
  const [tagKeywords, setTagKeywords] = useState([]);
  const [selectedBadges, setSelectedBadges] = useState([]);
  const [isShowVariationsTable, setIsShowVariationsTable] = useState(false);
  const [errors, setErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [isPublished, setIsPublished] = useState(true);
  const [isVatOpen, setIsVatOpen] = useState(false);
  const [productInventory, setProductInventory] = useState([]);
  const [loading, setloading] = useState(true);
  const params = useParams();

  const getProductDetails = async () => {
    const res = await axiosAll({
      url: `v2/product/${params.id}`,
      method: "get",
    });
    const data = res.result.product;
    setProductDetails(data);
    setProductInventory(res.result.product.productInventory);
    setTimeout(() => {
      setloading(false)
    }, "1000")
    
  };
  useEffect(() => {
    getProductDetails();
  }, []);

  const navigate = useNavigate();

  // const getMetadata = (data) => {
  //   const uniqueBadges = data?.filter(
  //     (item, index) =>
  //       data?.findIndex((i) => i.content === item.content) === index
  //   );

  //   setMetadata(uniqueBadges);
  // };

  const getSelectedTags = (tags) => {
    setSelectedTags(tags);
  };

  const getSelectedImage = (image) => {
    const newImage = {
      id: image.id,
      image: image.url,
      createdAt: image.createdAt,
      name: image.name,
    };
    setSelectedImage((prev) => {
      return [...prev, newImage];
    });
  };

  const getUserProfile = async () => {
    const res = await axiosAll({
      method: "get",
      url: `v1/user-profile/${userDetails?.user?.id}`,
    });

    setUserProfile(res.result.user);
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  const getColors = async () => {
    const res = await axiosAll({
      method: "get",
      url: "v1/product/colors",
    });
    setColors(res?.result);
  };

  const getTags = async () => {
    const response = await axiosAll({
      method: "get",
      url: "v1/tag",
    });
    const tags = response?.result?.data?.filter((tag) => {
      return tag.name !== "Ethical";
    });

    if (userProfile?.isProSeller === 1) {
      setMasterCategory(response?.result?.data);
    } else {
      setMasterCategory(tags);
    }
  };

  const getCountriesWithCouriers = async () => {
    const response = await axiosAll({
      method: "get",
      url: "v2/countries-with-couriers",
    });

    setCountries(response.result.countries);
    setProcessingTime(response.result.processingTime);
    setCouriers(response.result.couriers);
  };

  useEffect(() => {
    getTags();
  }, [userProfile]);

  useEffect(() => {
    getCountriesWithCouriers();
    getColors();
  }, []);

  const getBadges = async () => {
    const response = await axiosAll({
      method: "get",
      url: "v1/product/badges",
    });
    setBadges(response.result);
  };

  useEffect(() => {
    if (selectedMasterCategory === 295) {
      const newBadges = badges.map((badge) => {
        if (badge.id === 19 || badge.id === 6) {
          return badge;
        }
      });
      const filteredBadges = newBadges?.filter((badge) => {
        return badge !== undefined;
      });
      setFilteredBadges(filteredBadges);
    } else if (selectedMasterCategory === 44) {
      setFilteredBadges(badges);
    } else {
      setFilteredBadges([]);
    }
  }, [badges, selectedMasterCategory]);

  useEffect(() => {
    getBadges();
  }, []);

  const initialValues = {
    seo_title: "",
    seo_decription: "",
    seo_keywords: "",
    name: "",
    description: "",
    specification: "",
    price: "",
    actualPrice: "",
    subscription_price: "",
    vat: false,
    is_alcohol_beverage: false,
    is_subscription: false,
    isNew: 0,
    shippingCharge: 0,
    pickUpAddress: "",
    tag_keywords: "",
    personalization: false,
    personalization_charter_limit: "1000",
    personalisation_note: "",
    is_wrapping_available: false,
    wrapping_price: "",
    is_fixed: 1,
    is_published: false,
    postageCharge: "",
    shippingUpgrade: false,
    variations: [
      {
        actualPrice: "",
        categoryName: "0",
        color_id: "0",
        id: 0,
        discount: 0,
        is_discount: false,
        price: "",
        stocks: "",
        subscription_price: "",
        size: "0",
      },
    ],
    shippings: [
      {
        shippingCourier: "0",
        shippingClass: "0",
        postageCharge: "",
        shippingUpgrades: false,
      },
    ],
    quantity: "",
    sku: "",
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Product title is required"),
    // updated condition because now using editor which returns html  on 17 words we get main text of 10 length characters
    description: Yup.string()
      .required("Product description is required")
      .min(17, "Product description must be at least 10 characters long"),
    specification: Yup.string(),
    price: Yup.number().min(0.31, "Price should be greater than 0.3"),
    actualPrice: Yup.number()
      .required("Product price is required")
      .min(0.31, "Price should be greater than 0.3"),
    vat: Yup.boolean(),
    is_alcohol_beverage: Yup.boolean(),
    is_subscription: Yup.boolean(),
    subscription_price: Yup.number().when("is_subscription", {
      is: true,
      then: Yup.number().required("Subscription price is required"),
    }),
    isNew: Yup.number(),
    personalization: Yup.boolean(),
    personalization_charter_limit: Yup.number().when("personalization", {
      is: true,
      then: Yup.number()
        .required("Personalization charter limit is required")
        .min(1, "Personalization charter limit should be greater than 0"),
    }),
    quantity: Yup.string(),
    personalisation_note: Yup.string().when("personalization", {
      is: true,
      then: Yup.string().required("Personalization note is required"),
    }),
    is_fixed: Yup.string(),
    is_wrapping_available: Yup.boolean(),
    wrapping_price: Yup.number().when("is_wrapping_available", {
      is: true,
      then: Yup.number().required("Wrapping price is required"),
    }),
    postageCharge: Yup.string().required("Postage charge is required"),
    variations: Yup.array().of(
      Yup.object({
        categoryName: Yup.string(),
        actualPrice: Yup.string(),
        is_discount: Yup.boolean(),
        price: Yup.string(),
        stocks: Yup.string(),
        subscription_price: Yup.string(),
      })
    ),
    shippingUpgrade: Yup.boolean(),
    shippings: Yup.array().of(
      Yup.object({
        shippingCourier: Yup.string().required("Courier is required"),
        shippingClass: Yup.string().required("Courier class is required"),
        postageCharge: Yup.string(),
      })
    ),
    sku: Yup.string(),
  });

  const image = async (data) => {
    setLoadingMessage("Updating Product images ...")
    setIsLoading(true);
  
    const res = await axiosAll({
      method: "post",
      url: "v1/image",
      data,
    });
    if (res.status === "Success") {
      setLoadingMessage(res.message)
      navigate("/onboarding/products");
      setIsLoading(false);
    }
  };

  const editProduct = async (data) => {
    setLoadingMessage("Updating Product Details ...")
    setIsLoading(true);
    const res = await axiosAll({
      method: "put",
      url: `v2/product/${params.id}`,
      data,
    });

    if (res.statusCode === 200) {
      const formData = new FormData();
      formData.append("type", 2);
      formData.append("coverIndex", 0);
      formData.append("productId", res?.result?.product?.id);
      selectedImage.forEach((image) => {
        formData.append("productImage[]", image.image);
      });

      image(formData);
      // setLoadingMessage(res.message)
      notify(res.message, "success");
    } else {
      notify(res.message, "error");
      setIsLoading(false);
    }
 
  };

  const onSubmit = (values) => {
    const inventory = values.variations
      .map((variation) => {
        const size_id = variation.size;
        const id = variation.inventoryId;
        const sku = values.sku;
        const tag_id = variation.categoryName;

        const stock_items = values?.variations
          ?.reduce((acc, curr) => {
            if (curr?.size && curr?.size !== "0") {
              if (curr.size === size_id) {
                acc.push({
                  color_id: curr.color_id ? parseInt(curr.color_id) : 0,
                  stocks: curr.stocks ? parseInt(curr.stocks) : 0,
                  actualPrice: parseFloat(curr.actualPrice),
                  is_discount: curr.is_discount ? 1 : 0,
                  price: curr.is_discount
                    ? parseFloat(curr.price)
                    : parseFloat(curr.actualPrice),
                  discount: curr.price
                    ? parseInt(
                        ((curr.actualPrice - curr.price) / curr.actualPrice) *
                          100
                      )
                    : 0,
                  subscription_price: curr.subscription_price
                    ? parseFloat(curr.subscription_price)
                    : 0,
                  categoryName: curr.categoryName,
                  id: curr.id,
                });
              }
            } else {
              if (curr.categoryName === tag_id) {
                acc.push({
                  color_id: curr.color_id ? parseInt(curr.color_id) : 0,
                  stocks: curr.stocks ? parseInt(curr.stocks) : 0,
                  actualPrice: parseFloat(curr.actualPrice),
                  is_discount: curr.is_discount ? 1 : 0,
                  price: curr.is_discount
                    ? parseFloat(curr.price)
                    : parseFloat(curr.actualPrice),
                  discount: curr.price
                    ? parseInt(
                        ((curr.actualPrice - curr.price) / curr.actualPrice) *
                          100
                      )
                    : 0,
                  subscription_price: curr.subscription_price
                    ? parseFloat(curr.subscription_price)
                    : 0,
                  categoryName: curr.categoryName,
                  id: curr.id,
                });
              }
            }

            return acc;
          }, [])
          ?.map((item) => {
            if (item.id === undefined) {
              delete item.id;
            }
            return item;
          });

        return {
          tag_id,
          size_id: parseInt(size_id),
          id,
          stock_items,
          sku,
        };
      })
      ?.reduce((acc, curr) => {
        if (curr.size_id === 0) {
          if (!acc.some((item) => item.tag_id === curr.tag_id)) {
            acc.push(curr);
          }
        } else {
          if (!acc.some((item) => item.size_id === curr.size_id)) {
            acc.push(curr);
          }
        }
        return acc;
      }, []);

    if (values.variations.length > 0) {
      values.variations.forEach((variation) => {
        inventory.forEach((item) => {
          if (variation.size === item.size_id) {
            if (item.id === undefined) {
              item.id = variation.mainId;
            }
          }
        });
      });
    }

    const price = () => {
      if (selectedCategories?.length < 2 && !isShowVariationsTable) {
        if (values.price !== "") {
          return parseFloat(values.price);
        } else {
          return parseFloat(values.actualPrice);
        }
      } else {
        if (values.variations[0].price) {
          return parseFloat(values.variations[0].price);
        } else {
          return parseFloat(values.variations[0].actualPrice);
        }
      }
    };

    const subscriptionPrice = () => {
      if (selectedCategories?.length < 2 && !isShowVariationsTable) {
        if (values.subscription_price !== "") {
          return parseFloat(values.subscription_price);
        } else {
          return 0;
        }
      } else {
        if (values.variations[0].subscription_price) {
          return parseFloat(values.variations[0].subscription_price);
        } else {
          return 0;
        }
      }
    };

    const actualPrice = () => {
      if (
        selectedCategories?.length < 2 &&
        !isShowVariationsTable &&
        values.actualPrice
      ) {
        return parseFloat(values.actualPrice);
      } else {
        return parseFloat(values.variations[0].actualPrice);
      }
    };

    const singleInventory = [
      {
        id: values.singleInventoryId,
        size_id: 0,
        sku: values.sku,
        stock_items: [
          {
            actualPrice: actualPrice(),
            is_discount: actualPrice() > price() ? 1 : 0,
            price: values.price
              ? parseFloat(values.price)
              : parseFloat(values.actualPrice),
            discount: price()
              ? parseInt(((actualPrice() - price()) / actualPrice()) * 100)
              : 0,
            subscription_price: values.subscription_price
              ? parseFloat(values.subscription_price)
              : 0,
            category: tag[0],
            color_id: 0,
            stocks: parseFloat(values.quantity),
          },
        ],
      },
    ];

    const discount = () => {
      if (price() !== 0) {
        return parseInt(((actualPrice() - price()) / actualPrice()) * 100);
      } else {
        return 0;
      }
    };

    const uniqueMetadata = metadata?.map((meta, index) => {
      const sameMetadata = metadata?.filter((m) => m.content === meta.content);
      if (sameMetadata.length > 1) {
        if (index === 0) {
          return meta;
        } else {
          return { ...meta, action: "delete" };
        }
      }
      return meta;
    });

    //! send data
    const data = {
      is_published: isPublished === true ? 1 : 0,
      actualPrice: actualPrice(),
      description: values.description,
      seo_meta_title: values.seo_title,
      seo_meta_description: values.seo_decription,
      seo_meta_keywords: values.seo_keywords,
      last_changed_by: oldData ? "admin" : "seller",
      discount: discount(),
      inventory:
        selectedCategories?.length < 2 && !isShowVariationsTable
          ? singleInventory
          : inventory,
      isNew: parseInt(condition),
      is_alcohol_beverage: values.is_alcohol_beverage ? 1 : 0,
      is_fixed: parseInt(values.is_fixed),
      is_subscription: values.is_fixed === "1" ? 0 : 1,
      is_wrapping_available: values.is_wrapping_available ? 1 : 0,
      latitude: "00.0000",
      longitude: "00.0000",
      metadata: selectedMasterCategory === 42 ? [] : uniqueMetadata,
      name: values.name,
      personalisation_note: values.personalisation_note,
      personalization: values.personalization ? 1 : 0,
      personalization_charter_limit: values.personalization_charter_limit,
      pickUpAddress: "",
      price: price(),
      shippingCharge: 0,
      shippingData: {
        countryOfOrigin: selectedCountry,
        postageCharge: parseFloat(values.postageCharge),
        proccessingTime: selectedProcessingTime,
        shippingClass: selectedCourierClass,
        shippingCourier: selectedCourier,
        shippingUpgrades: values.shippingUpgrade ? 1 : 0,
        shippingUpgradesList: values.shippingUpgrade
          ? [
              ...values.shippings.map((shipping) => {
                return {
                  ...shipping,
                  postageCharge: parseFloat(shipping.postageCharge),
                  shippingClass: parseInt(shipping.shippingClass),
                  shippingCourier: parseInt(shipping.shippingCourier),
                  shippingUpgrades: shipping.shippingUpgrades ? 1 : 0,
                };
              }),
            ]
          : [],
      },
      shippingType: 1,
      specification: values.specification,
      subscription_price: subscriptionPrice(),
      tag,
      tag_keywords: selectedTags,
      vat: values.vat ? 0 : 20,
      wrapping_price: values.is_wrapping_available
        ? parseFloat(values.wrapping_price)
        : 0,
    };
    if (selectedImage?.length === 0) {
      notify("Please select at least one image", "error");
    } else if (selectedImage?.length > 10) {
      notify("Maximum product photos should be 10", "error");
    } else if (selectedTags.length === 0) {
      notify("Please select at least one tag", "error");
    } else if (tag.length === 0) {
      notify("Please select at least one category", "error");
    } else if (condition === "3") {
      notify("Please select product condition", "error");
    } else if (selectedMasterCategory !== 42 && selectedBadges.length === 0) {
      notify("Please enter product values", "error");
    } else if (
      tag.length < 2 &&
      values.quantity === "" &&
      !isShowVariationsTable
    ) {
      notify("Please enter product quantity", "error");
    } else if (
      tag.length < 2 &&
      values.actualPrice === "" &&
      !isShowVariationsTable
    ) {
      notify("Please enter product fixed price", "error");
    } else if (
      values.personalization &&
      values.personalization_charter_limit === 0
    ) {
      notify("Personalization charter limit should be greater than 0", "error");
    } else if (
      tag.length < 2 &&
      values.actualPrice < values.price &&
      !isShowVariationsTable
    ) {
      notify("Fixed price should be greater than discount price", "error");
    } else if (
      !isShowVariationsTable &&
      tag.length < 2 &&
      values.is_fixed === "0" &&
      values.subscription_price === ""
    ) {
      notify("Please enter product subscription price", "error");
    } else if (
      (isShowVariationsTable || tag.length > 1) &&
      values.variations.some((variation) => {
        return (
          variation.categoryName === "0" ||
          variation.actualPrice === "" ||
          variation.quantity === "" ||
          (variationCategory(variation.categoryName, "isColorAvailable") &&
            variation.color_id === "0") ||
          (variationCategory(variation.categoryName, "isSizeAvailable") &&
            variation.size === "0")
        );
      })
    ) {
      notify("Please fill variation correctly", "error");
    } else if (
      (isShowVariationsTable || tag.length > 1) &&
      values.variations.some((variation) => {
        return values.is_fixed === "0" && variation.subscription_price === "";
      })
    ) {
      notify("Please enter variation subscription price", "error");
    } else if (
      (isShowVariationsTable || tag.length > 1) &&
      values.variations.some((variation) => {
        return variation.is_discount === true && variation.price === "";
      })
    ) {
      notify("Please enter variation discount price", "error");
    } else if (
      (isShowVariationsTable || tag?.length > 1) &&
      values.variations.some((variation) => {
        return variation.stocks === "";
      })
    ) {
      notify("Please add product quantity", "error");
    } else if (selectedCountry === "0") {
      notify("Please select country of origin", "error");
    } else if (selectedProcessingTime === "0") {
      notify("Please select processing time", "error");
    } else if (selectedCourier === "0") {
      notify("Please select courier", "error");
    } else if (selectedCourierClass === "0") {
      notify("Please select courier class", "error");
    } else if (tag?.length > 1) {
      notify("You can add only one category.", "error");
    } else if (
      values.shippingUpgrade &&
      values.shippings.some((shipping) => {
        return (
          shipping.shippingCourier === "0" ||
          shipping.shippingClass === "0" ||
          shipping.postageCharge === ""
        );
      })
    ) {
      notify("Please fill additional shipping correctly", "error");
    } else {
      if (isValid) {
        editProduct(data);
        // console.log('data', data);
      } else {
        Object.keys(errors).forEach((key, index) => {
          if (index === 0) {
            notify(errors[key], "error");
          }
        });
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const getVatValue = (value) => {
    formik.setFieldValue("vat", value);
  };

  useEffect(() => {
    const newErrors = { ...errors, ...formik.errors };
    setIsValid(Object.keys(newErrors).length === 0);
  }, [errors, formik.errors]);

  //! set data
  useEffect(() => {
    setIsPublished(productDetails?.isPublished === "0" ? false : true);
    setSelectedImage(productDetails?.images);

    formik.setFieldValue("specification", productDetails?.specification);
    setCondition(productDetails?.isNew);
    //add check for empty string on tags
    const tagKeyword =
      productDetails?.tagKeywords?.length > 0
        ? productDetails?.tagKeywords?.split(",")?.map((tag) => {
            return { id: tag, text: tag };
          })
        : [];
    setTagKeywords(tagKeyword);

    formik.setFieldValue(
      "is_alcohol_beverage",
      productDetails?.isAlcoholBeverage === 1 ? true : false
    );
    if (productDetails?.productInventory?.length) {
      formik.setFieldValue("sku", productDetails?.productInventory[0]?.sku);
    }
    if (productDetails?.productInventory?.length < 2) {
      formik.setFieldValue(
        "quantity",
        productDetails?.productInventory[0]?.inventoryColors[0]?.stocks
      );
    }

    if (productDetails?.productInventory?.length) {
      formik.setFieldValue(
        "singleInventoryId",
        productDetails?.productInventory[0]?.id
      );
    }

    if (productDetails?.discount > 0) {
      formik.setFieldValue("price", productDetails?.price);
    }
    formik.setFieldValue("actualPrice", productDetails?.actualPrice);
    formik.setFieldValue(
      "subscription_price",
      productDetails?.subscription_price
    );
    formik.setFieldValue(
      "is_fixed",
      `${productDetails?.isSubscription === 0 ? 1 : 0}`
    );
    // formik.setFieldValue('is_discount', productDetails?.is_discount);

    // formik.setFieldValue('discount_price', productDetails?.discount_price);
    formik.setFieldValue(
      "subscription_price",
      productDetails?.subscriptionPrice
    );

    formik.setFieldValue("vat", productDetails?.vat === 20 ? false : true);
    formik.setFieldValue(
      "personalization",
      productDetails?.personalization === "1" ? true : false
    );
    if (
      parseInt(productDetails?.personalizationCharterLimit) === 0 ||
      productDetails?.personalizationCharterLimit === ""
    ) {
      formik.setFieldValue("personalization_charter_limit", 1000);
    } else {
      formik.setFieldValue(
        "personalization_charter_limit",
        parseInt(productDetails?.personalizationCharterLimit)
      );
    }
    formik.setFieldValue(
      "personalisation_note",
      productDetails?.personalisationNote?productDetails?.personalisationNote:''
    );

    formik.setFieldValue(
      "is_wrapping_available",
      productDetails?.isWrappingAvailable === "1" ? true : false
    );
    formik.setFieldValue("wrapping_price", productDetails?.wrappingPrice);

    formik.setFieldValue("shippingType", productDetails?.shippingType);
    formik.setFieldValue(
      "shippingUpgrade",
      productDetails?.shippingData?.shippingUpgrades === "1" ? true : false
    );
    setSelectedCountry(productDetails?.shippingData?.countryOfOrigin);
    formik.setFieldValue(
      "postageCharge",
      parseFloat(productDetails?.shippingData?.postageCharge)
    );
    setSelectedProcessingTime(productDetails?.shippingData?.proccessingTime);
    setSelectedCourier(productDetails?.shippingData?.shippingCourier);
    setSelectedCourierClass(productDetails?.shippingData?.shippingClass);

    if (productDetails?.tagsAttached) {
      const masterCat = productDetails?.tagsAttached[0]?.parentId;
      setSelectedMasterCategory(masterCat);
      setMasterCategoryArr([masterCat]);
    }

    const shippings = productDetails?.shippingData?.shippingUpgradesList.map(
      (shipping) => {
        return {
          shippingCourier: shipping?.shippingCourier,
          shippingClass: shipping?.shippingClass,
          shippingUpgrades: shipping?.shippingUpgrades === "1" ? true : false,
          postageCharge: `${parseFloat(shipping?.postageCharge)}`,
          id: shipping?.id,
        };
      }
    );
    if (shippings?.length > 0) {
      formik.setFieldValue("shippings", shippings);
    } else {
      formik.setFieldValue("shippings", [
        {
          shippingCourier: "0",
          shippingClass: "0",
          postageCharge: "",
          shippingUpgrades: false,
        },
      ]);
    }

    formik.setFieldValue("name", productDetails?.name);
    formik.setFieldValue("description", productDetails?.description);
    formik.setFieldValue("seo_title", productDetails?.seoMetaTitle);
    formik.setFieldValue("seo_decription", productDetails?.seoMetaDescription);
    formik.setFieldValue("seo_keywords", productDetails?.seoMetaKeywords);
    // eslint-disable-next-line
  }, [productDetails]);

  useEffect(() => {
    const filteredBadges = productDetails?.metadata
      ?.map((item) => {
        const badge = badges?.find((badge) => {
          return item.content === `${badge.id}`;
        });
        return {
          id: item.id,
          category: item?.category,
          content: item?.content,
          type: item?.type,
          name: badge?.name,
        };
      })
      .filter((item) => {
        return item !== undefined;
      });
    setSelectedBadges(filteredBadges);
  }, [productDetails, badges]);

  useEffect(() => {
    const tagsAttached = productDetails?.tagsAttached?.map((tag) => {
      return tag?.tagId;
    });

    setTag(tagsAttached);

    const tagArrays = masterCategory
      ?.map((master) => {
        return master.children?.map((main) => {
          return main.children?.map((sub) => {
            if (tagsAttached?.includes(sub.id)) {
              return {
                label: `${main?.name} - ${sub?.name}`,
                id: sub?.id,
                isColorAvailable: sub?.isColorAvailable,
                isSizeAvailable: sub?.isSizeAvailable,
                sizes: sub?.tagSizes,
              };
            }
          });
        });
      })
      ?.flat(2)
      ?.filter((tag) => {
        return tag !== undefined;
      });

    setSelectedCategories(tagArrays);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [masterCategory]);

  useEffect(() => {
    const inventory = productInventory
      ?.map((inventory) => {
        const inventoryColors = inventory?.inventoryColors?.map((color) => {
          return {
            actualPrice: color?.actualPrice,
            categoryName: inventory.tagId
              ? inventory.tagId
              : inventory?.sizes?.tagId
              ? inventory?.sizes?.tagId
              : "0",
            color_id: color?.colorId,
            id: color?.id,
            discount: color?.discount,
            is_discount: color?.isDiscount === "0" ? false : true,
            price: color?.price,
            stocks: color?.stocks,
            subscription_price: color?.subscriptionPrice,
            size: inventory?.sizeId,
            inventoryId: inventory?.id,
          };
        });

        return inventoryColors?.flat();
      })
      .flat();

    formik.setFieldValue("variations", inventory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productInventory, masterCategory]);

  //! validation errors
  useEffect(() => {
    if (selectedImage?.length === 0) {
      setErrors({
        image: "Please select at least one image",
      });
    } else if (selectedImage?.length > 10) {
      setErrors({
        imagesLength: "Maximum product photos should be 10",
      });
    } else if (selectedTags?.length === 0) {
      setErrors({
        tag: "Please select at least one tag",
      });
    } else if (
      formik.values.personalization &&
      formik.values.personalization_charter_limit === 0
    ) {
      setErrors({
        personalization_charter_limit:
          "Personalization charter limit should be greater than 0",
      });
    } else if (tag?.length === 0) {
      setErrors({
        category: "Please select at least one category",
      });
    } else if (condition === "3") {
      setErrors({
        condition: "Please select product condition",
      });
    } else if (
      tag?.length < 2 &&
      formik.values.quantity === "" &&
      !isShowVariationsTable
    ) {
      setErrors({
        quantity: "Please enter product quantity",
      });
    } else if (
      tag?.length < 2 &&
      formik.values.actualPrice === "" &&
      !isShowVariationsTable
    ) {
      setErrors({
        fixed_price: "Please enter product fixed price",
      });
    } else if (
      tag?.length < 2 &&
      formik.values.actualPrice < formik.values.price &&
      !isShowVariationsTable
    ) {
      setErrors({
        priceError: "Fixed price should be greater than discount price",
      });
    } else if (
      !isShowVariationsTable &&
      tag?.length < 2 &&
      formik.values.is_fixed === "0" &&
      formik.values.subscription_price === ""
    ) {
      setErrors({
        subscription_price: "Please enter product subscription price",
      });
    } else if (
      !isShowVariationsTable &&
      tag?.length < 2 &&
      formik.values.is_fixed === "0" &&
      formik.values.subscription_price <= 0.3
    ) {
      setErrors({
        subscription_price_error:
          "Subscription price should be greater than 0.3",
      });
    } else if (
      (isShowVariationsTable || tag?.length > 1) &&
      formik.values.variations.some((variation) => {
        return (
          variation.categoryName === "0" ||
          variation.actualPrice === "" ||
          variation.quantity === "" ||
          (variationCategory(variation.categoryName, "isColorAvailable") &&
            variation.color_id === "0") ||
          (variationCategory(variation.categoryName, "isSizeAvailable") &&
            variation.size === "0")
        );
      })
    ) {
      setErrors({
        variation: "Please fill variation correctly",
      });
    } else if (
      (isShowVariationsTable || tag?.length > 1) &&
      formik.values.variations.some((variation) => {
        return variation.stocks === "";
      })
    ) {
      setErrors({ variationQuantity: "Please add product quantity" });
    } else if (
      (isShowVariationsTable || tag?.length > 1) &&
      formik.values.variations.some((variation) => {
        return (
          formik.values.is_fixed === "0" && variation.subscription_price === 0
        );
      })
    ) {
      setErrors({
        variation_subscription_price:
          "Please enter variation subscription price",
      });
    } else if (
      (isShowVariationsTable || tag?.length > 1) &&
      formik.values.variations.some((variation) => {
        return (
          formik.values.is_fixed === "0" && variation.subscription_price <= 0.3
        );
      })
    ) {
      setErrors({
        variation_subscription_price_error:
          "Variation subscription price should be greater than 0.3",
      });
    } else if (
      (isShowVariationsTable || tag?.length > 1) &&
      formik.values.variations.some((variation) => {
        return variation.actualPrice < variation.price;
      })
    ) {
      setErrors({
        variation: "Fixed price should be greater than discount price",
      });
    } else if (
      (isShowVariationsTable || tag?.length > 1) &&
      formik.values.variations.some((variation) => {
        return variation.actualPrice <= 0.3;
      })
    ) {
      setErrors({
        variation_error: "Variation price should be greater than 0.3",
      });
    } else if (
      (isShowVariationsTable || tag?.length > 1) &&
      formik.values.variations.some((variation) => {
        return variation.is_discount === true && variation.price === "";
      })
    ) {
      setErrors({
        variation_discount_price: "Please enter variation discount price",
      });
    } else if (
      (isShowVariationsTable || tag?.length > 1) &&
      formik.values.variations.some((variation) => {
        return variation.is_discount === true && variation.price <= 0.3;
      })
    ) {
      setErrors({
        variation_discount_price_error:
          "Variation discount price should be greater than 0.3",
      });
    } else if (selectedCountry === "0") {
      setErrors({
        country: "Please select country of origin",
      });
    } else if (selectedProcessingTime === "0") {
      setErrors({
        processing_time: "Please select processing time",
      });
    } else if (selectedCourier === "0") {
      setErrors({
        courier: "Please select courier",
      });
    } else if (selectedCourierClass === "0") {
      setErrors({
        courier_class: "Please select courier class",
      });
    } else if (tag?.length > 1) {
      setErrors({
        category_max: "You can only add one category.",
      });
    } else if (
      formik.values.shippingUpgrade &&
      formik.values.shippings.some((shipping) => {
        return (
          shipping.shippingCourier === "0" ||
          shipping.shippingClass === "0" ||
          shipping.postageCharge === ""
        );
      })
    ) {
      setErrors({
        additional_shipping: "Please fill additional shipping correctly",
      });
    } else if (
      formik.values.is_wrapping_available &&
      formik.values.wrapping_price === "0"
    ) {
      setErrors({
        wrapping_price: "Wrapping price should be greater than 0",
      });
    } else if (Object.keys(formik.errors).length > 0) {
      setErrors({
        required: "Please fill all required fields",
      });
    } else {
      setErrors({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    condition,
    tag,
    selectedTags,
    selectedImage,
    formik.values.name,
    formik.values.description,
    formik.values.shippingUpgrade,
    formik.values.quantity,
    formik.values.actualPrice,
    isShowVariationsTable,
    formik.values.subscription_price,
    formik.values.is_fixed,
    formik.values.variations,
    selectedCountry,
    selectedProcessingTime,
    selectedCourier,
    selectedCourierClass,
    formik.values.shippingUpgrade,
    formik.values.shippings,
    formik.errors,
    formik.values.wrapping_price,
    formik.values.personalization_charter_limit,
  ]);

  useEffect(() => {
    if (formik.values.name !== "" && formik.values.description !== "") {
      delete formik.errors.name;
      delete formik.errors.description;
    } else if (formik.values.name !== "") {
      delete formik.errors.name;
    } else if (formik.values.description !== "") {
      delete formik.errors.description;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // check if selectedCategories one or more than one item have isColorAvailable true or isSizeAvailable true

    const isColorAvailable = selectedCategories?.some(
      (item) => item.isColorAvailable
    );
    const isSizeAvailable = selectedCategories?.some(
      (item) => item.isSizeAvailable
    );

    if (isColorAvailable || isSizeAvailable) {
      setIsShowVariationsTable(true);
    } else {
      setIsShowVariationsTable(false);
    }
  }, [selectedCategories, masterCategory]);

  useEffect(() => {
    const mainCategoryData = masterCategory?.find(
      (masterCategory) => masterCategory.id === parseInt(selectedMasterCategory)
    );
    setMainCategory(mainCategoryData?.children);
  }, [selectedMasterCategory, masterCategory]);

  useEffect(() => {
    const subCategoryData = mainCategory?.find(
      (mainCategory) => mainCategory.id === parseInt(selectedMainCategory)
    );
    setSubCategory(subCategoryData?.children);
  }, [selectedMainCategory, mainCategory]);

  const size = (category_id) => {
    // find the category_id from selectedCategories
    const category = selectedCategories.find(
      (selectedCategory) => selectedCategory.id === category_id
    );
    if (category) {
      return category?.sizes;
    }
  };

  const variationCategory = (category_id, key) => {
    // find the category_id from selectedCategories
    const category = selectedCategories.find(
      (selectedCategory) => selectedCategory.id === category_id
    );
    if (category) {
      if (key) {
        return category[key];
      } else {
        return category;
      }
    }
  };

  const courierClasses = (selectedCourier) => {
    const courierClassData = couriers?.find(
      (courier) => courier.id === parseInt(selectedCourier)
    );
    return courierClassData?.courierClasses;
  };

  useEffect(() => {
    const courier = couriers?.find(
      (courier) => courier.id === parseInt(selectedCourier)
    );
    setCourierClass(courier?.courierClasses);
  }, [selectedCourier, couriers]);

  const checkCategoryAdd = () => {
    if (tag.length >= 1) {
      if (masterCategoryArr[0] === selectedMasterCategory) {
        handleCategoryAdd();
      } else {
        notify("Please select same master category", "error");
      }
    } else {
      handleCategoryAdd();
    }
  };

  const handleCategoryAdd = () => {
    if (selectedMainCategory === "0") {
      notify("Please select main category", "error");
    } else if (selectedSubCategory === "0") {
      notify("Please select sub category", "error");
    } else {
      setTag((prev) => [...prev, parseInt(selectedSubCategory)]);
      setSelectedMainCategory("0");
      setSelectedSubCategory("0");
      const main_category = mainCategory?.find(
        (item) => item.id === parseInt(selectedMainCategory)
      );

      const sub_category = subCategory?.find(
        (item) => item.id === parseInt(selectedSubCategory)
      );

      if (main_category && sub_category) {
        setSelectedCategories((prevState) => [
          ...prevState,
          {
            label: `${main_category.name} - ${sub_category.name}`,
            id: sub_category.id,
            isColorAvailable: sub_category.isColorAvailable,
            isSizeAvailable: sub_category.isSizeAvailable,
            sizes: sub_category?.tagSizes,
          },
        ]);
      }
    }
  };

  const handleCategoryDelete = (id) => {
    setSelectedCategories((prevState) =>
      prevState.filter((item) => item.id !== id)
    );
    setTag((prev) => prev.filter((item) => item !== id));
  };

  const handleDeleteListing = async () => {
    const res = await axiosAll({
      url: `v2/product/${params.id}`,
      method: "delete",
    });
    if (res.status === "Success") {
      navigate("/onboarding/products");
    }
  };

  return (
    <div className="addsingleproduct">
      <Loader open={loading||isLoading} message={LoadingMessage} />
      <Container className="all-container-27inch">
        <form className="" onSubmit={formik.handleSubmit} noValidate>
          <MainHeading title="Edit Product" />
          <div className="publish-card">
            <div className="order-number">Publish listing to store</div>
            <OnOffSwitch
              value={isPublished}
              onChange={(e) => {
                setIsPublished(e.target.checked);
              }}
            />
          </div>

          <div className="personal-container">
            <SubHeading title="Photos" />
            <Grid container spacing={6}>
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">Product Photos</div>
                  <div className="pixel-text">
                    {" "}
                    Maximum of 10 photos in jpg or png format{" "}
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                {selectedImage?.length > 0 && (
                  <>
                    {selectedImage?.map((image, index) => {
                      return (
                        <div key={index} className="img-container">
                          <CloseIcon
                            className="close-icon"
                            onClick={() => {
                              setSelectedImage((prevState) =>
                                prevState.filter((item) => item.id !== image.id)
                              );
                            }}
                          />
                          <ProductsCard
                            image={image?.url ? image?.url : image?.image}
                            name={image?.name}
                          />
                        </div>
                      );
                    })}
                  </>
                )}
                <div className="drop-down">
                  <div className="file-label">
                    <label htmlFor="files">
                      <img src={images.upload} className="upload-img" />
                      <p className="select-image-text">
                        <MediaLibrary getSelectedImage={getSelectedImage} /> to
                        select files or upload images
                      </p>
                    </label>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>

          <div className="personal-container">
            <SubHeading title="Listing Details" />
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">Product Title</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <InputTypeOutline
                  placeholder="Product Title"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && formik.errors.name}
                />
              </Grid>
            </Grid>
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">Categories</div>
                  <div className="pixel-text">Maximum of 1</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <div className="categories-drop-down">
                  <CategoryDropDown
                    options={masterCategory}
                    value={selectedMasterCategory}
                    disabled={tag?.length >= 1}
                    defaultSelect={{
                      name: "Master category",
                      value: "0",
                    }}
                    onChange={(e) => {
                      setSelectedMasterCategory(e.target.value);
                      setSelectedMainCategory("0");
                      setMasterCategoryArr((prev) => [...prev, e.target.value]);
                    }}
                    onClick={() => {
                      if (tag?.length >= 1) {
                        notify("You can only add one category.", "error");
                      }
                    }}
                  />
                </div>
                {tag?.length < 1 && mainCategory?.length > 0 && (
                  <div className="categories-drop-down">
                    <CategoryDropDown
                      value={selectedMainCategory}
                      options={mainCategory}
                      defaultSelect={{
                        name: "Main category",
                        value: "0",
                      }}
                      onChange={(e) => {
                        setSelectedMainCategory(e.target.value);
                        setSelectedSubCategory("0");
                      }}
                    />
                  </div>
                )}
                {subCategory?.length > 0 && (
                  <div className="categories-drop-down">
                    <CategoryDropDown
                      value={selectedSubCategory}
                      options={subCategory}
                      defaultSelect={{
                        name: "Sub category",
                        value: "0",
                      }}
                      // getSubCategory={getSubCategory}
                      onChange={(e) => {
                        setSelectedSubCategory(e.target.value);
                      }}
                    />
                  </div>
                )}
                <div className="categories-drop-down">
                  {" "}
                  <LightBtn
                    title="Add"
                    onClick={checkCategoryAdd}
                    disabled={
                      selectedMasterCategory === "0" ||
                      selectedMainCategory === "0" ||
                      selectedSubCategory === "0"
                    }
                    customClass={`${
                      selectedSubCategory !== "0" ? "singleproductbtndrk" : ""
                    }`}
                  />
                </div>
                {selectedCategories?.map((item, index) => (
                  <Chip
                    key={index}
                    label={item.label}
                    variant="outlined"
                    onDelete={() => {
                      handleCategoryDelete(item.id);
                      formik.setFieldValue(
                        "variations",
                        [
                          {
                            actualPrice: "",
                            categoryName: "0",
                            color_id: "0",
                            id: 0,
                            discount: 0,
                            is_discount: false,
                            price: "",
                            stocks: "",
                            subscription_price: "",
                            size: "0",
                          },
                        ],
                        true
                      );
                    }}
                  />
                ))}

                <div className="add-new">
                  You can add sizes and colours below if applicable
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">Description</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <Editor
                  modules={modules}
                  formats={formats}
                  theme={"snow"}
                  placeholder="Description"
                  name="description"
                  value={formik.values.description}
                  error={formik.errors.description}
                  onChange={(e) => {
                    formik.setFieldValue("description", e);
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">Specification (optional)</div>
                  <div className="pixel-text">
                    Specific details about your product variations
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextArea
                  placeholder="Specification"
                  name="specification"
                  value={formik.values.specification}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.specification && formik.errors.specification
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">Product condition</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <CategoryDropDown
                  defaultSelect={{
                    name: "Product condition",
                    value: "3",
                  }}
                  value={condition}
                  options={[
                    {
                      name: "New",
                      id: 1,
                    },
                    {
                      name: "Very Good",
                      id: 0,
                    },
                    {
                      name: "Worn",
                      id: 2,
                    },
                  ]}
                  onChange={(e) => setCondition(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">Tags</div>
                  <div className="pixel-text">
                    What words might someone use to search for your listings?
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <div className="inputbtn">
                  {/* <InputTypeOutline /> */}
                  <EditTagSelection
                    tagKeywords={tagKeywords}
                    getSelectedTags={getSelectedTags}
                  />
                </div>
              </Grid>
            </Grid>
            {selectedMasterCategory !== 42 && (
              <Grid container spacing={6} className="gridcenter">
                <Grid item xs={12} sm={3.4}>
                  <div>
                    <div className="order-number">Values</div>
                    <div className="pixel-text">
                      Add a maximum of four values
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <EditMultiSelect
                    badges={filteredBadges}
                    selectedBadges={selectedBadges}
                    setSelectedBadges={setSelectedBadges}
                    setMetadata={setMetadata}
                    metadata={metadata}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">
                    Is it an alcoholic beverage / product?
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <OnOffSwitch
                  name="is_alcohol_beverage"
                  value={formik.values.is_alcohol_beverage}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "is_alcohol_beverage",
                      e.target.checked
                    );
                  }}
                  onBlur={formik.handleBlur}
                />
              </Grid>
            </Grid>
          </div>
          <div className="personal-container">
            <SubHeading title="Pricing & Inventory" />
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">Total quantity</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                {!isShowVariationsTable && tag?.length <= 1 ? (
                  <InputTypeOutline
                    placeholder="Total quantity"
                    name="quantity"
                    value={formik.values.quantity}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key) && e.key !== ".") {
                        e.preventDefault();
                      }
                    }}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.quantity && formik.errors.quantity}
                    type="number"
                    min={0}
                  />
                ) : (
                  <div className="order-number">See variation table</div>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">SKU (optional)</div>
                  <div className="pixel-text">
                    Stock Keeping Unit. A unique product identifier
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <InputTypeOutline
                  placeholder="SKU"
                  name="sku"
                  value={formik.values.sku}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </Grid>
            </Grid>
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">Pricing</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <div className="radiobtn-section">
                  <RadioGroup
                    defaultValue={"1"}
                    onChange={(e) => {
                      formik.setFieldValue("is_fixed", e.target.value);
                      if (e.target.value === "1") {
                        formik.setFieldValue(
                          "variations",
                          formik.values.variations.map((item) => {
                            return {
                              ...item,
                              subscription_price: 0,
                            };
                          })
                        );
                      }
                    }}
                    name="is_fixed"
                    value={formik.values.is_fixed}
                  >
                    <FormControlLabel
                      value={"1"}
                      label="Fixed"
                      control={<Radio />}
                    />
                    <FormControlLabel
                      value={"0"}
                      label="Subscription"
                      control={<Radio />}
                    />
                  </RadioGroup>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">Fixed price (£)</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                {!isShowVariationsTable && tag?.length <= 1 ? (
                  <InputTypeOutline
                    placeholder="Fixed price"
                    name="actualPrice"
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key) && e.key !== ".") {
                        e.preventDefault();
                      }
                    }}
                    value={formik.values.actualPrice}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.actualPrice && formik.errors.actualPrice
                    }
                    type="number"
                    min={0}
                  />
                ) : (
                  <div className="order-number">See variation table</div>
                )}
              </Grid>
            </Grid>
            {formik.values.is_fixed === "0" && (
              <Grid container spacing={6} className="gridcenter">
                <Grid item xs={12} sm={3.4}>
                  <div>
                    <div className="order-number">Subscription price (£)</div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  {!isShowVariationsTable && tag?.length <= 1 ? (
                    <InputTypeOutline
                      placeholder="Subscription price"
                      name="subscription_price"
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key) && e.key !== ".") {
                          e.preventDefault();
                        }
                      }}
                      value={formik.values.subscription_price}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.subscription_price &&
                        formik.errors.subscription_price
                      }
                      type="number"
                      min={0}
                    />
                  ) : (
                    <div className="order-number">See variation table</div>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">
                    Discount price (£) (optional)
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                {!isShowVariationsTable && tag?.length <= 1 ? (
                  <InputTypeOutline
                    placeholder="Discount price"
                    name="price"
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key) && e.key !== ".") {
                        e.preventDefault();
                      }
                    }}
                    value={formik.values.price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.price && formik.errors.price}
                    type="number"
                    min={0}
                  />
                ) : (
                  <div className="order-number">See variation table</div>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">VAT exempted</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <VatDialog
                  getVatValue={getVatValue}
                  isVatOpen={isVatOpen}
                  setIsVatOpen={setIsVatOpen}
                />
                <OnOffSwitch
                  name="vat"
                  value={formik.values.vat}
                  onChange={(e) => {
                    formik.setFieldValue("vat", e.target.checked);
                    if (e.target.checked === true) {
                      setIsVatOpen(true);
                    }
                  }}
                />
              </Grid>
            </Grid>
          </div>
          <div className="personal-container">
            <SubHeading title="Variations" />
            <div className="variation-text">
              {" "}
              You can add variations to your product. This allows you to specify
              price, discount and quantity per product variation.{" "}
            </div>
            {(isShowVariationsTable || tag?.length > 1) && (
              <div
                className="container-table"
                style={{
                  overflow: "auto",
                }}
              >
                <table className="ng-dirty ng-touched ng-invalid">
                  <tr className="table-heading">
                    <th>Category Name</th>
                    <th>Size</th>
                    <th>Colour</th>
                    <th>Price (£)</th>
                    <th>DiscountPrice (£)</th>
                    {formik.values.is_fixed === "0" && (
                      <th>Subscription Price (£)</th>
                    )}
                    <th>Quantity</th>
                    <th></th>
                  </tr>

                  {formik.values.variations &&
                    formik.values.variations.length > 0 &&
                    formik.values.variations.map((variation, index) => (
                      <tr
                        key={index}
                        className="table-data ng-star-inserted ng-dirty ng-touched ng-valid"
                      >
                        <td>
                          <CategoryDropDown
                            options={selectedCategories}
                            defaultSelect={{
                              name: "Select Category",
                              value: "0",
                            }}
                            name={`variations[${index}].categoryName`}
                            value={variation.categoryName}
                            onChange={(e) => {
                              formik.setFieldValue(
                                `variations[${index}].categoryName`,
                                e.target.value
                              );
                              formik.setFieldValue(
                                `variations[${index}].size`,
                                "0"
                              );
                            }}
                          />
                        </td>
                        <td>
                          {variationCategory(
                            variation.categoryName,
                            "isSizeAvailable"
                          ) === 1 && (
                            <SizeDropDown
                              options={size(variation.categoryName)}
                              defaultSelect={{
                                name: "Select size",
                                value: "0",
                              }}
                              name={`variations[${index}].size`}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `variations[${index}].size`,
                                  e.target.value
                                );

                                delete formik.values.variations[index].mainId;
                                delete formik.values.variations[index].id;
                              }}
                              value={variation.size}
                            />
                          )}
                        </td>

                        <td>
                          {variationCategory(
                            variation.categoryName,
                            "isColorAvailable"
                          ) === 1 && (
                            <ColorDropDown
                              options={colors}
                              defaultSelect={{
                                name: "Select color",
                                value: "0",
                              }}
                              name={`variations[${index}].color_id`}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `variations[${index}].color_id`,
                                  e.target.value
                                );
                              }}
                              value={variation.color_id}
                            />
                          )}
                        </td>
                        <td>
                          <InputTypeOutline
                            placeholder="Price"
                            name={`variations[${index}].actualPrice`}
                            categoryName={`variations[${index}].actualPrice`}
                            value={variation.actualPrice}
                            onKeyPress={(e) => {
                              if (!/[0-9]/.test(e.key) && e.key !== ".") {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              formik.setFieldValue(
                                `variations[${index}].actualPrice`,
                                e.target.value
                              );
                              if (
                                !formik.values.variations[index].is_discount
                              ) {
                                formik.setFieldValue(
                                  `variations[${index}].price`,
                                  0
                                );
                              }
                            }}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.actualPrice &&
                              formik.errors.actualPrice
                            }
                            type="number"
                            min={0}
                          />
                        </td>
                        <td>
                          <div className="discount-section">
                            <OnOffSwitch
                              name={`variations[${index}].is_discount`}
                              value={variation.is_discount}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `variations[${index}].is_discount`,
                                  e.target.checked
                                );

                                formik.setFieldValue(
                                  `variations[${index}].price`,
                                  0
                                );
                              }}
                            />{" "}
                            {variation.is_discount && (
                              <InputTypeOutline
                                placeholder="Discount"
                                name={`variations[${index}].price`}
                                value={variation.price}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                onKeyPress={(e) => {
                                  if (!/[0-9]/.test(e.key) && e.key !== ".") {
                                    e.preventDefault();
                                  }
                                }}
                                error={
                                  formik.touched.price && formik.errors.price
                                }
                                type="number"
                                min={0}
                              />
                            )}
                          </div>
                        </td>
                        {formik.values.is_fixed === "0" && (
                          <td>
                            <InputTypeOutline
                              placeholder="Subscription Price"
                              name={`variations[${index}].subscription_price`}
                              value={variation.subscription_price}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              onKeyPress={(e) => {
                                if (!/[0-9]/.test(e.key) && e.key !== ".") {
                                  e.preventDefault();
                                }
                              }}
                              error={
                                formik.touched.subscription_price &&
                                formik.errors.subscription_price
                              }
                              type="number"
                              min={0}
                            />
                          </td>
                        )}
                        <td>
                          <InputTypeOutline
                            placeholder="Quantity"
                            name={`variations[${index}].stocks`}
                            value={variation.stocks}
                            onChange={formik.handleChange}
                            onKeyPress={(e) => {
                              if (!/[0-9]/.test(e.key) && e.key !== ".") {
                                e.preventDefault();
                              }
                            }}
                            onBlur={formik.handleBlur}
                            error={
                              formik.touched.stocks && formik.errors.stocks
                            }
                            type="number"
                            min={0}
                          />
                        </td>
                        <td>
                          {index > 0 && (
                            <button
                              className="delete-button"
                              type="button"
                              onClick={() => {
                                // remove the variation from the array
                                const newVariations = [
                                  ...formik.values.variations.slice(0, index),
                                  ...formik.values.variations.slice(index + 1),
                                ];
                                formik.setFieldValue(
                                  "variations",
                                  newVariations
                                );
                              }}
                            >
                              <DeleteIcon />
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                </table>
              </div>
            )}

            {(isShowVariationsTable || tag?.length > 1) && (
              <LightBtn
                title="Add Variations"
                onClick={() => {
                  formik.setFieldValue(
                    "variations",
                    [
                      ...formik.values.variations,
                      {
                        actualPrice: "",
                        categoryName: "0",
                        color_id: "0",
                        id: 0,
                        discount: 0,
                        is_discount: false,
                        price: "",
                        stocks: "",
                        subscription_price: "",
                        size: "0",
                      },
                    ],
                    true
                  );
                }}
              />
            )}
          </div>
          <div className="personal-container">
            <SubHeading title="Product Personalisation" />
            <div className="variation-text">
              We offer you the ability to capture personalisation text from the
              user. This might be relevant for engraved jewelry, custom prints,
              embossed leather bags etc.
            </div>

            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div className="order-number">Product Personalisation</div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <OnOffSwitch
                  name="personalization"
                  value={formik.values.personalization}
                  onChange={(e) => {
                    formik.setFieldValue("personalization", e.target.checked);
                    formik.setFieldValue("personalization_charter_limit", 1000);
                  }}
                />
              </Grid>
            </Grid>
            {formik.values.personalization && (
              <>
                {" "}
                <Grid container spacing={6} className="gridcenter">
                  <Grid item xs={12} sm={3.4}>
                    <div>
                      <div className="order-number">
                        Enter your personalization instructions you want buyers
                        to see
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <TextArea
                      name="personalisation_note"
                      value={formik.values.personalisation_note}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.personalisation_note &&
                        formik.errors.personalisation_note
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={6} className="gridcenter">
                  <Grid item xs={12} sm={3.4}>
                    <div>
                      <div className="order-number">Character limit</div>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={7}>
                    <InputTypeOutline
                      name="personalization_charter_limit"
                      value={formik.values.personalization_charter_limit}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      onKeyPress={(e) => {
                        if (!/[0-9]/.test(e.key) && e.key !== ".") {
                          e.preventDefault();
                        }
                      }}
                      error={
                        formik.touched.personalization_charter_limit &&
                        formik.errors.personalization_charter_limit
                      }
                      type="number"
                      min={0}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </div>

          <div className="personal-container">
            <SubHeading title="Giftwrapping" />
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div className="order-number">Offer giftwrapping</div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <OnOffSwitch
                  name="is_wrapping_available"
                  value={formik.values.is_wrapping_available}
                  onChange={(e) => {
                    formik.setFieldValue(
                      "is_wrapping_available",
                      e.target.checked
                    );
                  }}
                />
              </Grid>
            </Grid>

            {formik.values.is_wrapping_available && (
              <Grid container spacing={6} className="gridcenter">
                <Grid item xs={12} sm={3.4}>
                  <div>
                    <div className="order-number">Giftwrapping charge (£)</div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={7}>
                  <InputTypeOutline
                    placeholder="Gift Wrapping charge"
                    name="wrapping_price"
                    value={formik.values.wrapping_price}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    onKeyPress={(e) => {
                      if (!/[0-9]/.test(e.key) && e.key !== ".") {
                        e.preventDefault();
                      }
                    }}
                    error={
                      formik.touched.wrapping_price &&
                      formik.errors.wrapping_price
                    }
                    type="number"
                    min={0}
                  />
                </Grid>
              </Grid>
            )}
          </div>

          <div className="personal-container">
            <SubHeading title="Shipping & Delivery" />
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div className="order-number">Country of origin</div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <ShippingDropDown
                  options={countries}
                  value={selectedCountry}
                  onChange={(e) => setSelectedCountry(e.target.value)}
                  defaultSelect={{
                    name: "Country you're dispatching from",
                    value: "0",
                  }}
                />
              </Grid>
            </Grid>

            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">Processing time</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <ShippingDropDown
                  options={processingTime}
                  value={selectedProcessingTime}
                  defaultSelect={{
                    name: "Select Processing Time",
                    value: "0",
                  }}
                  onChange={(e) => setSelectedProcessingTime(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">Shipping courier & class</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <div className="dropdownwidth">
                  <div className="shipping-dropdown leftspace">
                    <ShippingDropDown
                      options={couriers}
                      defaultSelect={{
                        name: "Select Courier",
                        value: "0",
                      }}
                      value={selectedCourier}
                      onChange={(e) => {
                        setSelectedCourier(e.target.value);
                        setSelectedCourierClass("0");
                      }}
                    />
                  </div>
                  <div className="shipping-dropdown">
                    <ShippingDropDown
                      options={courierClass}
                      value={selectedCourierClass}
                      defaultSelect={{
                        name: "Select Courier Class",
                        value: "0",
                      }}
                      onChange={(e) => {
                        setSelectedCourierClass(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">Postage charge</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <InputTypeOutline
                  placeholder="Postage charge"
                  name="postageCharge"
                  value={formik.values.postageCharge}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onKeyPress={(e) => {
                    if (!/[0-9]/.test(e.key) && e.key !== ".") {
                      e.preventDefault();
                    }
                  }}
                  error={
                    formik.touched.postageCharge && formik.errors.postageCharge
                  }
                  type="number"
                  min={0}
                />
              </Grid>
            </Grid>
            <Grid container spacing={6} className="gridcenter">
              <Grid item xs={12} sm={3.4}>
                <div>
                  <div className="order-number">Shipping upgrades</div>
                </div>
              </Grid>
              <Grid item xs={12} sm={7}>
                <OnOffSwitch
                  name="shippingUpgrade"
                  value={formik.values.shippingUpgrade}
                  onChange={(e) => {
                    formik.setFieldValue("shippingUpgrade", e.target.checked);
                  }}
                />
              </Grid>
            </Grid>

            {formik.values.shippingUpgrade &&
              formik.values.shippings &&
              formik.values.shippings.map((shipping, index) => {
                return (
                  <Fragment key={index}>
                    <div className="shipping3">
                      Additional Shipping upgrade {index + 1}
                    </div>
                    <Grid container spacing={6} className="gridcenter">
                      <Grid item xs={12} sm={3.4}>
                        <div>
                          <div className="order-number">
                            Shipping courier & class
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <div className="dropdownwidth">
                          <div className="shipping-dropdown leftspace">
                            <ShippingDropDown
                              options={couriers}
                              name={`shippings[${index}].shippingCourier`}
                              defaultSelect={{
                                name: "Select Courier",
                                value: "0",
                              }}
                              value={shipping.shippingCourier}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `shippings[${index}].shippingCourier`,
                                  e.target.value
                                );
                                formik.setFieldValue(
                                  `shippings[${index}].shippingClass`,
                                  "0"
                                );
                              }}
                            />
                          </div>
                          <div className="shipping-dropdown">
                            <ShippingDropDown
                              name={`shippings[${index}].shippingClass`}
                              options={courierClasses(shipping.shippingCourier)}
                              value={shipping.shippingClass}
                              defaultSelect={{
                                name: "Select Courier Class",
                                value: "0",
                              }}
                              onChange={(e) => {
                                formik.setFieldValue(
                                  `shippings[${index}].shippingClass`,
                                  e.target.value
                                );
                              }}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>

                    <Grid container spacing={6} className="gridcenter">
                      <Grid item xs={12} sm={3.4}>
                        <div>
                          <div className="order-number">Postage charge</div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <InputTypeOutline
                          placeholder="Postage charge"
                          name={`shippings[${index}].postageCharge`}
                          onKeyPress={(e) => {
                            if (!/[0-9]/.test(e.key) && e.key !== ".") {
                              e.preventDefault();
                            }
                          }}
                          value={shipping.postageCharge}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          type="number"
                          min={0}
                        />
                      </Grid>
                    </Grid>
                    <Grid container spacing={6} className="gridcenter">
                      <Grid item xs={12} sm={3.4}>
                        <div>
                          <div className="order-number">
                            This shipping upgrade is
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={7}>
                        <OnOffSwitch
                          name={`shippings[${index}].shippingUpgrades`}
                          value={shipping.shippingUpgrades}
                          onChange={(e) => {
                            formik.setFieldValue(
                              `shippings[${index}].shippingUpgrades`,
                              e.target.checked
                            );
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Fragment>
                );
              })}
            {formik.values.shippingUpgrade && (
              <LightBtn
                title=" Add another shipping upgrade "
                onClick={() => {
                  formik.setFieldValue("shippings", [
                    ...formik.values.shippings,
                    {
                      shippingCourier: "0",
                      shippingClass: "0",
                      postageCharge: "",
                      shippingUpgrades: false,
                    },
                  ]);
                }}
              />
            )}
          </div>

          {/* //meta */}
          <div className="personal-container">
            <SubHeading title="Product SEO" />
            <div className="variation-text">
              Product SEO is the practice of optimising elements of Product to
              provide search engines with enough semantically relevant content
              to get the product found in search engines
            </div>
            <>
              <Grid container spacing={6} className="gridcenter">
                <Grid item xs={12} sm={3.4}>
                  <div>
                    <div className="order-number">Meta title</div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputTypeOutline
                    name="seo_title"
                    value={formik.values.seo_title}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text"
                    min={0}
                    error={formik.touched.seo_title && formik.errors.seo_title}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={6} className="gridcenter">
                <Grid item xs={12} sm={3.4}>
                  <div>
                    <div className="order-number">Meta description</div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextArea
                    name="seo_decription"
                    value={formik.values.seo_decription}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.seo_decription &&
                      formik.errors.seo_decription
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={6} className="gridcenter">
                <Grid item xs={12} sm={3.4}>
                  <div>
                    <div className="order-number">Meta keywords</div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextArea
                    name="seo_keywords"
                    value={formik.values.seo_keywords}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.seo_keywords && formik.errors.seo_keywords
                    }
                  />
                </Grid>
              </Grid>
            </>
          </div>

          <div className="publish-card">
            <div className="order-number">
              Do you want to delete this listing?
            </div>

            <DeleteDialog
              name={productDetails?.name}
              handleDelete={handleDeleteListing}
              isAddedToCart={productDetails?.isAddedToCart}
              isAddedToWishlist={productDetails?.isAddedToWishlist}
            />
          </div>

          <div className="btn-section">
            <LightBtn title="Cancel" onClick={() => navigate(-1)} />
            {/* if errors object then return true */}
            <span className="test">
              <LightBtn
                customClass={`${
                  Object.keys(errors).length === 0 ? "singleproductbtndrk" : ""
                }`}
                title="Save"
                type="submit"
                loading={isLoading}
              />
            </span>
          </div>
        </form>
      </Container>
    </div>
  );
};

export default EditSingleProduct;
