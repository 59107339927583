import { useNavigate } from 'react-router';
import { images } from '../../../assets';
import MobileMenu from './MobileMenu';

const PublicHeader = () => {
   const nav = useNavigate()

  return (

    <div className='sellerheader'>
      <div className='headersize'>
      <div className='mobileshow tabshow mobilemenu '>
             <MobileMenu />
           </div>
      <div>
        <div className='header-seller'>
          <div onClick={() => window.open("https://goethical.com", "_Self")}>
            <img src={images.mainLogo} alt="goEthical logo" className='logo-img' />
          </div>
          <div className='category-section desktopshow'>
            <div className='category-name' onClick={() => window.open("https://goethical.com", "_Self")}>Home</div>
            <div className='category-name' onClick={() => window.open("https://goethical.com/blog/im-a-seller/", "_Self")}>I'm a Seller</div>
            <div className='category-name' onClick={() => window.open("https://goethical.com/blog/im-a-buyer/", "_Self")}>I'm a Buyer</div>
            <div className='category-name' onClick={() => window.open("https://goethical.com/blog/about-goethical/", "_Self")}>About Us</div>
            <div className='category-name' onClick={() => window.open("https://goethical.com/blog/", "_Self")}>Our Blog</div>
            <div className='category-name' onClick={() => window.open("https://goethical.com/blog/contact-us-goethical/", "_Self")}>Contact Us</div>
            <div>
              {/* <LightBtn title="Login"/> */}
              <button onClick={() =>{window.location.pathname=="/login"?  window.scroll(0, 0):nav('/login')}} className='login-btn'>Login</button>
            </div>
          </div>
          <div className='desktophide'>
            {/* <SellerMobileMenu/> */}
          </div>
        </div>
        </div> 
        </div>
    </div>
  )
}

export default PublicHeader;