import React from 'react';
import { Button } from '@mui/material';

const LightBtn = ({
  title,
  onClick,
  props,
  type,
  disabled,
  loading,
  customClass,
}) => {
  return (
    <Button
      style={{ textTransform: 'none' }}
      onClick={onClick}
      variant="contained"
      type={type}
      className={`lightbtn ${customClass}`}
      disabled={disabled}
      {...props}
    >
      {loading ? 'Loading...' : title}
    </Button>
  );
};

export default LightBtn;
