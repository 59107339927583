import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";

import BlackBtn from "../../components/universal/Buttons/BlackBtn/";
import ExclusiveBlogs from "../../components/universal/ExclusiveBlogs";
import InputType from "../../components/universal/InputType/";
import { notify } from "../../core/constants";
import { updateGuestUser } from "../../redux/actions/guestUser";
import { addLogin } from "../../redux/actions/login";
import { AxiosAll } from "../../services/axiosAll";
const Login = () => {
  const [isPasswordShow, setIsPasswordShow] = React.useState(true);
  const [Loading, setLoading] = React.useState(false);
  const { guestDetails } = useSelector((state) => state.guestReducer);
  const { userData } = useSelector((state) => state.guestUserReducer);
  const searchParams = new URLSearchParams(window?.location?.search);
  const dispatch = useDispatch();
  const nav = useNavigate();
  const loginCall = async (data) => {
    try {
      const response = await AxiosAll("post", `user/login?`, data);
      if (response.data.statusCode == "102") {
        notify(response.data.message, "error");
      } else if (response?.data?.result?.user?.isVerified) {

        formik.handleReset();

        if (response?.data?.result?.user?.userType == 1) {
          nav("/admin/user-registration");
          notify(response.data.message, "success");
          dispatch(addLogin({
            token: response?.data?.result?.userToken,
            user: response?.data?.result?.user,
          }));

          dispatch(
            updateGuestUser({
              user: response?.data?.result?.user,
              userToken: response?.data?.result?.userToken,
            })
          );

        } else {
          if (searchParams?.get("token") || response?.data?.result?.user?.isProSeller && !response?.data?.result?.user?.isProSellerProfileComplete) {
            nav(`/store-details/${response?.data?.result?.user?.id}/${response?.data?.result?.userToken}`)
          } else {
            nav("/onboarding/dashboard")
            notify(response.data.message, "success");
            dispatch(addLogin({
              token: response?.data?.result?.userToken,
              user: response?.data?.result?.user,
            }));

            dispatch(
              updateGuestUser({
                user: response?.data?.result?.user,
                userToken: response?.data?.result?.userToken,
              })
            );
          }
        }
      }
      else if (!response?.data?.result?.user?.isVerified) {
        resendVerification(response?.data?.result?.user?.id, response?.data?.result?.user?.email, response?.data?.result?.userToken)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const resendVerification = async (id, emailId, token) => {

    try {
      const res = await AxiosAll('post', `user/resend-link?`, { email: emailId }, `${token}`);
      notify(res.data.message, 'success');
      nav(`/verify-email/${id}/${token}`)
    } catch (error) {
      console.log(error);
    }
  };
  // formik start
  const initialValues = {
    email: "",
    password: "",
  };
  const validationSchema = Yup.object({
    email: Yup.string()
      .required("E-mail address is required.")
      .email("Enter valid email address"),
    password: Yup.string().required("Password is required."),
  });
  const onSubmit = (values) => {
    setLoading(true);
    loginCall(searchParams?.get("token") ? {
      email: values.email,
      password: values.password,
      deviceToken: guestDetails.token,
      deviceType: 3,
      guest_user_id: userData?.user?.id,
      proSellerToken: searchParams?.get("token")
    } : {
      email: values.email,
      password: values.password,
      deviceToken: guestDetails.token,
      deviceType: 3,
      guest_user_id: userData?.user?.id,
    });
    setLoading(false);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });
  return (
    <div className="loginpage">
      <div className="login-full-container">
        <div className="main-heading">Login</div>
        <div className="login-para">Welcome back ! Lets get you signed in.</div>
        <form onSubmit={formik.handleSubmit}>
          <div>
            <InputType
              name="email"
              id="email"
              type="email"
              placeholder="Email Address"
              onBlur={formik.handleBlur}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
            />
          </div>
          <div>
            <InputType
              password
              name="password"
              id="password"
              type={isPasswordShow ? "password" : "text"}
              placeholder="Password"
              onBlur={formik.handleBlur}
              value={formik.values.password}
              onChange={formik.handleChange}
              error={formik.errors.password}
              isPasswordShow={isPasswordShow}
              setIsPasswordShow={setIsPasswordShow}
            />
          </div>
          <div>
            <BlackBtn type="submit" loading={Loading} title="Login" />
          </div>

          <Link to="/forgot-password" className="forgot-password">
            <span>Forget Password?</span>
          </Link>
        </form>
      </div>
      <ExclusiveBlogs />
    </div>
  );
};

export default Login;
