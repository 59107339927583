import Skeleton from '@mui/material/Skeleton';
import UploadImage from '../../dialogs/UploadImage';

export default function ProductImageSkeleton({ type }) {
   return (
      <div className="productimageskeleton">
         {type == 2 ?
 <div className='searchbtnsection'>  
            <Skeleton variant="rectangular" className="search" />
            <div className='btnsection'>
            <Skeleton variant="rectangular" className="btn" />
              <UploadImage/>
            </div>

      </div>
     : <div className='boxsection'>
      <div className='productimgbox'>
         <Skeleton variant="rectangular" className="gboxes" />
         <div className='pronameicon'>
            <Skeleton variant="text" className="producttname" />
            <Skeleton variant="circular" className="copyicon" />
         </div>
      </div>
      <div className='productimgbox'>
         <Skeleton variant="rectangular" className="gboxes" />
         <div className='pronameicon'>
            <Skeleton variant="text" className="producttname" />
            <Skeleton variant="circular" className="copyicon" />
         </div>
      </div>
      <div className='productimgbox'>
         <Skeleton variant="rectangular" className="gboxes" />
         <div className='pronameicon'>
            <Skeleton variant="text" className="producttname" />
            <Skeleton variant="circular" className="copyicon" />
         </div>
      </div>
      <div className='productimgbox'>
         <Skeleton variant="rectangular" className="gboxes" />
         <div className='pronameicon'>
            <Skeleton variant="text" className="producttname" />
            <Skeleton variant="circular" className="copyicon" />
         </div>
      </div>
      <div className='productimgbox'>
         <Skeleton variant="rectangular" className="gboxes" />
         <div className='pronameicon'>
            <Skeleton variant="text" className="producttname" />
            <Skeleton variant="circular" className="copyicon" />
         </div>
      </div>
      <div className='productimgbox'>
         <Skeleton variant="rectangular" className="gboxes" />
         <div className='pronameicon'>
            <Skeleton variant="text" className="producttname" />
            <Skeleton variant="circular" className="copyicon" />
         </div>
      </div>
      <div className='productimgbox'>
         <Skeleton variant="rectangular" className="gboxes" />
         <div className='pronameicon'>
            <Skeleton variant="text" className="producttname" />
            <Skeleton variant="circular" className="copyicon" />
         </div>
      </div>
      <div className='productimgbox'>
         <Skeleton variant="rectangular" className="gboxes" />
         <div className='pronameicon'>
            <Skeleton variant="text" className="producttname" />
            <Skeleton variant="circular" className="copyicon" />
         </div>
      </div>

   </div>
}

      
    </div >
  );
}