import { Button } from '@mui/material';
import React from 'react';

const BlackBtn = ({ title, style, onClick, type, loading, disabled }) => {
  return (
    <div className="">
      <Button
        onClick={onClick}
        type={`${type}`}
        disabled={disabled}
        variant="contained"
        className="blackbtn"
      > {loading?"loading..." :title}
      </Button>
    </div>
  );
};

export default BlackBtn;
