//import Pagination from "@mui/material/Pagination";
// import Stack from "@mui/material/Stack";
import TablePagination from '@material-ui/core/TablePagination';

const CustomTablePagination = ({ page, onChangePage, count }) => {
  return (
    <div className="pagination">
      <div className="row-page">Items per page :{10}</div>
      <TablePagination
        component="div"
        count={count}
        rowsPerPageOptions={[10]}
        rowsPerPage={10}
        page={page}
        onChangePage={onChangePage}
      />
    </div>
  );
};

export default CustomTablePagination;
