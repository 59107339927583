import { DELETE_NEWSLATTER, SET_NEWSLATTER, UPDATE_NEWSLATTER } from '../constant';

let initialState = {
    isShowLatter:true
}

const newsLatterReducer=(state = initialState, action)=> {
    switch (action.type) {
        case SET_NEWSLATTER:
            return { isShowLatter: action.data };
        case DELETE_NEWSLATTER:
            return {  isShowLatter: false };
     case UPDATE_NEWSLATTER:
                return { isShowLatter:action.data
                 }; 
        default:
            return state;
    }
}

export default newsLatterReducer;