import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DarkBtn from "../../../components/universal/Buttons/DarkBtn";
import PreviewIcon from '@mui/icons-material/Preview';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { images } from '../../../assets';
import { Link } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="tab-section-container">
      <div className="detail-heading">Order Item Detail</div>
     
    <Box sx={{ width: "100%" }} >
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Details" {...a11yProps(0)} />
          <Tab label="Product Item" {...a11yProps(1)} />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0} className="paddingremove">
      {/* <div>
          <h1 className="">Order Item Detail</h1>
        </div> */}
        <div className="card-container">
          <h3 className="email">Generated Order ID :</h3>
          <p className="mail-text">D8E7J5L1M3S6</p>

          <h3 className="email">Buyer :</h3>
          <p className="mail-text">10100 - henry Copper</p>

          <h3 className="email">Seller :</h3>
          <p className="mail-text">3097 - Boody</p>

          <h3 className="email">Total Order Price :</h3>
          <p className="mail-text">36.95</p>
          <DarkBtn title='Back' />
        </div>
      </TabPanel>
      <TabPanel value={value} index={1} className="paddingremove">
      <div className="product-item">
          <div >
          <table>
              <tr className="table-heading">
                  <th>Generated Product ID</th>
                  <th>Name</th>
                  <th>Product Image</th>
                  <th>Price</th>
                  <th>Shipping Charge</th>
                  <th>Delivery Type</th>
                  <th>Status</th>
                  <th>Action</th>
              </tr>
              <tr>
                  <td>G4X5P7I1N2H5X8</td>
                  <td>Men's Long Sleeve T-Shirt</td>
                  <td>
                      <img src={images.product} className="product-img"/>
                  </td>
                  <td className="price">33.95</td>
                  <td>3</td>
                  <td>Delivery</td>
                  <td>PENDING</td>
                  <td>
                  <Link to="/admin/orderitem">
                          < VisibilityIcon  className='icon-visibility' />
                          </Link> 
                  </td>
              </tr>
          </table>
      </div>
      </div>
      </TabPanel>
      
    </Box>
    </div>
  );
}
