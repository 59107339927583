import React from 'react';
import { images } from '../../../assets';

const SellerFooter = () => {
  return (
    <div className='sellerfooter'>
      <div className='footer-section'>
        {/* <div className='privacy-link' onClick={() => window.open("https://goethical.com/", "_Self")}> */}
        <div>
          <img src={images.mainLogo} alt="goEthical logo" className='logo-img' />
        </div>
        <div className='link-section'>
          <div className='privacy-link' onClick={() => window.open("https://goethical.com/blog/privacy-policy/", "_Self")} >Privacy Policy</div>
          <div className='privacy-link' onClick={() => window.open("https://goethical.com/blog/terms-and-conditions/", "_Self")}>Terms and Conditions</div>
          <div className='privacy-link' onClick={() => window.open("https://goethical.com/blog/contact-us-goethical/", "_Self")}>Contact Us</div>
        </div>
        <div className='icon-section'>
          <a
            target="_blank"
            href="https://www.facebook.com/GoEthicalMarketplace"
          >
            <img src={images.facebook1} alt="facebook" className='icon-img' />
          </a>

          <a
            target="_blank"
            href="https://twitter.com/goethical_app"
          >
            <img src={images.twitter1} alt="twitter" className='icon-img' />
          </a>

          <a
            target="_blank"
            href="https://www.instagram.com/goethicalmarketplace/"
          >
            <img src={images.instagram1} alt="instagram" className='icon-img' />
          </a>

          <a
            target="_blank"
            href="https://www.linkedin.com/company/goethicalmarketplace/"
          >
            <img src={images.linkedin1} alt="linkedin" className='icon-img' />
          </a>
        </div>
      </div>
    </div>
  )
}

export default SellerFooter;
