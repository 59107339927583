import React from 'react';
import { Button } from '@mui/material';

const CancelBtn = ({onClick}) => {
  return (
    <div className="">
        <Button variant="contained" className="cancelbtn orderbtn3" onClick={onClick}>Cancel</Button>
    </div>
  )
}

export default CancelBtn;