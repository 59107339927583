import { Grid } from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Business, IsRegistered, VAT } from '../../../core/constants';
import DarkBtn from '../../universal/Buttons/DarkBtn';
import DropDown from '../../universal/DropDown';
import InputType from '../../universal/InputType';
const RegisterToday = ({ setStep, step, registration, setRegistration }) => {
  // formik initial state
  const initialValues = {
    firstName: '',
    lastName: '',
    companyName: '',
    isRegistered: '',
    registrationNumber: '',
    isVat: '',
    vatNumber: '',
    address1: '',
    address2: '',
    state: '',
    city: '',
    country: '',
    postcode: '',
    email: '',
    phone: '',
    isWebsite: '',
    website: '',
    newCategory: '',
  };

  //formik validation
  const validationSchema = Yup.object({
    firstName: Yup.string().required('This feild is required'),
    lastName: Yup.string().required('This feild is required'),
    companyName: Yup.string().required('This feild is required'),
    isRegistered: Yup.string().required('This feild is required'),
    isVat: Yup.string().required('This feild is required'),
    address1: Yup.string().required('This feild is required'),
    address2: Yup.string().required('This feild is required'),
    state: Yup.string().required('This feild is required'),
    city: Yup.string().required('This feild is required'),
    country: Yup.string().required('This feild is required'),
    postcode: Yup.string().required('This feild is required'),
    email: Yup.string().required('This feild is required'),
    isWebsite: Yup.string().required('This feild is required'),
    website: Yup.string().matches(
      /^(?:(ftp|http|https):\/\/)?(?:[\w-]+\.)+[a-z]{2,6}$/,
      'Please enter a valid website url'
    ),
  });

  const userFormData = async (values) => {
    setRegistration({
      ...registration,
      firstName: values.firstName,
      lastName: values.lastName,
      companyName: values.companyName,
      isRegistered: values.isRegistered,
      isVat: values.isVat,
      address1: values.address1,
      address2: values.address2,
      state: values.state,
      city: values.city,
      country: values.country,
      postcode: values.postcode,
      email: values.email,
      phone: values.phone,
      isWebsite: values.isWebsite,
      registrationNumber: values.registrationNumber,
      website: values.website,
      vatNumber: values.vatNumber,
      newCategory: values.newCategory,
    });

    setStep(step + 1);
  };

  const onSubmit = (values) => {
    if (
      values.firstName &&
      values.lastName &&
      values.companyName &&
      values.isRegistered === 'Yes'
        ? values.registrationNumber
        : values.isRegistered && values.isVat === 'Yes'
        ? values.vatNumber
        : values.isVat &&
          values.address1 &&
          values.address2 &&
          values.state &&
          values.city &&
          values.country &&
          values.postcode &&
          values.email &&
          values.isWebsite === 'Yes'
        ? values.website
        : values.isWebsite
    ) {
      userFormData(values);
 
    } else {
      console.log('fill all data');
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });



  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="registertoday">
      <form onSubmit={formik.handleSubmit}>
        <div className="register-text-heading"> Register Today</div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputType
              type={'text'}
              placeholder={'Your First Name'}
              name={'firstName'}
              onChange={formik.handleChange}
              value={formik.values.firstName}
              blur={formik.handleBlur}
              touched={formik.touched.firstName}
              error={formik.errors.firstName}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputType
              type={'text'}
              placeholder={'Your Last Name'}
              name={'lastName'}
              onChange={formik.handleChange}
              value={formik.values.lastName}
              blur={formik.handleBlur}
              touched={formik.touched.lastName}
              error={formik.errors.lastName}
            />
          </Grid>
          <Grid item xs={12}>
            <InputType
              type={'text'}
              placeholder={'Seller Shop/ Company Name*'}
              name={'companyName'}
              onChange={formik.handleChange}
              value={formik.values.companyName}
              blur={formik.handleBlur}
              touched={formik.touched.companyName}
              error={formik.errors.companyName}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <DropDown
              name={'isRegistered'}
              handlechan={(e) =>
                formik.setFieldValue('isRegistered', e.target.value)
              }
              value={formik.values.isRegistered}
              option={IsRegistered}
              touched={formik.touched.isRegistered}
              error={formik.errors.isRegistered}
              blur={formik.handleBlur}
            />
          </Grid>
          {formik.values.isRegistered === 'Yes' ? (
            <Grid item xs={12} sm={4}>
              <InputType
                type={'text'}
                placeholder={'Limited Company Registration number*'}
                name={'registrationNumber'}
                onChange={formik.handleChange}
                value={formik.values.registrationNumber}
                blur={formik.handleBlur}
              />
              {formik.values.isRegistered === 'Yes' &&
              formik.values.registrationNumber === '' ? (
                <span className="input-error-message">
                  This feild is required
                </span>
              ) : (
                ''
              )}
            </Grid>
          ) : (
            ''
          )}

          <Grid item xs={12} sm={4}>
            <DropDown
              name={'isVat'}
              handlechan={(e) => formik.setFieldValue('isVat', e.target.value)}
              value={formik.values.isVat}
              option={VAT}
              touched={formik.touched.isVat}
              error={formik.errors.isVat}
              blur={formik.handleBlur}
            />
          </Grid>
          {formik.values.isVat === 'Yes' ? (
            <Grid item xs={12} sm={12}>
              <InputType
                type={'text'}
                placeholder={'VAT Number'}
                name={'vatNumber'}
                onChange={formik.handleChange}
                value={formik.values.vatNumber}
                blur={formik.handleBlur}
              />
              {formik.values.isVat === 'Yes' &&
              formik.values.vatNumber === '' ? (
                <span className="input-error-message">
                  This feild is required
                </span>
              ) : (
                ''
              )}
            </Grid>
          ) : (
            ''
          )}
        </Grid>
        <div className="your-company-section">
          <div className="register-text-heading"> Your Company</div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputType
                type={'text'}
                placeholder={'Address Line 1'}
                name={'address1'}
                onChange={formik.handleChange}
                value={formik.values.address1}
                blur={formik.handleBlur}
                touched={formik.touched.address1}
                error={formik.errors.address1}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputType
                type={'text'}
                placeholder={'Address Line 2'}
                name={'address2'}
                onChange={formik.handleChange}
                value={formik.values.address2}
                blur={formik.handleBlur}
                touched={formik.touched.address2}
                error={formik.errors.address2}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <InputType
                type={'text'}
                placeholder={'City'}
                name={'city'}
                onChange={formik.handleChange}
                value={formik.values.city}
                blur={formik.handleBlur}
                touched={formik.touched.city}
                error={formik.errors.city}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputType
                type={'text'}
                placeholder={'State / Province / Region'}
                name={'state'}
                onChange={formik.handleChange}
                value={formik.values.state}
                blur={formik.handleBlur}
                touched={formik.touched.state}
                error={formik.errors.state}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputType
                type={'text'}
                placeholder={'Postal Code'}
                name={'postcode'}
                onChange={formik.handleChange}
                value={formik.values.postcode}
                blur={formik.handleBlur}
                touched={formik.touched.postcode}
                error={formik.errors.postcode}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <InputType
                type={'text'}
                placeholder={'Country'}
                name={'country'}
                onChange={formik.handleChange}
                value={formik.values.country}
                blur={formik.handleBlur}
                touched={formik.touched.country}
                error={formik.errors.country}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <InputType
                type={'email'}
                placeholder={'email'}
                name={'email'}
                onChange={formik.handleChange}
                value={formik.values.email}
                blur={formik.handleBlur}
                touched={formik.touched.email}
                error={formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputType
                type={'number'}
                placeholder={'Phone Number'}
                name={'phone'}
                onChange={formik.handleChange}
                value={formik.values.phone}
                blur={formik.handleBlur}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DropDown
                name={'isWebsite'}
                handlechan={(e) =>
                  formik.setFieldValue('isWebsite', e.target.value)
                }
                value={formik.values.isWebsite}
                option={Business}
                touched={formik.touched.isWebsite}
                error={formik.errors.isWebsite}
              />
            </Grid>
            {formik.values.isWebsite === 'Yes' ? (
              <Grid item xs={12} sm={6}>
                <InputType
                  type={'text'}
                  placeholder={'Website URL (if so)'}
                  name={'website'}
                  onChange={formik.handleChange}
                  value={formik.values.website}
                  blur={formik.handleBlur}
                  error={formik.touched.website && formik.errors.website}
                />
              </Grid>
            ) : (
              ''
            )}
          </Grid>
        </div>
        <div className="btn-section">
          <DarkBtn title={'Next '} type={'submit'} />
        </div>
      </form>
    </div>
  );
};

export default RegisterToday;
